<template>
  <div class="top">
    <div class="cover">
      <div
        class="filter"
        :style="`background-image: url(${rootUrl}/img/black.png);`"
      >
        <video loop autoplay muted playsinline>
          <source :src="`${rootUrl}/img/takumi-hirano_top5.mp4`" />
          このブラウザではサポートされていません。
        </video>
      </div>

      <div class="container">
        <div class="right">
          <p>戦国武将</p>
          <p>織田信長の居城</p>
          <p>金華山の麓</p>
          <p>長良川の背流に沿って</p>
          <p>広がる岐阜の街並み</p>
        </div>

        <img :src="`${rootUrl}/img/takumi-hirano_logo02.png`" class="logo" />

        <div class="left">
          <p>繁華街柳瀬通の入り口</p>
          <p>バス停近くの小路を東に曲がれば</p>
          <p>そこには静かな御浪町</p>
          <p>すぐ右に「匠味平野」の明かりが</p>
          <p>あたゝかくゆれる・・・・</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "top",
  components: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.cover {
  background-color: rgb(213, 219, 229); //動画に黒のフィルターかける
  display: inline-block; //上記とセット

  //ここからスマホ全画面用
  margin: 0 auto;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .filter {
    opacity: 0.8;
    display: block;

    //ここからスマホ全画面用
    position: relative !important;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background: no-repeat center center/cover;
  }

  video {
    min-width: 100%;
    min-height: 100vh;
    position: absolute;
  }

  /*動画よりも画面が縦に長くなるとき用*/
  @media (max-aspect-ratio: 16/9) {
    video {
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .container {
    position: absolute;
    top: 20%;
    left: 15%;
    writing-mode: vertical-rl;
    // line-height: 3em;
    letter-spacing: 0.3em;
    font-size: 17px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    width: 70%;
    height: 50%;
    align-items: center;
    color: white;

    @media screen and (max-width: 767px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .left,
    .right {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 65%;
      white-space: nowrap;
      animation: fadeout-anim 13s linear forwards;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    img {
      width: 130px;
      margin-bottom: 80px;
      // animation: fadeout-anim2 13s linear forwards;

      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
        width: 90px;
        height: 300px;
      }
    }

    @keyframes fadeout-anim {
      100% {
        opacity: 0;
      }
    }
    @keyframes fadeout-anim2 {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
</style>
