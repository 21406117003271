var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"osechi-info"},[_c('UiDish',{attrs:{"title":'2024年おせちのご案内'}}),_c('div',{staticClass:"osechi-cover"},[_vm._m(0),_c('div',{staticClass:"osechi-item"},[_c('p',{staticClass:"title"},[_vm._v("２段重（５～６人前）")]),_vm._m(1),_c('img',{attrs:{"src":`${_vm.rootUrl}/img/osechi2.jpg`,"alt":"お弁当"}}),_c('img',{attrs:{"src":`${_vm.rootUrl}/img/osechi3.jpg`,"alt":"お弁当"}}),_vm._m(2)]),_c('div',{staticClass:"osechi-item"},[_c('p',{staticClass:"title"},[_vm._v("１段重（２～３人前）")]),_vm._m(3),_c('img',{attrs:{"src":`${_vm.rootUrl}/img/osechi1.jpg`,"alt":"お弁当"}}),_vm._m(4)]),_c('div',{staticClass:"osechi-item"},[_c('p',{staticClass:"title"},[_vm._v("特選１段重（2〜3人前）")]),_vm._m(5),_c('img',{attrs:{"src":`${_vm.rootUrl}/img/osechi4.jpg`,"alt":"お弁当"}}),_vm._m(6)])]),_c('div',{staticClass:"bottom"},[_vm._m(7),_c('router-link',{staticClass:"to-top",attrs:{"to":"/"}},[_vm._v("トップページに戻る→")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("2023年10月1日(日)より販売開始です。")]),_c('p',[_vm._v("おせち料理の販売は注文予約システムよりご注文が可能です。")]),_c('div',[_c('a',{staticClass:"osechi-button",attrs:{"href":"https://chumonyoyaku.com/00101/osechi/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" こちらから"),_c('br',{staticClass:"sp-only"}),_vm._v("ご注文ください")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 販売価格：67,000円"),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("販売個数：50個")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"des"},[_c('p',[_vm._v(" 鮑柔らか煮、車海老艶煮、祝い黒豆、新寒掘り筍、蛸柔らか煮、鰆幽庵焼、鰤照り焼を含む36品をご用意いたしました。素材本来の味を楽しめる彩り豊かに詰め込んだ豪華2段重おせち。（商品情報は去年のものです。決定次第お知らせします。） ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 販売価格：33,000円"),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("販売個数：50個")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"des"},[_c('p',[_vm._v(" 鮑柔らか煮、車海老艶煮、祝い黒豆、新寒掘り筍、蛸柔らか煮、鰆幽庵焼、鰤照り焼を含む36品をご用意いたしました。素材本来の味を楽しめる彩り豊かに詰め込んだ豪華おせち。（商品情報は去年のものです。決定次第お知らせします。） ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 販売価格：52,000円"),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("販売個数：10個")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"des"},[_c('p',[_vm._v(" 越前黒鮑、伊勢海老、北海道産毛蟹、飛騨牛ローストビーフ、鹿児島産新筍、信州飯田産松茸を含む19品を盛り込んだ匠味平野一推しの豪華おせち。日本各地より選りすぐりの食材を使用いたしました。（商品情報は去年のものです。決定次第お知らせします。） ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" ※写真はイメージです。"),_c('br',{staticClass:"sp-only"}),_vm._v("実際の商品ではありません。 ")])
}]

export { render, staticRenderFns }