<template>
  <div class="menu-seasons-season">
    <div class="summer">
      <div class="summer-left">
        <img
          :src="`${rootUrl}/img/takumi-hirano_pa007.png`"
          alt="ヘッダー画像2"
        />
        <p>蓮の花が咲く頃は夏も盛り、</p>
        <p>岐阜は鵜飼や花火で賑わいます。</p>
        <p>鱧、鮎、鰻が</p>
        <p>より一層美味しく堪能できる季節です。</p>
        <p>涼やかにしてくれる</p>
        <p>夏野菜もお楽しみいただけます。</p>
      </div>
      <div class="summer-right">
        <UiSliderBox
          :images="images"
          :swiperOption="swiperOption"
          :height="100"
          :width="100"
          :button="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "menu-seasons-season",
  components: {
    UiSliderBox,
  },
  data() {
    return {
      images: [
        { id: 1, imgpath: "takumi-hirano_032.jpg", name: "八寸" },
        { id: 2, imgpath: "takumi-hirano_033.jpg", name: "長良川" },
        { id: 3, imgpath: "takumi-hirano_034.jpg", name: "鮎" },
        { id: 4, imgpath: "takumi-hirano_035.jpg", name: "鱧" },
        { id: 5, imgpath: "takumi-hirano_036.jpg", name: "鵜飼" },
        { id: 6, imgpath: "takumi-hirano_037.jpg", name: "鰻" },
      ],
      swiperOption: {
        slidesPerView: 1,
        // spaceBetween: 30,
        speed: 5500,
        effect: "fade",
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
      },
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.summer {
  display: flex;
  justify-content: flex-start;
  // width: 100vh;
  // justify-content: flex-start;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }

  .summer-left {
    width: calc(1100px * 0.35);
    padding-left: 10px;
    margin-left: calc(calc(100vw - 1100px) / 2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;

    @media screen and (max-width: 1100px) {
      width: 90%;
      margin: 0 0 15px 0;
      height: 500px;
      padding: 0 30px;
    }

    img {
      width: 200px;
      @media screen and (max-width: 1100px) {
        width: 200px;
      }
    }

    p {
      font-size: 16px;
      letter-spacing: 0.1px;
    }
  }
  .summer-right {
    width: calc(calc(calc(100vw - 1100px) / 2) + calc(1100px * 0.65));
    //↑右だけ1100px超えて出したいとき使うやつね！
    height: auto;
    align-self: flex-start;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 1100px) {
      width: 100% !important;
      margin-top: 30px;
    }

    ::v-deepimg {

      @media screen and (min-width:769px) and ( max-width:110px) {
        width: 100%;
      }
      @media screen and (max-width: 768px) {
        object-fit: cover;
        object-position: 50% 50%;
        height: 300px;
      }
    }
  }
}
</style>
