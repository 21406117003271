<template>
  <div class="lunch-box">
    <UiDish title="お弁当" style="margin-top: 50px" />

            <div>
          <img :src="`${rootUrl}/img/hirano_oshinagaki-2021aki001.jpg`" alt="お弁当" />
        </div>

    <!-- <UiSliderBox
      :images="images"
      :swiperOption="swiperOption"
      :height="500"
      :width="100"
      :button="true"
    /> -->

    <UiPrice
      :type="1"
      description="ご予約は、２営業日前までにお願いいたします。"
      style="margin-bottom: 30px"
    />

    <p class="tax">価格は全て税込みです。</p>
  </div>
</template>

<script>
import UiDish from "@/components/Ui/Dish.vue";
import UiPrice from "@/components/Ui/Price.vue";
// import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "lunch-box",
  components: {
    // UiSliderBox,
    UiDish,
    UiPrice,
  },
  props: {},
  data() {
    return {
      // images: [
      //   { id: 1, imgpath: "takumi-hirano_020.jpg", name: "お弁当" },
      //   { id: 2, imgpath: "takumi-hirano_020.jpg", name: "お弁当" },
      //   { id: 3, imgpath: "takumi-hirano_020.jpg", name: "お弁当" },
      //   { id: 4, imgpath: "takumi-hirano_020.jpg", name: "お弁当" },
      //   { id: 5, imgpath: "takumi-hirano_020.jpg", name: "お弁当" },
      //   { id: 6, imgpath: "takumi-hirano_020.jpg", name: "お弁当" },
      // ],
      // swiperOption: {
      //   slidesPerView: 3,
      //   spaceBetween: 30,
      //   pagination: {
      //     el: ".swiper-pagination",
      //     clickable: true,
      //   },
      //   navigation: {
      //     nextEl: ".swiper-button-next",
      //     prevEl: ".swiper-button-prev",
      //   },
      // },
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.lunch-box {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  .dish {
    .top {
      text-align: center;
    }
  }
  .tax {
    text-align: center;
    padding-bottom: 40px;
  }
}
</style>
