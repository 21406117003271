<template>
  <div class="recruit-new-top">
    <div class="wrap">
      <div class="top">
        <img
          :src="`${rootUrl}/img/takumi-hirano_pa012.png`"
          alt="求人"
          class="kyujin-icon"
        />
        <div class="title-cover">
          <p class="title">心を学び、技を磨く</p>
          <p class="sub-title">匠味平野で新たな一歩を</p>
        </div>
      </div>
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-001-2.jpg`"
        alt="八寸"
        class="top-img pc-only"
      />
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-001sp.jpg`"
        alt="八寸"
        class="top-img sp-only"
      />
    </div>
    <div class="gold-bar">
      <img
        :src="`${rootUrl}/img/kyujin/th_deshi202305-pa006-1.png`"
        alt="和イラスト"
        class="logo"
      />
      <img
        :src="`${rootUrl}/img/kyujin/th_deshi202305-pa006-2.png`"
        alt="和イラスト"
        class="stop"
      />
      <h1>
        調理補助・ホールスタッフの<br class="sp-only" />パート・アルバイト
      </h1>
      <div class="pc-only">
        <div class="point">
          <span>Wワーク可能</span><span>時給・待遇良 </span
          ><span>学生歓迎 </span><span>未経験歓迎 </span
          ><span>主婦（夫）歓迎 </span>交通費支給
        </div>
      </div>
      <div class="sp-only point">
        <div class="row">
          <div class="item">
            <img
              :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa012.png`"
              alt="白丸"
              class="maru"
            />Wワーク可能
          </div>
          <div class="item">
            <img
              :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa012.png`"
              alt="白丸"
              class="maru"
            />学生歓迎
          </div>
          <div class="item">
            <img
              :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa012.png`"
              alt="白丸"
              class="maru"
            />
            主婦(夫)歓迎
          </div>
        </div>
        <div class="row">
          <div class="item">
            <img
              :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa012.png`"
              alt="白丸"
              class="maru"
            />
            時給・待遇良
          </div>
          <div class="item">
            <img
              :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa012.png`"
              alt="白丸"
              class="maru"
            />
            未経験歓迎
          </div>
          <div class="item">
            <img
              :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa012.png`"
              alt="白丸"
              class="maru"
            />交通費支給
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recruit-learn",
  components: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-new-top {
  .top {
    width: 58%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -50px;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 70%;
      align-items: center;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      width: 90%;
      align-items: center;
    }
    .kyujin-icon {
      width: 150px;
      margin-bottom: 50px;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
        width: 100px;
      }
    }
    .title-cover {
      .title {
        font-size: 48px;
        margin: 20px 0 30px;
        letter-spacing: 0.04em;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          font-size: 36px;
          margin-bottom: 10px;
        }
        @media screen and (max-width: 767px) {
          font-size: 30px;
          margin-bottom: 0px;
        }
      }
      .sub-title {
        letter-spacing: 0.04em;
        font-size: 27px;
        margin-bottom: 20px;
        @media screen and (max-width: 767px) {
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
.top-img {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 480px;
  object-fit: cover;
  @media screen and (max-width: 767px) {
    // width: 70%;
    // height: 300px;
    // height: 200px;
    width: 100%;
    height: auto;
    width: 80%;
    margin: 0 auto;
    object-fit: cover;
  }
}
.gold-bar {
  background-color: var(--gold);
  padding: 130px 0 100px;
  margin-top: -80px;
  position: relative;
  @media screen and (max-width: 767px) {
    padding-top: 90px;
    padding-bottom: 30px;
  }

  .logo {
    z-index: 10;
    position: absolute;
    width: 13%;
    top: -85px;
    left: calc(calc(100vw - 1100px) * 0.36);
    animation: rotate-anime 60s linear infinite;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      top: -100px;
      left: 30px;
      width: 20%;
    }
    @media screen and (max-width: 767px) {
      top: -45px;
      left: 0;
      width: 30%;
    }
  }
  @keyframes rotate-anime {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .stop {
    z-index: 10;
    position: absolute;
    width: 13%;
    top: -85px;
    left: calc(calc(100vw - 1100px) * 0.36);
        @media screen and (min-width: 768px) and (max-width: 1024px) {
      top: -100px;
      left: 30px;
      width: 20%;
    }
    @media screen and (max-width: 767px) {
      top: -45px;
      left: 0;
      width: 30%;
    }
  }
  h1,
  .point {
    color: white;
  }
  h1 {
    color: white;
    font-size: 38px;
    margin-bottom: 0px;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      font-size: 32px;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 1.5em;
    }
  }
  .point {
    font-size: 22px;
    margin-top: 20px;
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      padding: 0px 20px 0;
      font-size: 18px;
      padding-left: 40px;
      .row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 47%;
        .maru {
          width: 15px;
          margin-right: 5px;
        }
        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 10px;
        }
      }
    }
    span {
      margin-right: 50px;
      @media screen and (max-width: 767px) {
        margin-right: 0;
      }
    }
  }
  .pc-only {
    .point {
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        line-height: 2em;
        width: 85%;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}
</style>
