<template>
  <div class="recruit-deshi-japanese">
    <div class="wrap base">
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai003-1.png`"
        alt="だから日本料理の板前になる"
        class="base2 moon deco"
        :class="{ fadeIn: displayData.display01 === true }"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai003-2.png`"
        alt="日本料理の奥深さ1"
        class="base2 moon deco"
        :class="{ fadeLeft: displayData.display01 === true }"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai003-3.png`"
        alt="日本料理の奥深さ2"
        class="base2 moon deco"
        :class="{ fadeRight: displayData.display01 === true }"
      />

      <div class="block-cover">
        <div class="block">
          <div class="text">
            <h3>四季の美しさを五感で味わうおもてなしの料理</h3>
            <p>
              日本料理は、旬な食材を組み合わせ、ひとつの器の中で移ろいゆく季節に想いを馳せる料理です。丁寧に仕上げ、一品ずつ順番にお出しことによって、彩り、香り、味わい、器、空間が調和し、最高のおもてなしの料理が完成します。この静かな感動が共鳴し合うときこそが料理人にとってもお客様にとっても心をくすぶる瞬間です。また、お料理と同時に日本文化への理解が深まるのも奥深さの一つです。
            </p>
          </div>
          <img
            :src="`${rootUrl}/img/deshi/th_deshi202305-002.jpg`"
            alt="八寸"
          />
        </div>
        <div class="block re">
          <img
            :src="`${rootUrl}/img/deshi/th_deshi202305-003.jpg`"
            alt="八寸"
          />
          <div class="text">
            <h3>板前としてのやりがい</h3>
            <p>
              努力を続け、おもてなしの心を持ち、料理の腕を磨き続けることができれば、多くの人に料理で感動を与えることができます。お客様から「美味しかった」「また来るね」のお声をいただくことは、やはり大変嬉しいものです。また、日本料理は今、世界でも高く評価されています。古くから受け継がれてきた技術や料理法を学び、日本の食文化を継承する役割を担うのもやりがいの一つです。
            </p>
          </div>
        </div>
        <div class="block">
          <div class="text">
            <h3>進むべき道としての将来性</h3>
            <p>
              経済的な成功は料理人の経験や技術、進むべき料理の道によって大きく左右されます。信念を持ち、真摯に学んで独立し、人気店になれば収入面も期待できます。日本料理は、丁寧に仕上げ、最高の状態で1品ずつ楽しんでいただくものです。1日に接客するお客様の数は限られますが、お客様に期待値以上の感動のあるひとときをお過ごしいただくことで、他の料理では難しい価格帯での提供が可能となります。
            </p>
          </div>
          <img
            :src="`${rootUrl}/img/deshi/th_deshi202305-004.jpg`"
            alt="八寸"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "recruit-deshi-japanese",
  components: {},
  data() {
    return {
      // １．これをpropsで渡す
      configList: [
        {
          matchMedia: "(min-width: 768px)",
          displayDefinitionList: [
            {
              offset: 1300,
              display: "display01",
            },
          ],
        },
        {
          matchMedia: "(max-width: 768px)",
          displayDefinitionList: [
            {
              offset: 1200,
              display: "display01",
            },
          ],
        },
      ],

      //
      displayDefinitionList: [],
      displayData: {
        display01: false,
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
  watch: {
    // ４．あとは同じ
    scrollY(offset) {
      //
      let displayList = this.displayDefinitionList.filter(function (
        displayDefinitionData
      ) {
        return displayDefinitionData.offset <= offset;
      });

      //
      displayList.forEach((displayData) => {
        this.displayData[displayData.display] = true;
      });
    },
  },
  created() {
    // ２．configListから、画面幅に合致するものを取得
    let configList = this.configList.filter(function (configData) {
      return window.matchMedia(configData.matchMedia).matches;
    });

    // ３．dataのdisplayDefinitionListに、取得できたものを格納
    console.log("===== configList =====");
    console.log(configList);
    if (configList.length > 0) {
      this.displayDefinitionList = configList[0].displayDefinitionList;
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-deshi-japanese {
  background-color: var(--cream);
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-bottom: 70px;
  }

  .base {
    position: relative;
    padding-top: 400px;
    @media screen and (max-width: 1024px) {
      overflow-x: hidden;
    }
    @media screen and (max-width: 767px) {
      padding-top: 280px;
    }
  }
  .base2 {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    width: 50%;
    @media screen and (max-width: 767px) {
      width: 100%;
      top: 50px;
    }
  }

  .fadeLeft {
    animation-name: fadeLeftAnime;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  @keyframes fadeLeftAnime {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    to {
      opacity: 1;
      transform: translateX(-50%);
    }
  }
  .fadeRight {
    animation-name: fadeRightAnime;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  @keyframes fadeRightAnime {
    from {
      opacity: 0;
      transform: translateX(100%);
    }

    to {
      opacity: 1;
      transform: translateX(-50%);
    }
  }

.block-cover {
  .block {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 50px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 70px;
    }

    img {
      width: 25%;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
      }
    }
    .text {
      width: 70%;
      text-align: left;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h3 {
        text-align: left;
        font-size: 25px;
        margin-bottom: 20px;
        @media screen and (max-width: 767px) {
          margin-bottom: 20px;
          // line-height: 1.5em;
        }
      }
    }
  }
  .re {
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
}}
</style>
