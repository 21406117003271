<template>
  <div class="menu-seasons">
    <div class="wrap">
    <div class="top">
      <div class="top-left">
        <img :src="`${rootUrl}/img/takumi-hirano_pa011.png`" alt="旬がある" />
      </div>

      <div class="top-right">
        <p>
        <p>四季がはっきりしている</p>
        <p>日本では、</p>
        <p>食べ物に旬があります。</p>
        <p>旬とは、</p>
        <p>野菜や魚などが</p>
        <p>とれる季節や、味が美味しい</p>
        <p>時期のことです。
      </p>
      </div>
    </div>
</div>
    <MenuSeasonsSpring style="padding-top:100px;"/>
    <MenuSeasonsSeason style="padding-top:100px;"/>
    <MenuSeasonsFall style="padding-top:100px;"/>
    <MenuSeasonsWinter style="padding-top:100px;"/>
  </div>
</template>

<script>
import MenuSeasonsSpring from "@/components/Menu/Seasons/Spring.vue";
import MenuSeasonsSeason from "@/components/Menu/Seasons/Season.vue";
import MenuSeasonsFall from "@/components/Menu/Seasons/Fall.vue";
import MenuSeasonsWinter from "@/components/Menu/Seasons/Winter.vue";
export default {
  name: "menu-seasons",
  components: {
    MenuSeasonsSpring,
    MenuSeasonsSeason,
    MenuSeasonsFall,
    MenuSeasonsWinter,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.menu-seasons {
padding-bottom: 200px;

  .top {
    display: flex;
    @media screen and (max-width: 767px) {
      display: block;
      margin-left: 20px;
      margin-right: 20px;
    }

    .top-left {
      width: 40%;
      @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
    }

      img {
        width: 80%;
        @media screen and (max-width: 767px) {
      width: 90%;
    }
      }
    }

    .top-right {
      width: 60%;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;

@media screen and (max-width: 767px) {
      width: 100%;
      min-height: 280px;
    }

      p {
        writing-mode: vertical-rl;
        font-size: 20px;
        margin-top: 55px;

        @media screen and (max-width: 767px) {
      font-size: 16px;
    }
      }
    }
  }
}
</style>
