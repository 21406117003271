<template>
  <div class="osechi-info">
    <UiDish :title="'2024年おせちのご案内'" />
    <div class="osechi-cover">
      <div class="text">
        <p>2023年10月1日(日)より販売開始です。</p>
        <p>おせち料理の販売は注文予約システムよりご注文が可能です。</p>
        <!-- <p>販売開始次第、こちらのページにて注文ＵＲＬを公開いたします。</p>
        <p>今しばらくお待ち下さい。</p> -->
        <div>
          <a href="https://chumonyoyaku.com/00101/osechi/" target="_blank" rel="noopener noreferrer" class="osechi-button"> こちらから<br class="sp-only">ご注文ください</a>
            
        </div>
      </div>
      <div class="osechi-item">
        <p class="title">２段重（５～６人前）</p>
        <p>
          販売価格：67,000円<span style="margin-left: 10px">販売個数：50個</span>
        </p>
        <img :src="`${rootUrl}/img/osechi2.jpg`" alt="お弁当" />
        <img :src="`${rootUrl}/img/osechi3.jpg`" alt="お弁当" />
        <div class="des">
          <p>
            鮑柔らか煮、車海老艶煮、祝い黒豆、新寒掘り筍、蛸柔らか煮、鰆幽庵焼、鰤照り焼を含む36品をご用意いたしました。素材本来の味を楽しめる彩り豊かに詰め込んだ豪華2段重おせち。（商品情報は去年のものです。決定次第お知らせします。）
          </p>
        </div>
      </div>
      <div class="osechi-item">
        <p class="title">１段重（２～３人前）</p>
        <p>
          販売価格：33,000円<span style="margin-left: 10px">販売個数：50個</span>
        </p>
        <img :src="`${rootUrl}/img/osechi1.jpg`" alt="お弁当" />
        <div class="des">
          <p>
            鮑柔らか煮、車海老艶煮、祝い黒豆、新寒掘り筍、蛸柔らか煮、鰆幽庵焼、鰤照り焼を含む36品をご用意いたしました。素材本来の味を楽しめる彩り豊かに詰め込んだ豪華おせち。（商品情報は去年のものです。決定次第お知らせします。）
          </p>
        </div>
      </div>
      <div class="osechi-item">
        <p class="title">特選１段重（2〜3人前）</p>
        <p>
          販売価格：52,000円<span style="margin-left: 10px">販売個数：10個</span>
        </p>
        <img :src="`${rootUrl}/img/osechi4.jpg`" alt="お弁当" />
        <div class="des">
          <p>
            越前黒鮑、伊勢海老、北海道産毛蟹、飛騨牛ローストビーフ、鹿児島産新筍、信州飯田産松茸を含む19品を盛り込んだ匠味平野一推しの豪華おせち。日本各地より選りすぐりの食材を使用いたしました。（商品情報は去年のものです。決定次第お知らせします。）
          </p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <p>
        ※写真はイメージです。<br class="sp-only" />実際の商品ではありません。
      </p>
      <router-link to="/" class="to-top">トップページに戻る→</router-link>
    </div>
  </div>
</template>

<script>
import UiDish from "@/components/Ui/Dish.vue";
// import UiPrice from "@/components/Ui/Price.vue";
// import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "lunch-box",
  components: {
    // UiSliderBox,
    UiDish,
    // UiPrice,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.osechi-info {
  padding-top: 50px;
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
}
.osechi-cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  .text {
    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
  .osechi-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0 20px;
    img {
      margin-bottom: 20px;
      width: 50%;
    }
    .des {
      width: 80%;
    }
    .title {
      font-size: 26px;
      margin-bottom: 20px;
      border-bottom: #9a8338 2px solid;
    }
  }
}
.bottom {
  text-align: right;
  margin-right: 30px;
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    // text-align: left;
    font-size: 12px;
    line-height: 1.5em;
    margin-right: 0;
    margin-top: 20px;
  }
  .to-top {
    display: block;
    padding: 30px 0 50px;
    text-decoration: none;
    color: white;
    margin: 0 auto;
    text-align: right;
    font-size: 16px;
    @media screen and (max-width: 767px) {
      margin: 50px 0 30px;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
.osechi-button{
margin: 40px 50px;
// background-color:#3c3c3c;
border-top: #9a8338 2px solid;
border-bottom: #9a8338 2px solid;
padding: 10px 30px;
display: block;
text-decoration: none;
color: white;
transition: all .2s;
font-size: 17px;
text-align: center;
@media screen and (max-width: 767px) {
  margin-top: 50px;
}

&:hover{
background-color: #e6e6e6;
  color: black;
  opacity: 0.8;
  font-weight: bold;

}
}
</style>
