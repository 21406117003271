import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollReveal from 'vue-scroll-reveal';
import VueScrollTo from 'vue-scrollto/vue-scrollto.js'
import VueHead from 'vue-head'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import axios from 'axios'
import VuePrlx from 'vue-prlx'


import ElementUI from 'element-ui';//エレメント
import 'element-ui/lib/theme-chalk/index.css';//エレメント
import locale from 'element-ui/lib/locale/lang/ja'


Vue.use(VueHead)

Vue.use(VueAwesomeSwiper)
// import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/js/swiper.esm'
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
// SwiperClass.use([Pagination, Mousewheel, Autoplay])
// Vue.use(getAwesomeSwiper(SwiperClass))
Vue.use(VuePrlx);

//グローバル登録
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
Vue.prototype.$axios = axios;

Vue.use(ElementUI, { locale });

Vue.config.productionTip = false


Vue.use(VueScrollReveal);

Vue.use(VueScrollTo, {
  offset: -100
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// new Vue({
//   components: { Slick },
// })




// new Vue({
//   el: "#app",
//   components: {
//       Hooper: window.Hooper.Hooper,
//       Slide: window.Hooper.Slide
//   }
// })
