<template>
  <div
    class="footer"
    :style="`background-image: url(${rootUrl}/img/takumi-hirano_015.jpg);`"
  >
    <div class="wrap">
      <img
        class="logo"
        :src="`${rootUrl}/img/takumi-hirano_logo02.png`"
        alt="ロゴ"
      />
      <div class="cover">
        <!-- <img :src="`${rootUrl}/img/takumi-hirano_026.jpg`" alt="お料理" /> -->
        <div class="adress">
          <p>
            &ensp;〒500-8181<br class="sp-kaigyo" /><span
              >岐阜市御浪町10番地</span
            ><span style="font-size: 14px">(御浪ビル１F)</span>
          </p>
          <p>［電話］<br class="sp-kaigyo" /><span>058-263-8644</span></p>
          <p>［営業時間］<br class="sp-kaigyo" /><span>17時30分~</span></p>
          <p>
            ［定休日］<br class="sp-kaigyo" /><span class="close">日曜日、第一・第三月曜日</span
            ><br class="sp-kaigyo" /><span class="tb"
              >（※月曜日が祝日の場合もお休み）</span
            >
          </p>
        </div>
      </div>
      <div class="tel">
        <p class="tel-coment">
          ご予約はお電話で承っております。<br class="sp-kaigyo 951-kaigyo" /></p><p class="tel-no"
            ><a href="tel:0582638644"> 058-263-8644</a></p>
       
      </div>

      <div class="note">
        <p>
         ※営業時間内は、電話に出られない時がありますので、なるべく営業時間外にお問い合わせお願いいたします。
         </p>
      </div>

      <div class="copyright">
        <p>Copyright © Takumi-Hirano All Rights Reserved.</p>
      </div>
    </div>
    <div class="sp-footer" :class="{ 'footer-sp-bottom': path === '/recruit' }">
      <img :src="`${rootUrl}/img/takumi-hirano_028-02.jpg`" alt="SPフッター" />
      <p>Copyright © <br />Takumi-Hirano All Rights Reserved.</p>
    </div>

    <div class="non" :class="{ 'sp-contact-overlay': scrollY >= 100 && path === '/recruit' }">
            <router-link
        to="/recruit-deshi"
        target="_blank"
        rel="noopener noreferrer"
      >
      <img :src="`${rootUrl}/img/kyujin/th_kyujin202305-banner02.jpg`" alt="弟子募集ページ" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "layout-footer",
  components: {},
    props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  
  .logo {
    position: absolute;
    top: 21%;
    right: 12%;
    width: 7%;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
.footer {
  // background-position: center;
  background-position: right 35% bottom 45%;
  background-size: cover;
  position: relative;
  width: 100%;

  @media screen and (max-width: 767px) {
    background-image: none!important;
    background-color: #393939;
    text-align: center;
  }
}
.cover,
.tel,
.note {
  width: 60%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.cover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;

  @media screen and (max-width: 767px) {
    padding-top: 50px;
    margin: 0;
  }

  .adress {
    width: 85%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    p {
      line-height: 2.8em;
      letter-spacing: 0.1em;
      @media screen and (max-width: 767px) {
        line-height: 2em;
        padding: 15px 0;
      }
    }
    .sp-kaigyo {
      @media screen and (max-width: 1400px) and (min-width: 768px) {
        display: none;
      }
    }

    span {
      margin-left: 10px;
      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }
    .tb {
      font-size: 15px;
      margin-left: 0;
      // @media screen and (max-width: 1400px) and (min-width: 768px) {
      //   margin-left: 100px;
      // }
    }
  }
}

.tel {
  text-align: center;
  border-top: solid 1px white;
  border-bottom: solid 1px white;
  padding: 23px 0;
  margin-top: 44px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: 950px) {
flex-direction: column;
  }
  .sp-kaigyo {
    @media screen and (max-width: 1400px) and (min-width: 950px) {
      display: initial;
    }
  }

  .tel-coment {
        letter-spacing: 0.2em;
    @media screen and (max-width: 767px) {
      padding: 0 10px;
    }
  }
  .tel-no {
    font-size: 34px;
    letter-spacing: 0.1em;

    @media screen and (max-width: 767px) {
      font-size: 28px;
    }

    a {
      text-decoration: none;
      color: white;
      pointer-events: none;
      cursor: default;
      @media screen and (max-width: 767px) {
        pointer-events: initial;
        cursor: pointer;
      }
    }
  }
}

.note {
  font-size: 13px;
  margin-bottom: 70px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}

.copyright {
  text-align: center;
  font-size: 13px;
  margin-top: 50px;
padding-bottom: 20px;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.sp-footer {
  position: relative;
  display: none;
  @media screen and (max-width: 767px) {
    display: initial;
  }

  p {
    position: absolute;
    width: 100%;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  }
}
.close{
  letter-spacing: 0.005rem;
}
.non{
  display: none;
}
.sp-contact-overlay {
  position: relative;
  @media screen and (max-width: 767px) {
    display: initial;
    position: fixed;
    right: 0;
    bottom: 0;
    height: 95px;
z-index: 999;
  }
  }
  .footer-sp-bottom {
  @media screen and (max-width: 767px) {
    margin-bottom: 95px;
  }
}
</style>
