<template>
  <div class="recruit-qa">
    <div class="wrap">
      <div class="black-bar">
        <h3>よくある質問</h3>
      </div>
      <div class="block-cover">
        <UiQaQaBox
          v-for="qaBoxListData in qaBoxList"
          :key="qaBoxListData.id"
          :qestion="qaBoxListData.qestion"
          :answer="qaBoxListData.answer"
          class="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiQaQaBox from "../Ui/Qa/QaBox.vue";
export default {
  name: "recruit-qa",
  components: { UiQaQaBox },
  data() {
    return {
      qaBoxList: [
        {
          id: 1,
          qestion: "未経験ですが、応募可能でしょうか？",
          answer:
            "熱い気持ちがあれば、もちろん大丈夫です！料理について基礎からしっかり教えます！",
        },
        {
          id: 2,
          qestion: "フレンチのお店で5年働いていますが日本料理は未経験です。ジャンルが違うので不安ですが大丈夫ですか？",
          answer:
            "ジャンルが違っても料理の基本は同じだと考えています。経験を考慮して、日本料理をお教えしますので一度ご相談ください。",
        },
        {
          id: 3,
          qestion:
            "現在、他の仕事をしていますがどうしても料理人が諦められません。アルバイトから始めることはできますか？",
          answer:
            "料理人は正社員の募集ですが、調理補助としてアルバイトも可能です。アルバイトをして、やはりこの道に進みたいと思えば、本気で料理人を目指すことも可能です。面接時にその熱い想いを教えくてださい。<a href=/>調理補助のアルバイト募集はこちら</a>",
        },
          {
            id: 4,
            qestion: "現在、岐阜県外に住んでいます。遠方からでも働くことは可能でしょうか？",
            answer:
              "距離にもよりますが、車・電車・バス通勤可能です。家賃半額補助制度がありますので、お店の近くでお部屋を借りることも可能です。一度ご相談ください。",
          },
      ],
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-qa {
  padding-top: 200px;
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .black-bar {
    background-color: var(--black);
    color: white;
    font-size: 22px;
    padding: 15px 0;
    margin-bottom: 50px;
        @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
}
</style>
