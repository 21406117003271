<template>
  <div class="cotacnt-form">
    <div class="black-bar">
      <h3>お問い合わせ</h3>
    </div>
    <div class="title">
      <div v-if="pageType === 0">
        <h3>
          お問い合わせフォームからのご応募・お問い合わせは24時間受付しています。<br />
        </h3>
        <p>
          ※当お問い合わせフォームは求人専用です。<br>
          店舗のご利用に関するお問い合わせは<br
            class="sp-kaigyo"
          />お電話にてお願いいたします。
        </p>
      </div>
      <div v-else-if="pageType === 1">
        <h3>
          入力内容にお間違いがないかご確認の上、「送信」ボタンを押して下さい。
        </h3>
      </div>
    </div>
    <div class="out-cover">
      <div class="cover">
        <div class="form" v-if="pageType === 0 || pageType === 1">
          <div class="input-cover">
            <p class="sub">お名前<span>(※必須)</span></p>
            <div v-if="pageType === 0" class="row-cover">
              <input
                v-model="form.name"
                type="text"
                class="row1"
                placeholder="例）山田 花子"
              />
            </div>
            <div v-else-if="pageType === 1" class="confirmation">
              {{ form.name }}
            </div>
          </div>

          <div class="input-cover">
            <p class="sub">連絡先メールアドレス<span>(※必須)</span></p>
            <div v-if="pageType === 0" class="row-cover">
              <input
                v-model="form.mail"
                type="email"
                class="row1"
                placeholder="例）sample@gmail.com"
              />
            </div>
            <div v-else-if="pageType === 1" class="confirmation">
              {{ form.mail }}
            </div>
          </div>

          <div class="input-cover">
            <p class="sub">電話番号<span>(※必須)</span></p>
            <div v-if="pageType === 0" class="row-cover">
              <input
                v-model="form.tel"
                type="tel"
                class="row1"
                placeholder="例）090-1234-5678"
              />
            </div>
            <div v-else-if="pageType === 1" class="confirmation">
              {{ form.tel }}
            </div>
          </div>

          <div class="input-cover">
            <p class="sub">お問い合わせ区分<span>(※必須)</span></p>
            <div v-if="pageType === 0" class="row-cover select">
              <select v-model="form.type">
                <option value="">選択してください</option>
                <option value="0">料理人</option>
                <option value="1">調理補助スタッフ</option>
                <option value="2">ホールスタッフ</option>
              </select>
              <!-- <label class="sp"
              ><input
                v-model="form.type"
                type="radio"
                :value="0"
              />セラピーについて</label
            >
            <label class="sp"
              ><input
                v-model="form.type"
                type="radio"
                :value="1"
              />ご予約について</label
            >
            <label class="sp"
              ><input
                v-model="form.type"
                type="radio"
                :value="9"
              />その他</label
            > -->
            </div>
            <div v-else-if="pageType === 1" class="confirmation">
              <span v-show="form.type === '0'">料理人</span>
              <span v-show="form.type === '1'">調理補助スタッフ</span>
              <span v-show="form.type === '2'">ホールスタッフ</span>
            </div>
          </div>

          <div class="input-cover">
            <p class="sub">お問い合わせ内容<span>(※必須)</span></p>
            <div v-if="pageType === 0">
              <textarea
                v-model="form.description"
                placeholder="こちらにお問い合わせ内容を入力してください。"
              ></textarea>
            </div>
            <div v-else-if="pageType === 1" class="confirmation">
              {{ form.description }}
            </div>
          </div>
        </div>
        <div v-if="pageType === 0">
          <p style="text-align: center;">
            記入が終わりましたら、下の「確認」を押し、ご記入内容をご確認ください。
          </p>
        </div>

        <div @click="confirm" class="button" v-if="pageType === 0">
          <!-- <div class="button" v-if="pageType === 0"> -->
          <div><p>確認</p></div>
        </div>
        <div class="soushin" v-if="pageType === 1">
          <div @click="back" class="button"><p>修正</p></div>
          <div @click="send" class="button"><p>送信</p></div>
          <!-- @click="send"を本番は復活させること -->
        </div>
        <div v-else-if="pageType === 2" class="complete">
          <h3 style="margin-bottom: 50px; text-align: center">
            お問い合わせを承りました
          </h3>
          <p>
            お問い合わせ内容を、ご入力のメールアドレスに送信しました。<br />20分経過しても届かない場合は、迷惑メールボックスの中をご確認ください。
          </p>
          <!-- <router-link to="/"> -->
          <div class="button2" @click="top">トップに戻る</div>
          <!-- </router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CotacntForm",
  components: {},
  props: {
    type: Number,
  },
  data() {
    return {
      pageType: 0,
      form: {
        // companyName: "",
        name: "",
        tel: "",
        mail: "",
        type: "",
        description: "",
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  // created() {
  //   if (this.$route.path === "/pg") {
  //     this.form.type = "0";
  //   } else if (this.$route.path === "/web") {
  //     this.form.type = "1";
  //   } else if (this.$route.path === "/web-design") {
  //     this.form.type = "2";
  //   } else if (this.$route.path === "/design") {
  //     this.form.type = "3";
  //   } else if (this.$route.path === "/marketing") {
  //     this.form.type = "4";
  //   } else if (this.$route.path === "/ad") {
  //     this.form.type = "5";
  //   } else if (this.$route.path === "/keiri") {
  //     this.form.type = "6";
  //   } else if (this.$route.path === "/jimu") {
  //     this.form.type = "7";
  //   } else {
  //     this.form.type = "";
  //   }
  // },
  methods: {
    confirm() {
      // alert(this.form.companyName);
      // console.log(`company name: ${this.form.companyName}`);
      // console.log(`company name: ${this.form}`);//※ダメなやつ

      console.log(this.form);

      let validation_ok = true;
      let validation_message = "";
      console.log(validation_ok);
      console.log(validation_message);

      //お名前チェック
      if (this.form.name !== "") {
        console.log("お名前 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お名前」";
      }
      //メールアドレスチェック
      if (this.form.mail !== "") {
        var mailAddress = this.form.mail;
        var hotResult = mailAddress.indexOf("hotmail.co.jp");
        var outResult = mailAddress.indexOf("outlook.jp");

        // hotmail,outlookチェック
        if (hotResult === -1 && outResult === -1) {
          //含まれていない
        } else {
          validation_ok = false;
          alert(`hotmail. outlookは使えません`);
          return;
        }

        console.log("メールアドレス ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「メールアドレス」";
      }
      //電話番号チェック
      if (this.form.tel !== "") {
        console.log("電話番号 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「電話番号」";
      }
      //お問い合わせ区別チェック
      if (this.form.type !== "") {
        console.log("お問い合わせ区分 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お問い合わせ区分」";
      }
      //購入内容チェック
      // if (this.form.type === 0) {
      //   if (
      //     this.form.bottle1Num !== 0 ||
      //     this.form.bottle2Num !== 0 ||
      //     this.form.bottle3Num !== 0
      //   ) {
      //     console.log("購入内容 ok");
      //   } else {
      //     validation_ok = false;
      //     validation_message = validation_message + "「購入本数」";
      //   }
      // } else {
      //   noaction
      // }
      //内容チェック
      if (this.form.description !== "") {
        console.log("お問い合わせ内容 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お問い合わせ内容」";
      }

      //結果発表
      if (validation_ok === true) {
        this.pageType = 1;
        return;
      } else {
        alert(`${validation_message}を入力してください`);
        return;
      }
    },
    send() {
      let params = new URLSearchParams();
      params.append("name", this.form.name);
      params.append("tel", this.form.tel);
      params.append("mail", this.form.mail);
      params.append("type", this.form.type);
      params.append("description", this.form.description);
      this.$axios
        .post(`${this.rootUrl}/api/send.php`, params)
        .then((response) => {
          console.log(response);
          alert(response.data.message);
          if (response.data.status === 200) {
            // console.log('ok')
            this.pageType = 2;
            return;
          } else if (
            response.data.status === 400 ||
            response.data.status === 500
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      // alert("本当に戻りますか？");
      this.pageType = 0;
    },
    top() {
      this.$scrollTo("#app", 500, { easing: "ease" });
      this.pageType = 0;
      this.form = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.cotacnt-form {
  padding-bottom: 100px;
}
.black-bar {
  background-color: var(--black);
  color: white;
  font-size: 22px;
  padding: 15px 0;
  margin-bottom: 50px;
}
h3 {
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}
.title {
  p {
    font-size: 20px;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
      font-size: 16px;
    }
  }
}
.out-cover {
  background-color: var(--cream);
  padding-top: 50px;
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    padding-top: 10px;
  }
}
.cover {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  @media screen and (max-width: 767px) {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
}
.form {
  margin-bottom: 50px;
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }

  .input-cover {
    text-align: left;
    margin-bottom: 30px;
    .sub {
      font-size: 18px;
      margin-bottom: 5px;
    }
    input,
    textarea {
      border: none;
    }
    .row-cover,
    .confirmation {
      border: 1px solid #a7a5a4;
    }
    .select {
      display: inline-block;
      position: relative;
      border: 1px solid #eee;
      // width: 100%;
      // height: 70px;
      vertical-align: middle;
    }
    .select::before {
      position: absolute;
      top: 22px;
      right: 16px;
      width: 0;
      height: 0;
      border-width: 15px 10px 0 10px;
      border-style: solid;
      border-color: var(--black) transparent transparent transparent;
      content: "";
      pointer-events: none;
    }
    select {
      appearance: none;
      min-width: 300px;
      padding: 20px 15px;
      padding-right: 1em;
      border: 1px solid #a7a5a4;
      outline: 0;
      background: #fff;
      background-image: none;
      box-shadow: none;
      text-indent: 0.01px;
      text-overflow: ellipsis; // 文字の省略
      cursor: pointer;
      font-size: 18px;
      @media screen and (max-width: 767px) {
        min-width: 250px;
      }
    }

    .row1,
    textarea {
      width: 100%;
      padding: 20px 10px;
      font-size: 20px;
      resize: auto;
      cursor: text;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      column-count: initial !important;
      word-break: break-all;
    }

    textarea {
      border: 1px solid #a7a5a4;
      height: 300px;
      text-rendering: auto;

      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }

    .sp {
      &:nth-child(n + 2) {
        margin-left: 20px;
        @media screen and (max-width: 767px) {
          margin-left: 0;
        }
      }

      @media screen and (max-width: 767px) {
        display: block;
      }
    }
  }
}
.button,
.button2 {
  border: 1px solid #a7a5a4;
  color: var(--black);
  background-color: white;
  width: 200px;
  text-align: center;
  margin: 50px auto;
  cursor: pointer;
  transition: 0.8s;
  p {
    font-size: 18px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 767px) {
    width: 200px;
    padding: 20px 0px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    // color: white !important;
    background-color: var(--gold);
    transition: 0.8s;
  }
}

.soushin {
  display: flex;
  justify-content: space-between;
  .button,
  .button2 {
    width: 150px;
    @media screen and (max-width: 767px) {
    }
    width: 40%;
    font-size: 18px;
  }
}

.complete {
  text-align: left;
  h2 {
    @media screen and (max-width: 767px) {
      font-size: 25px;
    }
  }
  a {
    text-decoration: none;
    color: black;

    .button2 {
      width: 170px;
      margin-top: 70px;
      margin-bottom: 50px;
    }
  }
  p {
    text-align: center;
    @media screen and (max-width: 767px) {
      text-align: left;
    }
  }
}

.confirmation {
  padding: 20px;
}
</style>
