<template>
  <div class="recruit-new-a-day">
    <div class="wrap">
      <div class="happy-cover">
        <div class="img-cover">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa006-1.png`"
            alt="匠味 平野の1日"
            class="base"
          />
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa006-2.png`"
            alt="匠味 平野の1日2"
            class="deco" style="z-index:100;"
            :class="{ fadeIn: displayData.display01 === true }"
          />
        </div>
        <div class="list-cover">
          <div class="list">
            <div class="left">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa007.png`"
                alt="パターン1"
                class="maru-g"
              />
              <p>10時</p>
            </div>
            <p class="right">出勤：仕込みをはじめます。</p>
          </div>
          <div class="list">
            <div class="left">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa007.png`"
                alt="パターン1"
                class="maru-g"
              />
              <p>11時頃</p>
            </div>
            <p class="right">休憩：朝のコーヒー休憩タイムです。</p>
          </div>
          <div class="list">
            <div class="left">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa007.png`"
                alt="パターン1"
                class="maru-g"
              />
              <p>14時頃</p>
            </div>
            <p class="right">昼食：まかないをみんなで食べます。</p>
          </div>
          <div class="list">
            <div class="left">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa007.png`"
                alt="パターン1"
                class="maru-g"
              />
              <p>14時～16時</p>
            </div>
            <p class="right">順番に休憩：この時間に順番に休憩をとります。</p>
          </div>
          <div class="list">
            <div class="left">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa007.png`"
                alt="パターン1"
                class="maru-g"
              />
              <p>16時頃</p>
            </div>
            <p class="right">休憩：営業前にひと休憩です。</p>
          </div>
          <div class="list">
            <div class="left">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa007.png`"
                alt="パターン1"
                class="maru-g"
              />
              <p>17時半</p>
            </div>
            <p class="right">営業開始：お客様へおもてなし。</p>
          </div>
          <div class="list">
            <div class="left">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa007.png`"
                alt="パターン1"
                class="maru-g"
              />
              <p>22時頃</p>
            </div>
            <p class="right">
              営業終了：片付け、発注、翌日の献立打ち合わせなどします。<br />夜のまかないもこの時間です。まかないはお弁当にして持って帰ることも可能です。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "recruit-new-a-day",
  components: {},
    props: {
    // displayDefinitionList: Array,
    configList: Array
  },
  data() {
    return {
      displayDefinitionList:[],
       displayData: {
        display01: false,
        // display02: false,
      },

    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },

  watch: {
    // ４．あとは同じ
    scrollY(offset) {
      //
      let displayList = this.displayDefinitionList.filter(function (
        displayDefinitionData
      ) {
        return displayDefinitionData.offset <= offset;
      });

      //
      displayList.forEach((displayData) => {
        this.displayData[displayData.display] = true;
      });
    },
  },
      created() {
    // ２．configListから、画面幅に合致するものを取得
    let configList = this.configList.filter(function (configData) {
      return window.matchMedia(configData.matchMedia).matches;
    });

    // ３．dataのdisplayDefinitionListに、取得できたものを格納
    console.log("===== configList =====");
    console.log(configList);
    if (configList.length > 0) {
      this.displayDefinitionList = configList[0].displayDefinitionList;
    } else {
      // no action.
    }
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.recruit-new-a-day {
  background-color: var(--black);
  padding-top: 200px;
  padding-bottom: 200px;
  position: relative;
  @media screen and (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .happy-cover {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      // padding-top: 100px;
      display: block;
    }
    .img-cover {
      width: 28%;
      position: relative;
      @media screen and (max-width: 767px) {
        width: 80%;
        margin: 0 auto;
      }
    }
    .base {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      @media screen and (max-width: 767px) {
        position: relative;
      }
    }

    .list-cover {
      width: 68%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .list {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 20px;
        @media screen and (max-width: 1100px) {
          margin-top: 30px;
          display: block;
        }
        .left {
          display: flex;
          width: 30%;
          align-items: flex-start;
          @media screen and (max-width: 1100px) {
            width: 100%;
          }
          .maru-g {
            width: 30px;
            margin-right: 20px;
            @media screen and (max-width: 767px) {
              margin-right: 10px;
            }
          }
          p {
            color: var(--gold);
            font-size: 25px;
          }
        }
        .right {
          text-align: left;
          color: white;
          font-size: 19px;
          width: 70%;
          @media screen and (max-width: 1100px) {
            margin-left: 40px;
            font-size: 18px;
            width: calc(100% - 40px);
          }
        }
      }
    }
  }
}
</style>
