<template>
  <div class="ctrl-info">
    
    <h1>お知らせ投稿 編集画面</h1>

    <div style="margin-top: 50px" v-if="pageType === 0">
      <el-col>
        <el-switch
          v-model="infoShow"
          active-text="お知らせを表示"
          inactive-text="お知らせを非表示"
        >
        </el-switch>
      </el-col>
    </div>

    <div class="cover" v-if="pageType === 0">
      <h3>タイトル</h3>
      <div class="frame">
        <!-- <el-date-picker v-model="postDate" type="date" placeholder="日時を選択してください"> -->
        <el-input
          placeholder="タイトルをお書きください"
          v-model="title"
        ></el-input>
        <!-- </el-date-picker> --> </div>
      <h3 style="margin-top:20px;">本文</h3>
      <div class="frame">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="投稿したい内容をお書きください"
          v-model="message"
        >
        </el-input>
      </div>
      <div class="button-cover">
        <el-button @click="post" type="success" class="soushin">投稿する</el-button>
    </div>
</div>
    <div class="cover" v-else-if="pageType === 1">
        <p style="margin-bottom:20px;">お知らせを投稿しました。<br class="sp-kaigyo">トップページをご確認ください。</p>
<div class="button-cover"> 
        <el-button @click="backToLogin" type="success">戻る</el-button>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cotrl-info",
  components: {},
  data() {
    return {
      pageType: 0,
      infoShow: true,
      title: "",
      message: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  watch: {
    infoShow(newValue, oldValue) {
      let infoShow = "0";
      console.log(oldValue);
      if (newValue === true) {
        infoShow = "1";
      } else if (newValue === false) {
        infoShow = "0";
      } else {
        //noaction
      }
      // alert(infoShow);
      this.$axios;
      let params = new URLSearchParams();
      params.append("info_show", infoShow);
      this.$axios
        .post(`${this.rootUrl}/api/infoshow-check.php`, params)
        .then((response) => {
          console.log(response);
          // alert(response.data.status);
          if (response.data.status === 200) {
            // alert(response.data.message);
            // console.log('ok')
            // this.pageType = 1;
            return;
          } else if (
            response.data.status === 401 ||
            response.data.status === 400
          ) {
            // alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    // var def = this.abc(1);
    // alert(def);
    this.$axios
      .get(`${this.rootUrl}/api/get-post.php`)
      .then((response) => {
        console.log(response);
        // alert(response.data.status);
        if (response.data.status === 200) {
          // alert(response.data.message);
          // console.log('ok')
          this.title = response.data.title;
          this.message = response.data.message;
          // this.pageType = 1;
          return;
        } else if (
          response.data.status === 401 ||
          response.data.status === 400
        ) {
          alert(response.data.message);
          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    post() {
      let params = new URLSearchParams();
      params.append("title", this.title);
      params.append("message", this.message);
      let visible = '0';
      if (this.infoShow === true) {
        visible = '1';
      }else if(this.infoShow === false){
         visible = '0';
        }else{
          alert(this.infoShow);
          return;
      }
      params.append("visible", visible );
      this.$axios
        .post(`${this.rootUrl}/api/reg-post.php`, params)
        .then((response) => {
          console.log(response);
          // alert(response.data.status);
          if (response.data.status === 200) {
            alert(response.data.message);
            // console.log('ok')
            this.pageType = 1;
            return;
          } else if (
            response.data.status === 401 ||
            response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    backToLogin() {
      this.$router.push("/ctrl");
    },
    abc(value) {
      if (value === 0) {
        return "🌵";
      } else if (value === 1) {
        return "🐤";
      } else {
        //noaction
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ctrl-info {
  background-color: #f4f4ef;
  color: black;
  // height: 600px;
  h1 {
    padding-top: 30px;
       @media screen and (max-width: 767px){
    font-size: 25px; 
   }
  }
  .cover{
    padding: 20px;
    .frame{
      width: 80%;
      margin: 10px auto;
      @media screen and (max-width: 768px) {
        width: 95%;
      }
    }
    .button-cover{
display: flex;
justify-content: center;
    }
  }
.el-col {
  text-align: center;
  padding-bottom: 20px;
}
::v-deep.el-switch {
  .el-switch__label,
  span {
    font-size: 20px;
       @media screen and (max-width: 767px){
    font-size: 16px; 
   }
  }
}
.title {
  text-align: right;
  padding-right: 30px;
}

p {
  color: black;
}
    }
</style>
