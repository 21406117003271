<template>
  <div class="menu-seasons-season">
    <div class="summer">
      <div class="summer-left">
        <img
          :src="`${rootUrl}/img/takumi-hirano_pa009.png`"
          alt="ヘッダー画像2"
        />
        
        <p>風花が舞う季節。</p>
        <p>寒い冬を越すために</p>
        <p>魚には脂が乗り、野菜は糖分を蓄え</p>
        <p>ますます美味しくなります。</p>
        <p>のどぐろ、牡蠣、河豚、ズワイガニ…</p>
        <p>冬のごちそうは絶品です。</p>
      </div>
      <div class="summer-right">
        <UiSliderBox
          :images="images"
          :swiperOption="swiperOption"
          :height="100"
          :width="100"
          :button="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "menu-seasons-season",
  components: {
    UiSliderBox,
  },
  data() {
    return {
      images: [
        { id: 1, imgpath: "hirano_oshinagaki-2021fuyu001.jpg", name: "八寸" },
        { id: 2, imgpath: "hirano_oshinagaki-2021fuyu002.jpg", name: "蟹" },
        // { id: 3, imgpath: "hirano_oshinagaki-2021fuyu003.jpg", name: "蕎麦" },
        { id: 3, imgpath: "hirano_oshinagaki-2021fuyu004.jpg", name: "冬景色" },
        { id: 4, imgpath: "hirano_oshinagaki-2021fuyu005.jpg", name: "蛸" },
        { id: 5, imgpath: "hirano_oshinagaki-2021fuyu006.jpg", name: "河豚" },
        { id: 6, imgpath: "hirano_oshinagaki-2021fuyu007.jpg", name: "冬料理" },
        { id: 7, imgpath: "hirano_oshinagaki-2021fuyu008.jpg", name: "冬料理" },
      ],
      swiperOption: {
        slidesPerView: 1,
        speed: 6000,
        effect: "fade",
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 300,
          disableOnInteraction: false,
        },
      },
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.summer {
  display: flex;
  justify-content: flex-start;
  // width: 100vh;
  // justify-content: flex-start;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }

  .summer-left {
    width: calc(1100px * 0.35);
    padding-left: 10px;
    margin-left: calc(calc(100vw - 1100px) / 2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;

    @media screen and (max-width: 1100px) {
      width: 90%;
      margin: 0 0 15px 0;
      height: 500px;
      padding: 0 30px;
    }

    img {
      width: 200px;
      @media screen and (max-width: 1100px) {
        width: 200px;
      }
    }

    p {
      font-size: 16px;
      letter-spacing: 0.1px;
    }
  }
  .summer-right {
    width: calc(calc(calc(100vw - 1100px) / 2) + calc(1100px * 0.65));
    //↑右だけ1100px超えて出したいとき使うやつね！
    height: auto;
    align-self: flex-start;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 1100px) {
      width: 100% !important;
      margin-top: 30px;
    }

    ::v-deepimg {

      @media screen and (min-width:769px) and ( max-width:110px) {
        width: 100%;
      }
      @media screen and (max-width: 768px) {
        object-fit: cover;
        object-position: 50% 50%;
        height: 300px;
      }
    }
  }
}
</style>
