<template>
  <div class="recruit-new-message">
    <div class="cover wrap">
      <div class="takumi">
        <img
          :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa002.png`"
          alt="和イラスト"
          class="gold"
        />
        <img
          :src="`${rootUrl}/img/kyujin/th_kyujin202305-006.jpg`"
          alt="匠味 平野"
          class="takumi-img"
        />
        <p style="text-align: center;">店主 平野功治</p>
      </div>
      <div class="text">
        <h3>店主 平野からメッセージ</h3>
        <p>
          匠味 平野の求人ページをご覧いただき、ありがとうございます。
          <br />
          匠味 平野では食材や技術はもちろん、「おもてなしの心」をとても大切にしています。お客様の大切なひと時を、心から満足してお過ごしいただくためには、お料理、器、タイミング、接客から空間の細部まで行き届いたおもてなしが欠かせないと考えています。この想いに共感し、一緒にお店を盛り上げてくれる仲間を匠味
          平野ではお待ちしています。
          <br />
          一緒に働く仲間にとっても、匠味 平野で働いている時は、「良い時間・成長できる時間」であってほしいと考えています。時間は誰にでも平等です。将来独立してお店を構えたい。料理が好き。着物が着れるようになりたい。日本料理のマナーが知りたい。美味しいまかないが食べたい。その人にとって少しでもプラスになるのなら、どんな理由で働いてもかまいません。一生懸命働いてくれる仲間には、ぜひ匠味
          平野で働いてよかったと思ってもらいたいというのが私の想いです。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recruit-new-message",
  components: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-new-message {
  background-color: var(--black);
  padding-top: 200px;
  padding-bottom: 200px;
  position: relative;
  @media screen and (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 70px;
    // overflow: hidden;      
  }
  .gold {
    z-index: 10;
    position: absolute;
    width: 13%;
    top: -90px;
    right: calc(calc(100vw - 1100px) * 0.36);
    animation: rotate-anime 60s linear infinite;
    @media screen and (min-width:768px) and ( max-width:1100px){
      right: 28px;
      width: 140px;
    }

    @media screen and (max-width: 767px) {
      // right: calc(calc(100vw - 1100px) * 0.36);
      right: 8%;
      width: 150px;
      //後で直す
    }
  }
  @keyframes rotate-anime {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .cover {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px){
      flex-direction: column;
    }
    .takumi {
      width: 25%;
      text-align: left;
      @media screen and (max-width: 767px){
        width: 80%;
      }
      p {
        letter-spacing: 0.15em;
        font-size: 20px;
        span {
          font-size: 18px;
        }
      }
    }
    .text {
      text-align: left;
      width: 70%;
      @media screen and (max-width: 767px){
        width: 80%;
      }
      h3 {
        border-bottom: 1px solid white;
        font-size: 25px;
        padding-bottom: 10px;
        margin-bottom: 50px;
        @media screen and (max-width: 767px){
          font-size: 20px;
          margin-top: 50px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
