<template>
  <div class="ui-drawer">
    <div class="drawer-wrap">
      <!-- <img
        @click="switchDrawer"
        :src="`${rootUrl}/img/takumi-hirano_001.png`"
        class="drawer-button"
        alt="メニュー"
      /> -->
      <div
        class="btn-trigger btn05"
        @click="hamburgerSet"
        :class="{
          active: active === true,
          '': active === false,
        }"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div></div>
      <div
        class="drawer-menu"
        :class="{
          'open-drawer': openDrawer === true,
          'close-drawer': openDrawer === false,
        }"
      >
        <!-- <div class="drawer-header">
          <img
            @click="switchDrawer"
            :src="`${rootUrl}/img/takumi-hirano_002.png`"
            class="close-button"
            alt="ドロワーメニュー"
          />
        </div> -->
        <div @click="hamburgerSet" class="drawer-body">
          <ul>
            <li>
              <img
                :src="`${rootUrl}/img/takumi-hirano_logo02.png`"
                class="logo"
                alt="匠味平野ロゴ"
              />
            </li>
            <li>
              <router-link to="/">トップページ </router-link>
            </li>
            <li>
              <router-link to="/menu"> お品書き </router-link>
            </li>
            <li>
              <router-link to="/access"> 店舗情報・アクセス </router-link>
            </li>
            <li>
              <router-link to="/recruit"> 求人 </router-link>
              <!-- <a>求人</a> -->
            </li>
            <li>
              <router-link to="/recruit-deshi"> 弟子募集 </router-link>
              <!-- <a>求人</a> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      @click="hamburgerSet"
      class="back-gray"
      :class="{ 'back-gray-show': openDrawer === true }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "UiDrawer",
  data() {
    return {
      openDrawer: false,
      active: false,
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
      // return this.$store.getters.rt;
    },
  },
  methods: {
    switchDrawer() {
      this.openDrawer = this.openDrawer === false ? true : false;
    },
    hamburgerButton() {
      this.active = this.active === false ? true : false;
    },
    hamburgerSet() {
      this.switchDrawer();
      this.hamburgerButton();
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 100%;
.drawer-wrap {

  .drawer-button {
    width: 50px;
    cursor: pointer;
  }
  .drawer-menu {
    transition: all 1500ms 0s ease;
    position: fixed;
    z-index: 999;
    top: 0;
    // background-color: rgba(105, 105, 105,0.6);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.4);
    background-blend-mode: darken;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100%; //要らないかも？
    display: flex;
    justify-content: center;
    align-items: center;

    .drawer-body {
      padding-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

  
      ul {
        li {
          text-align: center;
          a {
            position: relative;
            display: inline-block;
            text-decoration: none;
            color: var(--white);
            margin-bottom: 20px;
            margin-top: 20px;
            font-size: 23px;

            @media screen and (max-width: 767px){
              margin-bottom: 15px;
              margin-top: 15px;
              font-size: 18px;
  }
          }

          a:after {
            position: absolute;
            bottom: -10px;
            left: 0;
            content: "";
            width: 100%;
            height: 2px;
            background: gray;
            transform: scale(0, 1);
            transform-origin: left top;
            transition: transform 0.8s;
          }

          a:hover::after {
            transform: scale(1, 1);
          }

          img {
            width: 30%;
            margin-bottom: 10px;
            margin-top: 10px;

            @media screen and (max-width: 767px){
              width: 25%;
              margin-bottom: 20px;
  }
          }
        }
      }
    }
  }
}
.open-drawer {
  // display: initial;
  opacity: 1;
  transform: translateX(-$width);
  right: -$width;
}
.close-drawer {
  // display: none;
  opacity: 0;
  transition: opacity 3s linear 2s;
  transform: translateX($width);
  right: -$width;
}

.back-gray {
  display: none;
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}
.back-gray-show {
  display: initial;
}
//新しいハンバーガーボタン
.btn-trigger {
  position: relative;
  width: 40px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;
   @media screen and (max-width: 1024px){
    //  width: 40px;
    //  height: 35px;

   }
}
.btn-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
}
.btn-trigger,
.btn-trigger span {
  display: inline-block;
  transition: all 0.5s;
  box-sizing: border-box;
}
.btn-trigger span:nth-of-type(1) {
  top: 0;
}
.btn-trigger span:nth-of-type(2) {
  top: 13px;
}
.btn-trigger span:nth-of-type(3) {
  bottom: 0;
}
.btn05.active span:nth-of-type(1) {
  -webkit-transform: translateY(20px) rotate(-45deg);
  transform: translateY(20px) rotate(-45deg);
}
.btn05.active span:nth-of-type(2) {
  left: 50%;
  opacity: 0;
  -webkit-animation: active-btn05-bar02 0.8s forwards;
  animation: active-btn05-bar02 0.8s forwards;
}
@-webkit-keyframes active-btn05-bar02 {
  100% {
    height: 0;
  }
}
@keyframes active-btn05-bar02 {
  100% {
    height: 0;
  }
}
.btn05.active span:nth-of-type(3) {
  -webkit-transform: translateY(-20px) rotate(45deg);
  transform: translateY(-20px) rotate(45deg);
    bottom: -12px;
}
</style>