import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rootUrl: 'https://takumi-hirano.com',
    scrollY: 0,
  },
  getters: {
    rootUrl(state) {
      return state.rootUrl
    },
    login(state) {
      return state.login
    },
    scrollY(state) {
      return state.scrollY
    },
  },
  mutations: {
    setRootUrl(state, payload) {
      state.rootUrl = payload;
    },
    setLogin(state, payload) {
      state.login = payload;
    },
    setScrollY(state, payload) {
      state.scrollY = payload.scrollY;
    },
  },
  actions: {
    setRootUrl({
      commit
    }) {
      let rootUrl = process.env.NODE_ENV === 'production' ? "https://takumi-hirano.com" : "http://takumi-hirano.localhost";

      commit('setRootUrl', rootUrl);
    },
    setScrollY({
      commit
    }, scrollY) {
      let payload = {
        scrollY: scrollY
      };
      commit('setScrollY', payload);
    },
    setLogin({
      commit
    }, payload) {
      commit('setLogin', payload);
    },
    loginCheck({
      getters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${getters.rootUrl}/api/login-check.php`)
          .then(res => {
            console.log(res.data);
            //[status: 200, message: "ログイン失敗"]
            resolve(res);
            // resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      })
    },
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
