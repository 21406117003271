<template>
  <div class="menu">
    <MenuCuisine />

    <div class="wrap">
      <div class="cover">
        <MenuLunchBox />
      </div>
    </div>

    <MenuSeasons style="padding-top: 100px;" class="white" />
    <MenuSoba class="white2" />
  </div>
</template>

<script>
import MenuCuisine from "@/components/Menu/Cuisine.vue";
import MenuLunchBox from "@/components/Menu/LunchBox.vue";
import MenuSeasons from "@/components/Menu/Seasons.vue";
import MenuSoba from "@/components/Menu/Soba.vue";

export default {
  name: "menu-list",
  components: {
    MenuCuisine,
    MenuLunchBox,
    MenuSeasons,
    MenuSoba,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .cover {
      background-color: rgba($color: black, $alpha: 0.9);
      margin-top: 100px;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;
      @media screen and (max-width: 767px) {
        width: 100%;
        // background-color: transparent;
      }
    }
  }
  .white {
    background-color: rgb(241, 235, 226);
    color: black;
  }
  .white2 {
    background-color: rgb(255, 255, 255);
    color: black;
  }
}
</style>
