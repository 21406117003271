<template>
  <div class="commitment">
    <div class="massage" id="change-line">
      <h1>匠味 平野のこだわり</h1>
      <p>季節ごとに<br class="sp-kaigyo" />最高の食材を提供しています。</p>
      <p>
        食材へのこだわりは、<br />長年愛され続けるお店でありたいが<br
          class="sp-kaigyo"
        />ゆえに、<br class="pc-kaigyo" />努力はおしみません。
      </p>
      <!-- <h3>「目で和み、味で楽しむ」</h3> -->
      <p>匠味平野の料理は食べる人を<br class="sp-kaigyo" />幸せにします。</p>
      <p>店主 平野 功治</p>
    </div>
    <div class="slider">
      <swiper :options="swiperOption">
        <swiper-slide
          ><img
            :src="`${rootUrl}/img/takumi-hirano_kodawari_001-490×270.jpg`"
            alt="竹"
        /></swiper-slide>
        <swiper-slide
          ><img
            :src="`${rootUrl}/img/takumi-hirano_kodawari_002-490×270.jpg`"
            alt="花火"
        /></swiper-slide>
        <swiper-slide
          ><img
            :src="`${rootUrl}/img/takumi-hirano_kodawari_003-490×270.jpg`"
            alt="川"
          />
        </swiper-slide>
        <swiper-slide
          ><img
            :src="`${rootUrl}/img/takumi-hirano_kodawari_004-490×270.jpg`"
            alt="鵜飼"
        /></swiper-slide>
        <swiper-slide
          ><img
            :src="`${rootUrl}/img/takumi-hirano_kodawari_005-490×270.jpg`"
            alt="長良川"
        /></swiper-slide>
        <swiper-slide
          ><img
            :src="`${rootUrl}/img/takumi-hirano_kodawari_006-490×270.jpg`"
            alt="もみじ"
          />
        </swiper-slide>
        <swiper-slide
          ><img
            :src="`${rootUrl}/img/takumi-hirano_kodawari_007-490×270.jpg`"
            alt="風景"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  name: "commitment",
  components: {},
  data() {
    return {
      swiperOption: {
        direction: "vertical",
        mousewheel: false,
        loop: true,
        speed: 6000,
        // effect: "slide",
        autoHeight: true,
        freeMode: true,
        slidesPerView: 3,
        spaceBetween: 20,
        autoplay: {
          delay: 0,
          disableOnInteraction: true,
        },
      },
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  created() {
    if (window.matchMedia("(min-width:768px)").matches) {
      //no action.
    } else if (window.matchMedia("(max-width: 767px)").matches) {
      this.swiperOption.direction = "horizontal";
      this.swiperOption.slidesPerView = "1";
    } else {
      //no action.
    }
  },
  methods: {},
  plugins: [{ src: "~/plugins/vue-awesome-swiper", mode: "client" }],
};
</script>

<style lang="scss" scoped>
.commitment {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 768px) and (max-width: 930px) {
    justify-content: space-between;
    // margin-bottom: 100px;
  }

  @media screen and (max-width: 767px) {
    display: block;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .massage {
    text-align: center;
    width: 50%;
    // line-height: 2.4em;
    @media screen and (min-width: 768px) and (max-width: 930px) {
      text-align: left;
    }

    p,
    h1,
    h3 {
      margin: 30px;
      letter-spacing: 0.2em;
      // white-space: nowrap;
    }
    h1 {
      margin-bottom: 70px;
      @media screen and (max-width: 767px) {
        font-size: 23px;
        margin-bottom: 50px;
      }
    }
    h3 {
      font-size: 23px;

      @media screen and (max-width: 767px) {
        font-size: 22px;
        letter-spacing: initial;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;

      p,
      h1,
      h3 {
        white-space: initial;
      }

      p {
        font-size: 14px;
      }
    }
  }
  .swiper-container {
    height: 830px;
    width: 490px;

    @media screen and (max-width: 767px) {
      width: 100%;
      height: auto;
      margin-bottom: 50px;
    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 38px;
    font-weight: 700;
    background-color: var(--black);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .swiper-wrapper {
    transition-timing-function: linear !important;
  }
}
.slider{
    pointer-events: none;
}
</style>
