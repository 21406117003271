<template>
  <div class="info wrap"  v-if="infoShow === true">
    <div class="info-cover">
    <div class="info-box">
      <p class="title">{{ title }}</p>
      <div v-html="message"></div>
      <!-- <div>
        <router-link to="/osechi"  class="osechi-button"> 
      2024年おせち料理の<br class="sp-only">ご案内はこちらから</router-link>
    </div> -->
   
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "info",
  components: {},
  data() {
    return {
      title: "",
      message: "",
      infoShow: true,
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  created() {
    // var def = this.abc(1);
    // alert(def);
    this.$axios
      .get(`${this.rootUrl}/api/get-post.php`)
      .then((response) => {
        console.log(response);
        // alert(response.data.status);
        if (response.data.status === 200) {
          // alert(response.data.message);
          // console.log('ok')
          this.title = response.data.title;
          this.message = response.data.message;
          if (response.data.visible === "1") {
            this.infoShow = true;
          } else if (response.data.visible === "0") {
            this.infoShow = false;
          } else {
            alert("エラーが発生しました。");
          }
          // this.pageType = 1;
          return;
        } else if (
          response.data.status === 401 ||
          response.data.status === 400
        ) {
          alert(response.data.message);
          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.info {
  margin-top: 30px;
  margin-bottom: 30px;
  }
.info-cover {
  background-color: black;
}
.info-box {
  // border-top: solid 2px var(--white);
  // border-bottom: solid 2px var(--white);
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white!important;
  a{
    text-decoration: none;
    color: white!important;
    &:hover{
      opacity: 0.8;
    }
  }
    @media screen and (max-width: 767px){
      text-align: left;
    }


  .title {
    padding: 10px 0 20px 0;
    font-size: 25px;
     @media screen and (max-width: 767px){
       font-size: 23px;
     }
  }
}
.osechi-button{
margin: 20px 0;
// background-color:#3c3c3c;
border-top: #9a8338 2px solid;
border-bottom: #9a8338 2px solid;
padding: 5px 30px;
display: block;
text-decoration: none;
color: white;
transition: all .2s;
font-size: 17px;
@media screen and (max-width: 767px) {
  // margin-top: 50px;
}

&:hover{
background-color: #e6e6e6;
  color: black;
  opacity: 0.8;
  font-weight: bold;

}
}
</style>
