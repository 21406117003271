<template>
  <div class="recruit-deshi">
    <RecuruitDeshiTop class="top-space" />

    <RecruitDeshiAbout />

    <RecruitDeshiJapanese />

    <RecruitDeshiHappy class="mb-200 wrap" />

    <RecruitNewHappy :barDisplay="false" :configList="configList" />

    <RecruitDeshiMessage />

    <RecruitDeshiTenYear class="mb-200 wrap" />

    <RecruitDeshiInfo class="mb-200 wrap" />

    <RecruitNewADay :configList="configList2" />

    <RecruitNewFlow />

    <RecruitDeshiQa />

    <div class="wrap">
      <RecuruitNewContactForm />
    </div>

    <div class="wrap">
      <p class="company-title">会社情報</p>
      <UiTable :tableList="tableList" class="company" />
    </div>
  </div>
</template>

<script>
import RecuruitDeshiTop from "@/components/RecruitDeshi/Top.vue";
import RecruitDeshiAbout from "@/components/RecruitDeshi/About.vue";
import RecruitDeshiJapanese from "@/components/RecruitDeshi/Japanese.vue";
import RecuruitNewContactForm from "@/components/RecruitNew/ContactForm.vue";
import RecruitDeshiHappy from "@/components/RecruitDeshi/Happy.vue";
import RecruitNewHappy from "@/components/RecruitNew/Happy.vue";
import RecruitDeshiMessage from "@/components/RecruitDeshi/Message.vue";
import RecruitDeshiTenYear from "@/components/RecruitDeshi/TenYear.vue";
import RecruitDeshiInfo from "@/components/RecruitDeshi/Info.vue";
import RecruitDeshiQa from "@/components/RecruitDeshi/Qa.vue";
import RecruitNewADay from "@/components/RecruitNew/ADay.vue";
import RecruitNewFlow from "@/components/RecruitNew/Flow.vue";
import UiTable from "@/components/Ui/Table.vue";
// @ is an alias to /src

export default {
  name: "recruit",
  components: {
    RecuruitDeshiTop,
    RecruitDeshiAbout,
    RecruitDeshiJapanese,
    RecuruitNewContactForm,
    RecruitDeshiHappy,
    RecruitNewHappy,
    RecruitDeshiMessage,
    RecruitDeshiTenYear,
    RecruitDeshiInfo,
    RecruitDeshiQa,
    RecruitNewADay,
    RecruitNewFlow,
    UiTable,
  },
  data() {
    return {
      tableList: [
        {
          id: 1,
          th: "会社名",
          td: "株式会社 匠味 平野",
        },
        {
          id: 2,
          th: "代表者名",
          td: "平野 功治",
        },
        {
          id: 3,
          th: "住所",
          td: "岐阜市御浪町10番地(御浪ビル１F)",
        },
        {
          id: 4,
          th: "電話<br class=sp-only>番号",
          td: "058-263-8644",
        },
      ],
      configList: [
        {
          matchMedia: "(min-width: 768px)",
          displayDefinitionList: [
            {
              offset: 4200,
              display: "display01",
            },
          ],
        },
        {
          matchMedia: "(max-width: 768px)",
          displayDefinitionList: [
            {
              offset: 6000,
              display: "display01",
            },
          ],
        },
      ],
      configList2: [
        {
          matchMedia: "(min-width: 768px)",
          displayDefinitionList: [
            {
              offset: 9340,
              display: "display01",
            },
          ],
        },
        {
          matchMedia: "(max-width: 768px)",
          displayDefinitionList: [
            {
              offset: 14981,
              display: "display01",
            },
          ],
        },
      ],
      displayData: {
        display01: false,
        // display02: false,
      },
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-space {
  padding-bottom: 100px;
  @media screen and (max-width: 767px) {
    padding-bottom: 50px;
  }
}
.recruit-deshi {
  background-color: rgb(241, 235, 226);
  color: var(--black);
  text-align: center;
}
.company-title {
  font-size: 20px;
  margin-bottom: 20px;
}
.company {
  width: 70%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  padding: 0 0 100px;
  margin: 0 auto;
  ::v-deepth {
    @media screen and (max-width: 767px) {
      width: 20%;
    }
  }
  ::v-deeptd{
    background-color: white;
  }
}
</style>
