<template>
  <div id="app">
    <!-- :class="{ backimg01: backimg === 0, backimg02: backimg === 1 }" -->
    <Header class="header" :path="path" />
    <router-view :class="{ 'body-top': path !== '/' && path !== '/recruit-new' && path !== '/recruit-deshi' }" />

    <ToTopButton :path="path" />
    <Footer
      :class="{
        ctrl:
          path === '/ctrl' || path === '/ctrl/login' || path === '/ctrl/info',
      }"
      :path="path"
    />
  </div>
</template>

<script>
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
// import $ from "jquery";

export default {
  name: "App",
  data() {
    return {
      backimg: 0,
      backscrollTimer: null,
      backscrollY: 0,
      idOffset: 0,
      wh: 0,
      scrollTimer: null,
    };
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    path() {
      return this.$route.path;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        // console.log(document.body.clientHeight);
        this.documentheight = document.body.clientHeight;
        this.scrollTimer = setTimeout(
          function () {
            // this.scrollY = window.scrollY;
            this.$store.dispatch("setScrollY", window.scrollY);
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            console.log("store scrollY: " + this.$store.getters.scrollY);
          }.bind(this),
          200
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP&display=swap");
#app {
  font-family: "游明朝", "Yu Mincho", "ヒラギノ明朝 Pro W3",
    "Hiragino Mincho Pro", "游明朝体", "YuMincho", "ＭＳ Ｐ明朝", "MS PMincho",
    "Noto Serif JP", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 500;
}

[v-cloak] {
  display: none;
}

html,
body {
  margin: 0;
  // background-color: rgb(0, 0, 0);
  background-blend-mode: darken;
  color: white;
  z-index: -2;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  background: url(https://takumi-hirano.com/img/takumi-hirano_image005.jpg)
    center no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.85);
  background-blend-mode: darken;
  @media screen and (max-width: 767px) {
    background-position: 6% 30%;
    padding-bottom: 150px;
  }
}

html * {
  box-sizing: border-box;
}

:root {
  --black: rgb(0, 0, 0);
  --white: rgb(255, 255, 255);
  --gold: rgb(154, 131, 56);
  --cream:rgb(222, 208, 186);
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

h1,
h2 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: bold;
}

.wrap,
.wrap1 {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2em;
}
.wrap {
  @media screen and (max-width: 1150px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.sp-kaigyo,
.sp-only {
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
  }
}

.pc-kaigyo,
.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.body-top {
  padding-top: 100px;
}

.cloud {
  width: 120px;
  margin-bottom: 20px;
  margin-left: 40px;
}

.ctrl {
  display: none;
}
// ふわっとアニメーション
.base {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
}
.deco {
  opacity: 0;
}
.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
    // transform: translateY(100px);
  }

  to {
    opacity: 1;
    //  transform: translateY(0);
  }
}
//ふわっとでてくるだけ
.fadeIn2 {
  animation-name: fadeInAnime;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  opacity: 0;

}

@keyframes fadeInAnime {
  from {
    opacity: 0;
    // transform: translateY(100px);
  }

  to {
    opacity: 1;
    //  transform: translateY(0);
  }
}
.mb-200 {
  margin-bottom: 200px;
  @media screen and (max-width: 767px) {
    margin-bottom: 70px;
  }
}

</style>
