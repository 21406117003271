<template>
  <div class="InfoAccess">
    <div class="top">
      <h2>店主</h2>
      <img :src="`${rootUrl}/img/takumi-hirano_pa004.png`" alt="雲" />
    </div>
    <div class="introduction">
      <img :src="`${rootUrl}/img/takumi-hirano_00.jpg`" alt="雲" />
      <div class="text-box">
        <div class="name-cover">
        <div class="t-name">
          <div class="t-name-title">
           <img :src="`${rootUrl}/img/takumi-hirano_pa001.png`" alt="丸"/><span class="position">店主</span></div>
          <p>平野 功治</p>
          <span class="en">Hirano Koji</span>
        </div></div>
        <div class="t-sentence">
          岐阜県出身。<br />
          1988年に料理の道へ。<br>日本料理店で修業を重ねた後、2002年に「匠味
          平野」を開業。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Introduction",
  components: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.top {
  text-align: center;
  img {
    width: 12%;
    @media screen and (max-width: 767px) {
      width: 25%;
    }
  }
}

.introduction {
  display: flex;
  justify-content:space-between;
  margin-top: 80px;
  margin-bottom: 100px;
  height: auto;
// align-items: flex-start;
  @media screen and (max-width: 767px){
    display: block;
  }
  img {
    width: 48%;
    margin-right: 20px;
    align-self: flex-start;
    @media screen and (max-width: 767px){
      width: 100%;
    }
  }
  .text-box{
    background-color: black;
    padding: 15px 20px;
    width: 48%;
    @media screen and (max-width: 767px){
      width: 100%;
    }
    .name-cover{
      display: flex;
      justify-content: flex-end;
      padding: 20px;
      @media screen and (max-width: 767px){
        height: 280px;
      }


      .t-name{
        writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      display: flex;
    flex-direction: column;
 
     span{
        color: rgb(171, 141, 63);
  }
  .t-name-title{
    display: flex;
    img{
      width: 30px;
      height: 30px;
      margin: 0;
      margin-bottom: 10px;
      // vertical-align: middle;
    }
    .position{
      font-size: 20px;
      letter-spacing: 0.5em;
      margin-left:10px;
      // vertical-align: middle;
    }
  }
  .en{
    letter-spacing: 0.2em;
    margin: 80px 10px 0 0;

  }
  p{
    font-size: 32px;
    letter-spacing: 0.4em;
    padding:10px 0;
  }
    }
    }
    }
    .t-sentence{
      margin-top: 30px;
    }
}
</style>
