<template>
  <div class="ctrl-info">

    <div class="wrap">
        <CtrlInfo />
    </div>



  </div>
</template>
<script>
import CtrlInfo from "@/components/Ctrl/Info.vue";
export default {
  name: 'ctrl-info',
  components: {
    CtrlInfo,
  },
data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
