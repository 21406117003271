<template>
  <div class="recruit-new-list">
    <div class="black-bar">
      <h3>募集中の職種</h3>
    </div>
    <div class="block-cover">
      <p>料理人</p>
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa007.png`"
        alt="ゴールドパターン"
        class="pc-only"
      />
      <p>調理補助</p>
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa007.png`"
        alt="ゴールドパターン"
        class="pc-only"
      />
      <p>ホールスタッフ</p>
    </div>
    <router-link
      to="/recruit-deshi"
      target="_blank"
      rel="noopener noreferrer"
      class="mb150"
    >
      <div class="button-cover">
        <img
          :src="`${rootUrl}/img/kyujin/th_deshi20231024-ba001.webp`"
          alt="弟子募集"
          class="banner pc-only"
        />
        <img
          :src="`${rootUrl}/img/kyujin/th_deshi20231024-ba001sp.webp`"
          alt="弟子募集"
          class="banner sp-only"
        />
        <p>詳細はこちらから</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "recruit-learn",
  components: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-new-list {
  .black-bar {
    background-color: var(--black);
    color: white;
    font-size: 22px;
    padding: 15px 0;
  }
  .block-cover {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      margin-top: 30px;
      flex-direction: column;
    }
    font-size: 28px;
    p {
      @media screen and (max-width: 767px) {
        margin-bottom: 26px;
      }
    }
    img {
      width: 30px;
      margin: 0 50px;
    }
  }
  a {
    text-decoration: none;
    color: black;
    transition: 3s;
    // width: 40%;
    display: inline-block;
    margin-top: 80px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 0;
    }
    .button-cover {
      .banner {
        background-color: white;
        transition: 1s;
        border: solid 1px var(--black);
        animation: poyopoyo 4s ease-out infinite;
        opacity: 1;

        @keyframes poyopoyo {
          0%,
          40%,
          60%,
          80% {
            transform: scale(1);
          }
          50%,
          70% {
            transform: scale(1.05);
          }
        }
        transition: 1s;
      }
      .syousai {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 20px;
          @media screen and (max-width: 1024px) {
            line-height: 1.3em;
            font-size: 16px;
          }
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
