<template>
  <div class="recruit-new-info">
    <div class="title">
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa011-1.png`"
        alt="デコ１"
        class="hana"
        :class="{ fadeIn2: displayData.display01 === true }"
      />
      <h2>求人詳細情報</h2>
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa011-2.png`"
        alt="デコ２"
        class="hana"
        :class="{ fadeIn2: displayData.display01 === true }"
      />
    </div>
    <!-- 料理人 -->
    <div class="gold-bar">
      <h3>料理人</h3>
    </div>
    <div class="top">
      <p class="stuff">
        <span> ＜正社員＞</span
        ><br />本気で一流料理人を志すスタッフが活躍中です。
      </p>
    </div>

    <div>
      <div class="kakko-cover">
        <img
          :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa014-2.png`"
          alt="［"
          class="kakko sp-only"
        />
        <p class="point">
          <span class="pc-only">［</span>料理人のおすすめポイント<span
            class="pc-only"
            >］</span
          >
        </p>
        <img
          :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa014-1.png`"
          alt="［"
          class="kakko sp-only"
        />
      </div>

      <div class="list">
        <img
          :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa010-1.png`"
          alt="1個目"
          class="number"
        />
        <div class="text">
          <p class="sub-title">
            本気で日本料理を覚えたい人必見！大将の横で直接学べる
          </p>
          <p>
            個人店だからこそ直接近くで学べます。とても刺激的で成長ができる環境になっています。
          </p>
        </div>
      </div>

      <div class="list">
        <img
          :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa010-2.png`"
          alt="2個目"
          class="number"
        />
        <div class="text">
          <p class="sub-title">京都などの本格日本料理の視察あり</p>
          <p>
            年2回ほど大将がその時に気になるお店の視察に同行できます。<br />また、信州や伊勢志摩にいらっしゃる生産者様を訪問し、直接食材について学ぶ研修も行っています。
          </p>
        </div>
      </div>

      <div class="list">
        <img
          :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa010-3.png`"
          alt="3個目"
          class="number"
        />
        <div class="text">
          <p class="sub-title">独立をサポート</p>
          <p>
            あなたが理想とするビジョンを叶えられるように手厚くサポート＆応援します。
          </p>
        </div>
      </div>

      <div class="bottom-cover">
        <img
          :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa009.png`"
          alt="こんな方におすすめ"
          class="like"
        />
        <div>
          <ul>
            <li>料理人としての独立願望がある人</li>
            <li>一生やっていける確固たる手に職をつけたい人</li>
            <li>日本料理の道を極めたい人</li>
          </ul>
        </div>
        <img
          :src="`${rootUrl}/img/kyujin/th_kyujin202305-007.jpg`"
          alt="手巻き風景"
          class="temaki"
        />
      </div>

      <router-link
        to="/recruit-deshi"
        target="_blank"
        rel="noopener noreferrer"
        class="mb150"
      >
      <div class="button-cover">
        <img
          :src="`${rootUrl}/img/kyujin/th_deshi20231024-ba001.webp`"
          alt="弟子募集"
          class="banner pc-only"
        />
        <img
          :src="`${rootUrl}/img/kyujin/th_deshi20231024-ba001sp.webp`"
          alt="弟子募集"
          class="banner sp-only"
        />
        <p>詳細はこちらから</p>
      </div>
      </router-link>
      <!-- 調理補助 -->
      <div class="gold-bar">
        <h3>調理補助（昼・夜）</h3>
      </div>
      <div class="top">
        <p class="stuff">
          <span> ＜パート・アルバイト＞</span><br />
          スタッフは調理学校生を含め、料理が好きな男女10代～50代が現在活躍中です。転勤なしで腰を据えて働けます。
        </p>
        <div class="option">
          <p class="surround">Wワーク可能</p>
          <p class="surround">時給・待遇良</p>
          <p class="surround">学生歓迎</p>
          <p class="surround">未経験歓迎</p>
          <p class="surround">主婦（夫）歓迎</p>
          <p class="surround">交通費支給</p>
        </div>
      </div>

      <div>
        <div class="kakko-cover">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa014-2.png`"
            alt="［"
            class="kakko sp-only"
          />
          <p class="point">
            <span class="pc-only">［</span>調理補助（昼・夜）の<br
              class="sp-only"
            />おすすめポイント<span class="pc-only">］</span>
          </p>
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa014-1.png`"
            alt="［"
            class="kakko sp-only"
          />
        </div>

        <div class="list">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa010-1.png`"
            alt="1個目"
            class="number"
          />
          <div class="text">
            <p class="sub-title">
              事前申請で最長1週間のお休みが取れます
            </p>
            <p>
              旅行、お子様の行事などに合わせて事前に申請すれば、最長1週間のお休みをとることができます。
            </p>
          </div>
        </div>

        <div class="list">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa010-2.png`"
            alt="2個目"
            class="number"
          />
          <div class="text">
            <p class="sub-title">
              旬の食材（下ごしらえの仕方）・器に詳しくなれる
            </p>
            <p>
              昼は野菜のカットや炊飯など仕込みがメインです。慣れてきたら、夜は盛り付けもお願いします。
            </p>
          </div>
        </div>

        <div class="list">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa010-3.png`"
            alt="3個目"
            class="number"
          />
          <div class="text">
            <p class="sub-title">未経験OK。丁寧にイチから教えます</p>
            <p>
              料理するのが好き、和食が好き、黙々と作業するのが好き、最初はそれだけで大丈夫です。
            </p>
          </div>
        </div>

        <div class="bottom-cover">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa009.png`"
            alt="こんな方におすすめ"
            class="like"
          />
          <div>
            <ul>
              <li>料理が好きな主婦（夫）の方</li>
              <li>お昼だけ、夜だけの時間を有効に使いたい方</li>
              <li>黙々と作業するのが好き、細かい作業ができる方</li>
              <li>高時給で働きたい方</li>
              <li>料理人に興味があり、手に職をつけたい方</li>
            </ul>
          </div>
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-009.jpg`"
            alt="調理補助"
            class="temaki"
          />
        </div>

        <UiTable :tableList="tableList1" class="mt50 mb150" />
      </div>

      <!-- ホールスタッフ -->
      <div class="gold-bar">
        <h3>ホールスタッフ（夜）</h3>
      </div>
      <div class="top">
        <p class="stuff">
          <span> ＜パート・アルバイト＞</span><br />
          スタッフは大学生を含め、20代～50代の女性が現在活躍中です。
        </p>
        <div class="option">
          <p class="surround">Wワーク可能</p>
          <p class="surround">時給・待遇良</p>
          <p class="surround">学生歓迎</p>
          <p class="surround">未経験歓迎</p>
          <p class="surround">主婦（夫）歓迎</p>
          <p class="surround">交通費支給</p>
        </div>
      </div>

      <div>
        <div class="kakko-cover">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa014-2.png`"
            alt="［"
            class="kakko sp-only"
          />
          <p class="point">
            <span class="pc-only">［</span>ホールスタッフ（夜）の<br
              class="sp-only"
            />おすすめポイント<span class="pc-only">］</span>
          </p>
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa014-1.png`"
            alt="［"
            class="kakko sp-only"
          />
        </div>
        <div class="list">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa010-1.png`"
            alt="1個目"
            class="number"
          />
          <div class="text">
            <p class="sub-title">
              制服は着物と二部式着物から選択できます
            </p>
            <p>
              この機会に着付けも同時に指導することも可能です。簡易的に制服を着たい方には二部式着物がおすすめです。
            </p>
          </div>
        </div>

        <div class="list">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa010-2.png`"
            alt="2個目"
            class="number"
          />
          <div class="text">
            <p class="sub-title">
              未経験歓迎です。丁寧にイチから教えます
            </p>
            <p>
              接客マナー、コース料理のマナーも基礎から学べるため安心して働けます。
            </p>
          </div>
        </div>

        <div class="list">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa010-3.png`"
            alt="3個目"
            class="number"
          />
          <div class="text">
            <p class="sub-title">事前申請で最長1週間のお休みが取れます</p>
            <p>
              ご家庭のご都合、テスト前・実習期間など学校の行事予定に合わせてシフトを調整することが可能です。
            </p>
          </div>
        </div>

        <div class="bottom-cover">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa009.png`"
            alt="こんな方におすすめ"
            class="like"
          />
          <div>
            <ul>
              <li>人と接することが好きな方</li>
              <li>夜の時間を有効に使いたい方</li>
              <li>しっかりとした接客を学びたい方</li>
              <li>高時給で働きたい方</li>
            </ul>
          </div>
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-010.jpg`"
            alt="ホールスタッフ"
            class="temaki"
          />
        </div>

        <UiTable :tableList="tableList2" class="mt50 mb200" />
      </div>
    </div>
  </div>
</template>

<script>
import UiTable from "../Ui/Table.vue";
import { mapGetters } from "vuex";
export default {
  name: "recruit-learn",
  components: { UiTable },
  data() {
    return {
      // scrollY: 0,
      // scrollTimer: null,
        displayData: {
        display01: false,
      },
      tableList1: [
        {
          id: 1,
          th: "職種",
          td: "調理補助（昼・夜）",
        },
        {
          id: 2,
          th: "雇用<br class=sp-only>形態",
          td: "パート・アルバイト",
        },
        {
          id: 3,
          th: "仕事<br class=sp-only>内容",
          td:
            "昼）主な仕事は開店前のお料理の仕込みをお願いします。野菜を切って並べたり、ご飯を炊いたりする調理の補助のお仕事です。<br>夜）営業時間内は主に、調理器具の用意・洗い物、器の準備などをお願いします。慣れてきたら料理のお盛り付けもおまかせすることもあります。",
        },
        {
          id: 4,
          th: "応募<br class=sp-only>条件",
          td:
            "学歴・経歴不問、未経験者歓迎<br>料理が好きな方、お待ちしています！",
        },
        {
          id: 5,
          th: "給与",
          td:
            "昼）時給1,000円～【研修期間なし】<br>夜）時給1,200円～【試用期間50時間（時給1,100円）】",
        },
        {
          id: 6,
          th: "通勤<br class=sp-only>手当",
          td:
            "規定によって支給<br>※バイク・徒歩・車通勤可能です。無料駐車場完備しています。",
        },
        {
          id: 7,
          th: "勤務地",
          td:
            "岐阜県岐阜市御浪町10番地御浪ビル1Ｆ<br>※名鉄岐阜駅から車で5分ほどです。",
        },
        {
          id: 8,
          th: "勤務<br class=sp-only>時間",
          td:
            "10：00～22：00の間で1日3時間から週2日から勤務可能です。<br>昼から夜の通しで働ける方も大歓迎です。",
        },
        {
          id: 9,
          th: "福利<br class=sp-only>厚生",
          td:
            "昇給あり、美味しいまかないあり<br>交通費規定支給、制服貸与、社会保険完備、社員登用有",
        },
      ],
      tableList2: [
        {
          id: 1,
          th: "職種",
          td: "ホールスタッフ（夕方～夜）",
        },
        {
          id: 2,
          th: "雇用<br class=sp-only>形態",
          td: "パート・アルバイト",
        },
        {
          id: 3,
          th: "仕事<br class=sp-only>内容",
          td:
            "主に、お客様のご案内と靴や上着の整頓、オーダー取り、お飲み物やお料理の配膳、説明をお願いします。多忙なときは、周りのスタッフのお手伝いをお願いすることもあります。",
        },
        {
          id: 4,
          th: "応募<br class=sp-only>条件",
          td:
            "学歴・経歴不問、未経験者歓迎です。<br>現在20代～50代の女性が活躍中です！Wワークも可能です。",
        },
        {
          id: 5,
          th: "給与",
          td:
            "時給1,100円～<br class=sp-only>【試用期間50時間（時給1,000円）】",
        },
        {
          id: 6,
          th: "通勤<br class=sp-only>手当",
          td:
            "規定によって支給<br>※バイク・徒歩・車通勤可能です。無料駐車場完備しています。",
        },
        {
          id: 7,
          th: "勤務地",
          td:
            "岐阜県岐阜市御浪町10番地御浪ビル1Ｆ<br>※名鉄岐阜駅から車で5分ほどです。",
        },
        {
          id: 8,
          th: "勤務<br class=sp-only>時間",
          td: "17：00～22：00の間で1日3時間から週2日から勤務可能です。",
        },
        {
          id: 9,
          th: "福利<br class=sp-only>厚生",
          td:
            "昇給あり、美味しいまかないあり<br>交通費規定支給、制服貸与、社会保険完備、社員登用有",
        },
      ],
            displayDefinitionList: [],
            configList: [
        {
          matchMedia: "(min-width: 768px)",
          displayDefinitionList: [
            {
              offset: 2984,
              display: "display01",
            },
          ],
        },
        {
          matchMedia: "(max-width: 768px)",
          displayDefinitionList: [
            {
              offset: 5300,
              display: "display01",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },

  watch: {
    // ４．あとは同じ
    scrollY(offset) {
      //
      let displayList = this.displayDefinitionList.filter(function (
        displayDefinitionData
      ) {
        return displayDefinitionData.offset <= offset;
      });

      //
      displayList.forEach((displayData) => {
        this.displayData[displayData.display] = true;
      });
    },
  },
    created() {
    // ２．configListから、画面幅に合致するものを取得
    let configList = this.configList.filter(function (configData) {
      return window.matchMedia(configData.matchMedia).matches;
    });

    // ３．dataのdisplayDefinitionListに、取得できたものを格納
    console.log("===== configList =====");
    console.log(configList);
    if (configList.length > 0) {
      this.displayDefinitionList = configList[0].displayDefinitionList;
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-new-info {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto 50px;
    @media screen and (max-width: 767px) {
      width: 80%;
    }
    .hana {
      width: 50px;
      opacity: 0;
      @media screen and (max-width: 767px) {
        width: 30px;
      }
    }
    h2 {
      font-size: 40px;
      margin-bottom: 0;
      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }
  }
  .gold-bar {
    background-color: var(--gold);
    color: white;
    font-size: 22px;
    padding: 15px 0;
  }
  .top {
    display: flex;
    align-items: flex-start;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 20px;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
      align-items: flex-start;
    }
    .stuff {
      text-align: left;
    }
    .option {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .surround {
        border: 1px var(--black) solid;
        padding: 0 20px;
        margin-left: 30px;
        @media screen and (max-width: 1100px) {
          margin-left: 0;
          margin-right: 10px;
          margin-top: 10px;
        }
      }
    }
  }
  .kakko-cover {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .kakko {
      width: 20px;
    }
  }
  .point {
    font-size: 25px;
    text-align: left;
    margin-bottom: 20px;
    margin-left: -0.5em;
    @media screen and (max-width: 767px) {
      text-align: center;
      font-size: 23px;
      margin-left: 0;
      margin-bottom: 0;
      color: var(--gold);
    }
  }
  .list {
    text-align: left;
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
    .number {
      width: 50px;
    }
    .text {
      margin-left: 20px;
      margin-top: 5px;
      @media screen and (max-width: 767px) {
        // margin-top: 0;
        margin-left: 10px;
        margin-top: 3px;
      }
      .sub-title {
        font-size: 25px;
        margin-left: -2px;
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
          font-size: 23px;
        }
        // margin-top: 5px;
      }
    }
  }
  .bottom-cover {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      align-items: flex-start;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
    .like {
      width: 200px;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 20%;
        margin-right: 40px;
      }
      @media screen and (max-width: 767px) {
        width: 50%;
      }
    }
    ul {
      list-style: disc;
      text-align: left;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        margin-right: 20px;
      }
      @media screen and (max-width: 767px) {
        margin-left: 30px;
        margin-top: 20px;
      }
      li {
        font-size: 25px;
        margin-bottom: 10px;
        @media screen and (max-width: 1100px) {
          font-size: 20px;
        }
      }
    }
    .temaki {
      width: 25%;
      @media screen and (max-width: 767px) {
        width: 70%;
        margin-top: 20px;
      }
    }
  }
  a {
    text-decoration: none;
    color: black;
    transition: 3s;
    // width: 40%;
    display: inline-block;
    margin-top: 100px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .button-cover {
      .banner {
        background-color: white;
        transition: 1s;
        border: solid 1px var(--black);
        animation: poyopoyo 4s ease-out infinite;
        opacity: 1;

        @keyframes poyopoyo {
          0%,
          40%,
          60%,
          80% {
            transform: scale(1);
          }
          50%,
          70% {
            transform: scale(1.05);
          }
        }
        transition: 1s;
      }
      .syousai {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 20px;
          @media screen and (max-width: 1024px) {
            line-height: 1.3em;
            font-size: 16px;
          }
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .mt50 {
    margin-top: 80px;
  }
  .mb150 {
    margin-bottom: 150px;
  }
  .mb200 {
    margin-bottom: 200px;
    @media screen and (max-width: 767px) {
      margin-bottom: 70px;
    }
  }
  .seisyain {
    width: 120px;
  }
}
</style>
