<template>
  <div
    class="header"
    :class="{ 'over-baseline': scrollY >= 600 || path !== '/' }"
  >
    <div class="top">
      <div class="logo">
        <div class="to-top" @click="top">
          <img
            :src="`${rootUrl}/img/takumi-hirano_logo02.png`"
            alt="匠味平野ロゴ"
            :class="{
              'over-baseline2':
                (scrollY >= 600 && documentheight - scrollY > 1100) ||
                (path !== '/' && documentheight - scrollY > 1100),
            }"
          />
        </div>
      </div>
        <!-- <div class="oubo">
          応募はこちらから
          ashitakokokara
        </div> -->
      <div class="hamburger">
        <UiDrawer />
      </div>
    </div>
  </div>
</template>

<script>
import UiDrawer from "@/components/Ui/Drawer.vue";
export default {
  name: "layout-header",
  components: {
    UiDrawer,
  },
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      documentheight: 0,
    };
  },

  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        // console.log(document.body.clientHeight);
        this.documentheight = document.body.clientHeight;
        this.scrollTimer = setTimeout(
          function () {
            this.scrollY = window.scrollY;
            // console.log(this.documentheight - this.scrollY);
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            // console.log(this.scrollY);
          }.bind(this),
          200
        );
      }
    },
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  z-index: 9999;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.8s ease;
}
.over-baseline {
  display: initial;
  // background-color: rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 767px) {
    background-color: initial;
  }
}

.top {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: white;
  padding-top: 10px;

  @media screen and (max-width: 1100px) {
    height: 70px;
  }

  .logo {
    width: 80px;
    margin: 5px;
    margin-left: 30px;
    overflow: visible;

    img {
      width: 80%;
      display: none;
      margin-top: 20px;
    }

    .to-top {
      cursor: pointer;
    }
    .over-baseline2 {
      display: initial;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}

.to-top:hover {
  opacity: 0.8;
}

.hamburger {
  margin: 20px 40px;

  @media screen and (max-width: 767px) {
    margin: 10px 20px;
  }

  img {
    width: 38%;

    @media screen and (max-width: 767px) {
      width: 15%;
    }
  }
}
</style>
