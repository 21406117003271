<template>
  <div class="ctrl-img">
    <h1>画像・動画データ一覧</h1>
    <p>画像を右クリック⇒『新しいタブで開く』で大きく表示できます。<br>また、画像を右クリック⇒『名前を付けて画像を保存』で保存もできます。</p>
    <div class="bord">


<el-row :gutter="10" justify="space-between">
  
  <el-col :span="4" v-for="image in images" :key="image.id">
     <p>{{image.id}}</p>
     <img
          :src="`${rootUrl}/img/${image.imgpath}`"
          :alt="image.name"
        />
        
  </el-col>
</el-row>
    </div>
  </div>

</template>

<script>
export default {
  name: "cotrl-img",
  components: {},
  props:{},
  data() {
    return {
images:[
  {id:1,imgpath:"211013-fall/(37).jpg",name:"蕎麦"},
  {id:2,imgpath:"211013-fall/(38).jpg",name:"蕎麦"},
  {id:3,imgpath:"211013-fall/(39).jpg",name:"蕎麦"},
  {id:4,imgpath:"211013-fall/(40).jpg",name:"蕎麦"},
  {id:5,imgpath:"211013-fall/(41).jpg",name:"蕎麦"},
  {id:6,imgpath:"211013-fall/(42).jpg",name:"蕎麦"},
  {id:7,imgpath:"211013-fall/(43).jpg",name:"蕎麦"},
  {id:8,imgpath:"211013-fall/(44).jpg",name:"蕎麦"},
  {id:9,imgpath:"211013-fall/(45).jpg",name:"蕎麦"},
  {id:10,imgpath:"211013-fall/(46).jpg",name:"蕎麦"},
  {id:11,imgpath:"211013-fall/(47).jpg",name:"蕎麦"},
  {id:12,imgpath:"211013-fall/(48).jpg",name:"蕎麦"},
  {id:13,imgpath:"211013-fall/(49).jpg",name:"蕎麦"},
  {id:14,imgpath:"211013-fall/(50).jpg",name:"蕎麦"},
  {id:15,imgpath:"211013-fall/(51).jpg",name:"蕎麦"},
  {id:16,imgpath:"211013-fall/(52).jpg",name:"蕎麦"},
  {id:17,imgpath:"211013-fall/(53).jpg",name:"蕎麦"},
  {id:18,imgpath:"211013-fall/(54).jpg",name:"蕎麦"},
  {id:19,imgpath:"211013-fall/(55).jpg",name:"蕎麦"},
  {id:20,imgpath:"211013-fall/(56).jpg",name:"蕎麦"},
  {id:21,imgpath:"211013-fall/(57).jpg",name:"蕎麦"},
  {id:22,imgpath:"211013-fall/(58).jpg",name:"蕎麦"},
  {id:23,imgpath:"211013-fall/(59).jpg",name:"蕎麦"},
  {id:24,imgpath:"211013-fall/(60).jpg",name:"蕎麦"},
  {id:25,imgpath:"211013-fall/(61).jpg",name:"蕎麦"},
  {id:26,imgpath:"211013-fall/(62).jpg",name:"蕎麦"},
  {id:27,imgpath:"211013-fall/(63).jpg",name:"蕎麦"},
  {id:28,imgpath:"211013-fall/(64).jpg",name:"蕎麦"},
  {id:29,imgpath:"211013-fall/(65).jpg",name:"蕎麦"},
  {id:30,imgpath:"211013-fall/(66).jpg",name:"蕎麦"},
  {id:31,imgpath:"211013-fall/(67).jpg",name:"蕎麦"},
  {id:32,imgpath:"211013-fall/(68).jpg",name:"蕎麦"},
  {id:33,imgpath:"211013-fall/(69).jpg",name:"蕎麦"},
  {id:34,imgpath:"211013-fall/(70).jpg",name:"蕎麦"},
  {id:35,imgpath:"211013-fall/(71).jpg",name:"蕎麦"},
  {id:36,imgpath:"211013-fall/(72).jpg",name:"蕎麦"},
  {id:37,imgpath:"211013-fall/(73).jpg",name:"蕎麦"},
  {id:38,imgpath:"211013-fall/(74).jpg",name:"蕎麦"},
  {id:39,imgpath:"211013-fall/(75).jpg",name:"蕎麦"},
  {id:40,imgpath:"211013-fall/(76).jpg",name:"蕎麦"},
  {id:41,imgpath:"211013-fall/(77).jpg",name:"蕎麦"},
  {id:42,imgpath:"211013-fall/(78).jpg",name:"蕎麦"},
  {id:43,imgpath:"211013-fall/(79).jpg",name:"蕎麦"},
  {id:44,imgpath:"211013-fall/(80).jpg",name:"蕎麦"},
  {id:45,imgpath:"211013-fall/(81).jpg",name:"蕎麦"},
  {id:46,imgpath:"211013-fall/(82).jpg",name:"蕎麦"},
  {id:47,imgpath:"211013-fall/(83).jpg",name:"蕎麦"},
  {id:48,imgpath:"211013-fall/(84).jpg",name:"蕎麦"},
],
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ctrl-img {
  background-color: #f4f4ef;
  color: black;
  h1 {
    padding-top: 30px;
  }
  p{
    text-align: center;
  }

  .bord{
    padding: 20px;
    margin-bottom: 60px;
  }
  .el-col{
    text-align: center;
    img{
      width: 100%;
    }
  }
}

</style>
