<template>
  <div class="recruit-new-happy">
    <div class="black-bar" v-if="barDisplay === true">
      <div class="wrap">
        <div class="block">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa003.png`"
            alt="ゴールドパターン左"
            class="left"
          />
          <p>
            匠味 平野ならではの魅力的な求人になっています。<br />ご興味のある方は、ぜひ最後まで読んでみてください。
          </p>
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa004.png`"
            alt="ゴールドパターン右"
            class="right"
          />
        </div>
      </div>
    </div>
    <div class="beige">
      <div class="wrap">
        <div class="happy-cover">
          <div class="img-cover">
            <img
              :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa005-1.png`"
              alt="ちょっと嬉しいこと"
              class="base"
            />
            <img
              :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa005-2.png`"
              alt="ちょっと嬉しいこと2"
              class="deco"
              :class="{ fadeIn: displayData.display01 === true }"
            />
          </div>
          <div class="list-cover">
            <div class="list">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa008-1.png`"
                alt="パターン1"
              />
              <div class="text">
                <h3>昼・夜まかない付</h3>
                <p>
                  昼食（14時）・夜食（22時頃）に働いている全員にまかないがつきます。メニューはその日のお楽しみです。高級食材がまかないで味わえることも！夜食についてはお弁当にして持ち帰ることもできます。
                </p>
              </div>
            </div>

            <div class="list">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa008-2.png`"
                alt="パターン2
                "
              />
              <div class="text">
                <h3>
                  飲食店だけど日曜は毎週お休み<span
                    >（日曜、第一・第三月曜定休日）</span
                  >
                </h3>
                <p>
                  日曜日は毎週お休みなので、家庭と両立されたい方や、休日は友達とプライベートを満喫したいという方も働きやすいお店になっています。第一週と第三週は日曜・月曜の2連休です。
                </p>
              </div>
            </div>

            <div class="list">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa008-3.png`"
                alt="パターン3"
              />
              <div class="text">
                <h3>トップレベルのおもてなしの心と接客マナーが学べる</h3>
                <p>
                  お客様は、本物嗜好で感度の高い方々ばかりです。そんなお客様の中でハイレベルなおもてなしが学べます。自然と相手を気遣えるような所作、立ち振る舞い、言葉遣いなども身につきます。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "recruit-new-happy",
  components: {},
    props: {
    barDisplay:Boolean,
    // displayDefinitionList: Array, // ←２．ここで渡す
    configList: Array, // ←２．ここで渡す
  },
  data() {
    return {
      displayDefinitionList:[],
       displayData: {
        display01: false,
        // display02: false,
      },

    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
  watch: {
    // ４．あとは同じ
    scrollY(offset) {
      //
      let displayList = this.displayDefinitionList.filter(function (
        displayDefinitionData
      ) {
        return displayDefinitionData.offset <= offset;
      });

      //
      displayList.forEach((displayData) => {
        this.displayData[displayData.display] = true;
      });
    },
  },
    created() {
    // ２．configListから、画面幅に合致するものを取得
    let configList = this.configList.filter(function (configData) {
      return window.matchMedia(configData.matchMedia).matches;
    });

    // ３．dataのdisplayDefinitionListに、取得できたものを格納
    console.log("===== configList =====");
    console.log(configList);
    if (configList.length > 0) {
      this.displayDefinitionList = configList[0].displayDefinitionList;
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-new-happy {
  .black-bar {
    background-color: var(--black);
    color: white;
    font-size: 22px;
    padding: 50px 0;
    .block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 30px;

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        flex-direction: column;
        font-size: 18px;
      }
      img {
        width: 70px;
      }
      .right {
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          margin-top: auto;
        }
        @media screen and (max-width: 767px) {
          margin-left: auto;
        }
      }
      .left {
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          margin-bottom: auto;
        }
        @media screen and (max-width: 767px) {
          margin-right: auto;
        }
      }
      p {
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          font-size: 22px;
        }
        @media screen and (max-width: 767px) {
          width: calc(100% - 100px);
        }
      }
    }
  }
  .beige {
    background-color: var(--cream);
    padding-bottom: 200px;
    @media screen and (max-width: 767px) {
      padding-bottom: 70px;
    }
    .happy-cover {
      padding-top: 200px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media screen and (max-width: 767px) {
        padding-top: 70px;
        display: block;
      }
      .img-cover {
        width: 28%;
        position: relative;
        @media screen and (max-width: 767px) {
          width: 80%;
          margin: 0 auto;
        }
      }
      .base {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        @media screen and (max-width: 767px) {
          position: relative;
        }
      }

      .list-cover {
        width: 68%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        img {
          width: 80px;
        }
        .list {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 30px;
          @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
          }
          .text {
            text-align: left;
            width: calc(100% - 100px);
            @media screen and (max-width: 767px) {
              width: 100%;
              // text-align: center;
            }
          }
          h3 {
            font-size: 25px;
            margin: 10px 0;
            @media screen and (max-width: 767px) {
              text-align: center;
              margin: 20px 0;
            }
            span {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
</style>
