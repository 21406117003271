<template>
  <div class="top-button" >
    <div class="scroll-button"
    :class="{'over-baseline': scrollY < 200 && path ===`/`}">
      <div class="scroll">
      <span>scroll</span>
      </div>
    </div>
    <div
      class="to-top-button"
      :class="{'over-baseline': scrollY >= 500 }"
      v-scroll-to="{
        el: '#app',
        container: 'body',
        duration: 750,
        easing: 'ease',
        offset: 0,
      }"
    >
      <div class="top">
        <span class="txt">Top</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiToTopButton",
  props: {
    path:String
  },
  data() {
    return {
            scrollY: 0,
      scrollTimer: null,
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rt;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {//スクロールすると実行される
      if (this.scrollTimer === null) {//初期値はnull
        this.scrollTimer = setTimeout(//ここでnullじゃなくてsetTimeoutになる
          function () {//実行するセット
            this.scrollY = window.scrollY;//ウィンドウトップからの画面距離
            clearTimeout(this.scrollTimer);//scrollTimerをリセット
            this.scrollTimer = null;//初期値に戻す
          }.bind(this),//第一引数
          200//第二引数(ミリ秒後)←0.2秒後にはじまるっていう意味
        );
      }
    },
    
  },
};
</script>

<style lang="scss" scoped>
.to-top-button,
.scroll-button {
  display: none;
  position: fixed;
  width: 50px;
  height: 50px;
  color: white;
  z-index: 999;
}
.to-top-button{
  right: 5%;
  bottom: 8%;
 font-size: 24px;
  cursor: pointer;
}
.scroll-button {
  left: 50%;
  bottom: 6%;
      left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  z-index: 999;

  span{
    font-size: 27px;
  }
}
.top,.scroll {
  display: inline-block;
  padding-top: 70px;
  position: relative;

}
.top::before {
  animation: top 3.5s infinite;
  border: solid white;
  border-width: 1px 1px 0 0;
  content: "";
  display: inline-block;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform: rotate(-45deg);
  width: 20px;
  height: 20px;
}
@keyframes top {
  80% {
    transform: rotate(-45deg) translate(0, 0);
  }
  0% {
    transform: rotate(-45deg) translate(-30px, 30px);
  }
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.scroll::before {
animation: scroll 3.5s infinite;
  border: solid white;
  border-width: 0 0 1px 1px;
  content: "";
  display: inline-block;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform: rotate(-45deg);
  width: 20px;
  height: 20px;
}


.scroll-button{
  z-index: 2000;
  color: white;
  font-size: 50px;
}
.over-baseline{
  display: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>