var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recruit-deshi-happy"},[_c('div',{staticClass:"happy-cover"},[_c('div',{staticClass:"img-cover"},[_c('img',{staticClass:"base",attrs:{"src":`${_vm.rootUrl}/img/deshi/th_deshi202305-pa002-1.png`,"alt":"弟子になる理由"}}),_c('img',{staticClass:"deco",class:{ fadeIn: _vm.displayData.display01 === true },staticStyle:{"z-index":"100"},attrs:{"src":`${_vm.rootUrl}/img/deshi/th_deshi202305-pa002-2.png`,"alt":"弟子になる理由"}})]),_vm._m(0)]),_c('div',{staticClass:"way-cover"},[_c('div',{staticClass:"way"},[_c('div',{staticClass:"year"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/deshi/th_kyujin202305-pa007.png`,"alt":"金丸"}}),_c('p',[_vm._v("1年目  八寸場・盛り付け")])]),_vm._m(1)]),_c('div',{staticClass:"way"},[_c('div',{staticClass:"year"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/deshi/th_kyujin202305-pa007.png`,"alt":"金丸"}}),_c('p',[_vm._v("2年目  焼き場")])]),_vm._m(2)]),_c('div',{staticClass:"way"},[_c('div',{staticClass:"year"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/deshi/th_kyujin202305-pa007.png`,"alt":"金丸"}}),_c('p',[_vm._v("4年目  揚げ物")])]),_vm._m(3)]),_c('div',{staticClass:"way"},[_c('div',{staticClass:"year"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/deshi/th_kyujin202305-pa007.png`,"alt":"金丸"}}),_c('p',[_vm._v("6年目  蒸し場・煮方")])]),_vm._m(4)]),_c('div',{staticClass:"way"},[_c('div',{staticClass:"year"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/deshi/th_kyujin202305-pa007.png`,"alt":"金丸"}}),_c('p',[_vm._v("8年目  刺し場")])]),_vm._m(5)]),_c('div',{staticClass:"way"},[_c('div',{staticClass:"year"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/deshi/th_kyujin202305-pa007.png`,"alt":"金丸"}}),_c('p',[_vm._v("10年目 独立")])]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',{staticClass:"big"},[_vm._v("10年かけないと見えない"),_c('br'),_vm._v("日本料理の真髄")]),_c('p',{staticClass:"small"},[_vm._v(" 食材が豊富な年もあれば、"),_c('br',{staticClass:"sp-only"}),_vm._v("不作な年もある。"),_c('br'),_vm._v(" 旬の食材を選り抜き、"),_c('br',{staticClass:"sp-only"}),_vm._v("時節にあう器という着物をきせる。"),_c('br'),_vm._v(" 10年かけてやっと日本料理の四季と共鳴できます。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" この期間に現場の作業や季節ごとの流れを把握し、先輩たちの仕事内容を理解します。"),_c('br',{staticClass:"pc-only"}),_vm._v("和食の特徴である「美しさ」を表現するための大事な仕事です。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" 魚はもちろん、肉や野菜も取り扱います。"),_c('br',{staticClass:"pc-only"}),_vm._v("綺麗な焼き目は料理の美味しさを引き立たせる大事な仕事です。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" 天ぷらなどを揚げる「揚げ場」を担当します。"),_c('br',{staticClass:"pc-only"}),_vm._v("素材ごとの加熱温度とスピードを意識しながら味を引き出す大事な仕事です。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" 蒸し場担当者はすべての味付けをチェックしますので、椀物・蒸し物・煮物も担当します。"),_c('br',{staticClass:"pc-only"}),_vm._v("蒸し場は蒸し物を担当するほかに「味付け」を担う大事な仕事です。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" この刺し場を担当する料理人が板前・板場と呼ばれます。"),_c('br',{staticClass:"pc-only"}),_vm._v("料理以外の心配りも重要な仕事となります。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_vm._v(" 独立・開業して自分の店を持てる一流料理人になっています。"),_c('br',{staticClass:"pc-only"}),_vm._v("平野のサポートのもと、あなたのタイミングで独立へと進みます。 ")])
}]

export { render, staticRenderFns }