/**
 * ページタイトル設定
 * @param pathTitle
 * @returns {string}
 */
 export const setTitle = pathTitle => {
    const pageTitle = pathTitle;
    return (window.document.title = pageTitle);
};

/**
 * Description設定
 * @param pathMeta
 */
export const setDescription = pathMeta => {
    const defaultDescription = 'This is initial description.';
    const description = pathMeta ? pathMeta : defaultDescription;
    document
        .querySelector("meta[name='description']")
        .setAttribute('content', description);
};
//ページごとにnoindex設定
export const setNoIndex = pathMeta => {
    const defaultNoIndex = 'all';
    const noindex = pathMeta ? pathMeta : defaultNoIndex;
    document
        .querySelector("meta[name='robots']")
        .setAttribute('content', noindex);
};


export const globalMixins = {
    methods: {
        setTitle,
        setDescription,
        setNoIndex,//ページごとにnoindex設定
    },
};
