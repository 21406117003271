<template>
  <div class="osechi-info">
    <div class="text">
      <h3>※注意事項※</h3>
      <p class="mb">
      10月1日(土)から10月14日(金)までは先行販売期間となります。
      <br>
      普段、当店をご利用いただいているお客様に限り、おせち料理
      のご注文が可能となっています。</p>
      <p class="mb">
      当店をご利用いただいていないお客様からも、注文予約システムからおせち料理のご注文はできてしまいますが、当店で確認後、キャンセル処理をさせていただきます。その場合、キャンセル料の5%が発生しますので、ご注文をいただかないよう何卒よろしくお願いいたします。</p>
      <p class="mb">
      通常販売は10月15日(土)から開始となります。当店をご利用いただいていないお客様は、10月15日(土)以降にご注文をお
      願いいたします。<br />
      なお、おせち料理は売り切れしだい販売終了となります。何卒
      ご理解のほどお願い申し上げます。</p>

        <a href="https://chumonyoyaku.com/00101/osechi/" target="_blank" rel="noopener noreferrer" class="osechi-button">注文予約システムから注文する</a>
    </div>
  </div>
</template>

<script>
// import UiDish from "@/components/Ui/Dish.vue";
// import UiPrice from "@/components/Ui/Price.vue";
// import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "lunch-box",
  components: {
    // UiSliderBox,
    // UiDish,
    // UiPrice,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.text {
  padding: 30px;
  line-height: 2em;
  @media screen and (max-width: 767px) {
    // padding-top: 0;
  }
  .mb{
    margin-bottom: 50px;
  }
}

.osechi-button{
width: 300px;
margin: 20px auto;
// background-color:#3c3c3c;
border-top: #9a8338 2px solid;
border-bottom: #9a8338 2px solid;
padding: 5px 30px;
display: block;
text-decoration: none;
color: white;
transition: all .2s;
font-size: 17px;
@media screen and (max-width: 767px) {
  margin-top: 50px;
}

&:hover{
background-color: #e6e6e6;
  color: black;
  opacity: 0.8;
  font-weight: bold;

}
}
</style>
