<template>
  <div class="ctrl-home">

    <div class="wrap">
    <CtrlHome />
    </div>



  </div>
</template>
<script>
import CtrlHome from "@/components/Ctrl/Home.vue";
export default {
  name: 'ctrl-home',
  components: {
    CtrlHome,
  },
data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
