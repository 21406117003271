<template>
  <div class="recruit-new-message">
    <div class="cover wrap">
      <div class="text">
        <img
          :src="`${rootUrl}/img/deshi/th_deshi202305-pa005.png`"
          alt="平野の思い"
          class="hirano-mission pc-only"
        />
        <img
          :src="`${rootUrl}/img/deshi/th_deshi202305-pa005sp.png`"
          alt="平野の思い"
          class="hirano-mission sp-only"
        />
        <p>
          私が料理の道に進んだ頃は「見て学べ」というスタイルが当然の時代。学ぶことはおろか、最初は師匠に名前すら呼んでもらえない、非常に厳しい修行の日々でした。そんな中、「自分は大丈夫なのか」「一流の料理人になれるのか」と何度も不安になり、悩んだことを今でも思い出します。
        </p>
        <p>
          そんな私を支え続けたのは、「一流の料理人になりたい」という熱い思いでした。この熱い思いを胸に、今日まで日本料理の道で研鑽を重ね、お店を持ち20年。今ではミシュラン一つ星にも選出され、「美味しかった」「ありがとう」「また来るね」と毎日お客様に言っていただけるまでになりました。このような自身の経験から、「日本料理を極めたい」という熱い思いこそ、この道において大切なことだと私は学びしました。
        </p>
        <p>
          料理の道に飛び込んで、がむしゃらに走り続けてきた30年ですが、もっと美味しい料理を追求していきたい。さらにお店を良くしてお客様に感動を与えたい。こんな思いで今日も料理に向き合っています。そして、この思いを形にしていくには、私の思いに共感する、熱い気持ちを持った仲間を育てていく必要があると考えるようになりました。また、「見て学べ」というスタイルではなく、「しっかりと教育し、育てる」という修行こそ、繊細な日本料理の追求と、料理人としての独り立ちに大切だと考えるようになりました。
        </p>
        <p>
          このような私が描く未来を実現するために、岐阜でより良い待遇を整え、熱い思いを持つ人材と一緒に仕事をし、育てる。これこそ、これからの私の使命であると考えています。ぜひ熱い想いでぶつかってきてください。私が学び、身につけてきたすべてを伝え、一流の日本料理人に育て上げます。
        </p>
      </div>
      <div class="takumi">
        <img
          :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa002.png`"
          alt="和イラスト"
          class="gold"
        />
        <img
          :src="`${rootUrl}/img/kyujin/th_kyujin202305-006.jpg`"
          alt="平野 功治"
          class="takumi-img"
        />
        <p style="text-align: center;">店主 平野功治</p>
      </div>
    </div>
    <div class="recommendation-cover wrap">
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-pa003.png`"
        alt="こんな方におすすめ"
        class="osusume"
      />
      <ul class="row1">
        <li>「いつか独立して、自分の店を持ちたい」という明確な目標がある方</li>
        <li>「師匠とする料理人を見つけ、全力で学びたい」と意気込む方</li>
        <li>
          「手に職をつけて、これだと思う道で生きていきたい」と強い意志の方
        </li>
        <li>
          「違うジャンルの経験しかないけど日本料理に挑戦したい」と新たな道に進みたい方
        </li>
        <li>
          「料理が好き、日本料理が好き」で料理への探究心が強くて極めたい方
        </li>
      </ul>
    </div>
    <div class="recommendation-cover wrap">
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-005.jpg`"
        alt="弟子に求めるもの"
        class="want"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-pa004.png`"
        alt="こんな方におすすめ"
        class="osusume"
      />
      <ul class="row2">
        <li>熱い魂の持ち主であること</li>
        <li>目の前の仕事に懸命に取り組むこと</li>
        <li>真面目な性格の持ち主であること</li>
        <li>忍耐力があること</li>
        <li>目標を持ち続け、常に上を目指して努力ができること</li>
        <li>コミュニケーション力があること</li>
        <li>料理の味に敏感でいるために非喫煙者であること</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "recruit-new-message",
  components: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-new-message {
  background-color: var(--black);
  padding-top: 200px;
  padding-bottom: 200px;
  position: relative;
  @media screen and (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 70px;
    overflow-x: hidden;
    // overflow: hidden;
  }
  .hirano-mission {
    width: 100%;
    margin-bottom: 30px;
  }
  .gold {
    z-index: 10;
    position: absolute;
    width: 10%;
    top: -85px;
    right: calc(calc(100vw - 1100px) * 0.36);
    animation: rotate-anime 60s linear infinite;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      right: 28px;
      width: 140px;
      z-index:300;

    }

    @media screen and (max-width: 767px) {
      // right: calc(calc(100vw - 1100px) * 0.36);
      right: -10%;
      top: -20px;
      width: 150px;
      //後で直す
    }
  }
  @keyframes rotate-anime {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .cover {
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
    .takumi {
      width: 25%;
      text-align: left;
      @media screen and (max-width: 767px) {
        width: 80%;
      }
      p {
        letter-spacing: 0.15em;
        font-size: 20px;
        span {
          font-size: 18px;
        }
      }
    }
    .text {
      text-align: left;
      width: 70%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h3 {
        border-bottom: 1px solid white;
        font-size: 25px;
        padding-bottom: 10px;
        margin-bottom: 50px;
        @media screen and (max-width: 767px) {
          font-size: 20px;
          margin-top: 50px;
          margin-bottom: 20px;
        }
      }
    }
    .hirano-mission {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    p{
      @media screen and (max-width: 767px) {
        width: 80%;
        margin: 20px auto;
      }

    }
  }
  .recommendation-cover {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 100px;
    text-align: left;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 30px;
    }
    .osusume {
      width: 200px;
      @media screen and (max-width: 767px) {
        width: 50%;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
    .want {
      width: 25%;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 100px;
      }
    }
    ul {
      color: white;
      font-size: 21px;
      // margin-top: 30px;
      li {
        list-style: disc;
        margin-bottom: 20px;
        @media screen and (max-width: 767px){
          margin-left: 20px;
          font-size: 18px;
          // padding-left: 20px;
        }
      }
    }
    .row1 {
      width: calc(95% - 200px);
      @media screen and (max-width: 767px){
        width: 100%;
      }
    }
    .row2 {
      width: calc(65% - 200px);
      @media screen and (max-width: 767px){
        width: 100%;
      }
    }
  }
}
</style>
