var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-button"},[_c('div',{staticClass:"scroll-button",class:{'over-baseline': _vm.scrollY < 200 && _vm.path ===`/`}},[_vm._m(0)]),_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
      el: '#app',
      container: 'body',
      duration: 750,
      easing: 'ease',
      offset: 0,
    }),expression:"{\n      el: '#app',\n      container: 'body',\n      duration: 750,\n      easing: 'ease',\n      offset: 0,\n    }"}],staticClass:"to-top-button",class:{'over-baseline': _vm.scrollY >= 500 }},[_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll"},[_c('span',[_vm._v("scroll")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('span',{staticClass:"txt"},[_vm._v("Top")])])
}]

export { render, staticRenderFns }