<template>
  <div class="recruit-qa">
    <div class="wrap">
      <div class="black-bar">
        <h3>よくある質問</h3>
      </div>
      <div class="block-cover">
        <UiQaQaBox
          v-for="qaBoxListData in qaBoxList"
          :key="qaBoxListData.id"
          :qestion="qaBoxListData.qestion"
          :answer="qaBoxListData.answer"
          class="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiQaQaBox from "../Ui/Qa/QaBox.vue";
export default {
  name: "recruit-qa",
  components: { UiQaQaBox },
  data() {
    return {
      qaBoxList: [
        {
          id: 1,
          qestion: "車通勤は可能ですか？",
          answer:
            "店舗近くに駐車場を完備していますので、車・バイク・自転車での通勤が可能です。",
        },
        {
          id: 2,
          qestion: "飲食業界は未経験ですが、応募は可能ですか？",
          answer:
            "もちろん大丈夫です！基礎から丁寧にしっかり教えます。ここまで読んで、匠味 平野で働きたいと思えば、ぜひ応募してください。",
        },
        {
          id: 3,
          qestion:
            "料理は好きですが、接客業があまり得意ではありません。大丈夫でしょうか？",
          answer:
            "お昼の調理補助の場合、お客様に接客するお仕事はありません。料理を好きな気持ちがあれば大丈夫です。",
        },
      ],
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-qa {
  padding-top: 200px;
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .black-bar {
    background-color: var(--black);
    color: white;
    font-size: 22px;
    padding: 15px 0;
    margin-bottom: 50px;
        @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
}
</style>
