<template>
  <div class="access">

    <div class="wrap">
    <InfoAccess />
    </div>

    <div class="wrap" style="padding-top:100px">
    <Introduction />
    </div>


  </div>
</template>
<script>
import InfoAccess from "@/components/Access/InfoAccess.vue";
import Introduction from "@/components/Access/Introduction.vue";
export default {
  name: 'access',
  components: {
    InfoAccess,
    Introduction,
  },
data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
