<template>
  <div class="course">
    <div class="couse-cover">
    <UiDish title="お料理" />

    <UiPrice
      :type="0"
      description="季節にあわせた鍋物料理もご用意できます"
    />

    <div class="cover">
      <router-link to="/menu"> 詳細はこちら </router-link>
    </div>
</div>
  </div>
</template>

<script>
import UiPrice from "@/components/Ui/Price.vue";
import UiDish from "@/components/Ui/Dish.vue";
export default {
  name: "course",
  components: {
    UiDish,
    UiPrice,
  },
  data() {
    return {

    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
  plugins: [{ src: "~/plugins/swiper", mode: "client" }],
};
</script>

<style lang="scss" scoped>
.course {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  .couse-cover{
    background-color: black;
    width:100%;
    padding-top:100px;
    padding-bottom: 50px;
  }

  .cover {
    width: 210px;
    margin: 60px auto;
    a {
      text-decoration: none;
      color: white;
      background: black;
      border: none;
      position: relative;
      font-size: 18px;
      padding: 13px 20px;
      cursor: pointer;
      transition: 1000ms ease all;
      outline: none;

       @media screen and (max-width: 767px){
        //  border-top: #9c843c solid 1px;
        //  border-bottom: #9c843c solid 1px;
         border-top: gray solid 1px;
         border-bottom: gray solid 1px;
       }
    }
    a:hover {
      color: white;
    }
    a:before,
    a:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 2px;
      width: 0;
      // background: #9c843c;
      background: gray;
      transition: 500ms ease all;
    }
    a:after {
      right: inherit;
      top: inherit;
      left: 0;
      bottom: 0;
    }
    a:hover:before,
    a:hover:after {
      width: 100%;
      transition: 1000ms ease all;
    }
  }

}
</style>
