<template>
  <div class="recruit-new-info">
    <div class="title">
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa011-1.png`"
        alt="デコ１"
        class="hana"
        :class="{ fadeIn2: displayData.display01 === true }"
      />
      <h2>求人詳細情報</h2>
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa011-2.png`"
        alt="デコ２"
        class="hana"
        :class="{ fadeIn2: displayData.display01 === true }"
      />
    </div>

    <div class="top">
      <p class="stuff"><span> ＜正社員＞</span><br /></p>
      <div class="option">
        <p class="surround">半額家賃補助あり</p>
        <p class="surround">年１回の昇給あり</p>
        <p class="surround">昼・夜と美味しい賄い付</p>
        <p class="surround">毎週日曜休み</p>
        <p class="surround">年２回賞与</p>
        <p class="surround">車通勤可能</p>
      </div>
    </div>

    <UiTable :tableList="tableList" class="mb200" />
  </div>
</template>

<script>
import UiTable from "../Ui/Table.vue";
import { mapGetters } from "vuex";
export default {
  name: "recruit-learn",
  components: { UiTable },
  data() {
    return {
      configList: [
        {
          matchMedia: "(min-width: 768px)",
          displayDefinitionList: [
            {
              offset: 8000,
              display: "display01",
            },
          ],
        },
        {
          matchMedia: "(max-width: 768px)",
          displayDefinitionList: [
            {
              offset: 13300,
              display: "display01",
            },
          ],
        },
      ],
      displayDefinitionList: [],
      displayData: {
        display01: false,
      },
      tableList: [
        {
          id: 1,
          th: "職種",
          td: "日本料理人",
        },
        {
          id: 2,
          th: "雇用<br class=sp-only>形態",
          td: "正社員",
        },
        {
          id: 3,
          th: "仕事<br class=sp-only>内容",
          td:
            "主に調理や接客をお願いします。最初は補助業務や業務の基本的なことから学びます。毎朝市場から仕入れた新鮮な食材を夜の営業に向けて大将と仕込みをします。たくさんの実務を経験し、やがて一人前の料理人とし料理を任せます。",
        },
        {
          id: 4,
          th: "応募<br class=sp-only>条件",
          td:
            "学歴・経歴不問、未経験者歓迎です。<br>40歳以下の方(将来の独立を目指した長期キャリア形成のため)",
        },
        {
          id: 5,
          th: "募集<br class=sp-only>人数",
          td:
            "1名<br>(募集は1名ですが、現在すでに一緒に働いている兄弟子がいます。)",
        },
        {
          id: 6,
          th: "給与",
          td:
            "月給20～35万円(年齢・経験考慮)<br class=sp-only>【試用期間3ヶ月有（同条件）】",
        },
        {
          id: 7,
          th: "通勤<br class=sp-only>手当",
          td:
            "規定によって支給<br>※バイク・徒歩・車通勤可能です。無料駐車場完備しています。",
        },
        {
          id: 8,
          th: "勤務地",
          td:
            "岐阜県岐阜市御浪町10番地御浪ビル1Ｆ<br>※名鉄岐阜駅から車で5分ほどです。",
        },
        {
          id: 9,
          th: "勤務<br class=sp-only>時間",
          td: "10:00～22:00（休憩あり）",
        },
        {
          id: 10,
          th: "福利<br class=sp-only>厚生",
          td:
            "昇給あり、１年後から年２回賞与あり、美味しいまかないあり<br>家賃半額補助あり、名店視察あり<br>退職金制度、交通費規定支給、制服貸与、社会保険完備",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },

  watch: {
    // ４．あとは同じ
    scrollY(offset) {
      //
      let displayList = this.displayDefinitionList.filter(function(
        displayDefinitionData
      ) {
        return displayDefinitionData.offset <= offset;
      });

      //
      displayList.forEach((displayData) => {
        this.displayData[displayData.display] = true;
      });
    },
  },
  created() {
    // ２．configListから、画面幅に合致するものを取得
    let configList = this.configList.filter(function(configData) {
      return window.matchMedia(configData.matchMedia).matches;
    });

    // ３．dataのdisplayDefinitionListに、取得できたものを格納
    console.log("===== configList =====");
    console.log(configList);
    if (configList.length > 0) {
      this.displayDefinitionList = configList[0].displayDefinitionList;
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-new-info {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto 50px;
    @media screen and (max-width: 767px) {
      width: 80%;
    }
    .hana {
      width: 50px;
      opacity: 0;
      @media screen and (max-width: 767px) {
        width: 30px;
      }
    }
    h2 {
      font-size: 40px;
      margin-bottom: 0;
      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }
  }
  .gold-bar {
    background-color: var(--gold);
    color: white;
    font-size: 22px;
    padding: 15px 0;
  }
  .top {
    display: flex;
    align-items: flex-start;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 20px;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
      align-items: flex-start;
    }
    .stuff {
      text-align: left;
    }
    .option {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-size: 17px;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
      .surround {
        margin-top: 10px;
        border: 1px var(--black) solid;
        padding: 0 20px;
        margin-right: 15px;
        @media screen and (max-width: 1100px) {
          padding: 0 10px;
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
  }
  .kakko-cover {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .kakko {
      width: 20px;
    }
  }
  .point {
    font-size: 25px;
    text-align: left;
    margin-bottom: 20px;
    margin-left: -0.5em;
    @media screen and (max-width: 767px) {
      text-align: center;
      margin-left: 0;
      margin-bottom: 0;
      color: var(--gold);
    }
  }
  .list {
    text-align: left;
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
    .number {
      width: 50px;
    }
    .text {
      margin-left: 20px;
      margin-top: 5px;
      @media screen and (max-width: 767px) {
        // margin-top: 0;
        margin-left: 10px;
        margin-top: 3px;
      }
      .sub-title {
        font-size: 25px;
        margin-left: -2px;
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
          font-size: 23px;
        }
        // margin-top: 5px;
      }
    }
  }
  .bottom-cover {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      align-items: flex-start;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
    .like {
      width: 200px;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 20%;
        margin-right: 40px;
      }
      @media screen and (max-width: 767px) {
        width: 50%;
      }
    }
    ul {
      list-style: disc;
      text-align: left;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        margin-right: 20px;
      }
      @media screen and (max-width: 767px) {
        margin-left: 30px;
        margin-top: 20px;
      }
      li {
        font-size: 25px;
        margin-bottom: 10px;
        @media screen and (max-width: 1100px) {
          font-size: 20px;
        }
      }
    }
    .temaki {
      width: 25%;
      @media screen and (max-width: 767px) {
        width: 70%;
        margin-top: 20px;
      }
    }
  }
  .button-cover {
    border: 1px solid var(--black);
    width: 200px;
    margin: 50px auto 150px;
    transition: 3s;
    a {
      text-decoration: none;
      color: black;
      transition: 3s;
    }
    &:hover {
      background-color: var(--black);
      a {
        color: white;
      }
    }
  }
  .mt50 {
    margin-top: 80px;
  }
  .mb150 {
    margin-bottom: 150px;
  }
  .mb200 {
    margin-bottom: 200px;
    @media screen and (max-width: 767px) {
      margin-bottom: 70px;
    }
  }
  .seisyain {
    width: 120px;
  }
}
</style>
