import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'


import Home from '../views/Home.vue'
import Menu from '../views/Menu.vue'
import Access from '../views/Access.vue'
import Recruit from '../views/Recruit.vue'
import Osechi from '../views/Osechi.vue'
import OsechiNote from '../views/OsechiNote.vue'
import RecruitNew from '../views/RecruitNew.vue'
import RecruitDeshi from '../views/RecruitDeshi.vue'

//管理画面
import CtrlHome from '../views/Ctrl/Home.vue'
import CtrlLogin from '../views/Ctrl/Login.vue'
import CtrlInfo from '../views/Ctrl/Info.vue'
import CtrlImg from '../views/Ctrl/Img.vue'


import {
  setTitle,
  setDescription,
  setNoIndex,
} from '@/mixins/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '匠味 平野（たくみ ひらの）｜岐阜市の日本料理・和食',
      description: '匠味 平野の公式ホームページです。岐阜県岐阜市御浪町にある日本料理「匠味 平野」では、日本各地から厳選した四季折々の旬素材ならではの味を匠の技で、おもてなしさせていただきます。ぜひご堪能くださいませ。',
      noIndex:'all',
    }
  },
  {
    path: '/menu',
    name: 'menu',
    // redirect:'/',
    component: Menu,
    meta: {
      title: 'お品書き｜匠味 平野（たくみ ひらの）',
      description: '岐阜県岐阜市御浪町にある日本料理「匠味 平野」のお品書きです。コース料理、お弁当についてご紹介しています。春夏秋冬のお料理について写真でご確認いただけます。',
      noIndex:'all',
    }
  },
  {
    path: '/access',
    name: 'access',
    component: Access,
    meta: {
      title: '店舗情報・アクセス｜匠味 平野（たくみ ひらの）',
      description: '匠味 平野の店舗情報です。サフランの赤いカンバンが目印の建物に当店はございます。電話番号：	058-263-8644、住所： 岐阜市御浪町10番地(御浪ビル１F)、営業時間：17時30分~、定休日：日曜日、第一・第三月曜日（※月曜日が祝日の場合もお休み）'
      ,
      noIndex:'all',
    }
  },
  {
    path: '/recruit-new',
    name: 'recruit-new',
    component: Recruit,
    meta: {
      title: '匠味 料理人・調理師の求人｜匠味 平野（たくみ ひらの）',
      description: '岐阜県岐阜市御浪町にある日本料理「匠味 平野」の求人情報です。料理人・調理師として、将来的な独立を目指し、包丁一本で食べていけるスキルを目指す人はぜひご覧ください。'
      ,
      noIndex:'noindex',
    }
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: RecruitNew,
    meta: {
      title: ' 料理人・調理師・調理補助・ホールスタッフの求人｜匠味 平野',
      description: '岐阜市御浪町にある日本料理「匠味 平野」の正社員・パート・アルバイトの求人情報です。料理人・調理師として、将来的な独立を応援します。週２回３時間～ 調理補助（昼のみも可）、ホールスタッフ募集しています。また、日曜休み、高時給で求人をお探しの方におすすめです。うれしいまかないつきです。'
      ,
      noIndex:'all',
    },

  },
  {
    path: '/recruit-deshi',
    name: 'recruit-deshi',
    component: RecruitDeshi,
    meta: {
      title: '【独立応援】料理人・調理師の正社員求人｜匠味 平野',
      description: '岐阜市御浪町にある日本料理「匠味 平野」では、料理人・調理師として、将来的に独立を目指したい方を正社員で１名限定で募集しています。独立したい・料理人になるという熱い気持ちの持ち主なら経験者、未経験は問いません。また、最高の弟子を育てるため、働く環境を整えています。昇給あり・賞与有・日曜休み・家賃半額補助有',
      noIndex:'all',
    }
  },
  {
    path: '/osechi',
    name: 'osechi',
    component: Osechi,
    meta: {
      title: 'おせち料理のご案内｜匠味 平野（たくみ ひらの）',
      description: '2023年度おせち料理のご案内です。',
      noIndex:'noindex',
    },
  },
  {
    path: '/osechi/note',
    name: 'osechi-note',
    component: OsechiNote,
    meta: {
      title: 'おせち料理のご案内｜匠味 平野（たくみ ひらの）',
      description: '2023年度おせち料理のご案内です。',
      noIndex:'noindex',
    }
  },
  {
    path: '/ctrl', 
    name: 'ctrl-home',
    component: CtrlHome,
    meta: {
      title: '管理画面ログインページ',
      description: '管理画面ログインページです。',
      requiresAuth: true,
      noIndex:'noindex',
    }
  },
  {
    path: '/ctrl/login', 
    name: 'ctrl-login',
    // redirect:'/',
    component: CtrlLogin,
    meta: {
      title: '管理画面メニューページ',
      description: '管理画面メニューページです。',
      noIndex:'noindex',
    }
  },
  {
    path: '/ctrl/info', 
    name: 'ctrl-info',
    // redirect:'/',
    component: CtrlInfo,
    meta: {
      title: 'お知らせ投稿ページ',
      description: 'お知らせ投稿ページです。',
      requiresAuth: true,
      noIndex:'noindex',
    }
  },
  {
    path: '/ctrl/img', 
    name: 'ctrl-img',
    // redirect:'/',
    component: CtrlImg,
    meta: {
      title: '撮影画像一覧',
      description: '撮影画像一覧ページです。',
      requiresAuth: true,
      noIndex:'noindex',
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);//タイトル設定
  setDescription(to.meta.description);//description設定
  setNoIndex(to.meta.noIndex);//ページごとにnoindex設定
  store.dispatch("setRootUrl");

  if (to.path !== "/" && to.path.slice(-1) === "/") {
    next(to.path.slice(0, -1));
  } else {
    // no action.
  }

  //認証が必要なページかどうか(ログインチェック)
  if (to.matched.some(record => record.meta.requiresAuth) === true) {

    // // 認証状態を確認
    store.dispatch('loginCheck').then((res) => {
      if (res.data.status === 200) {
        store.dispatch('setLogin', 1); //ログインチェック対象のページでのみ、login状態の監視をするようにする
        next();
      } else {
        store.dispatch('setLogin', 0); //ログインチェック対象のページでのみ、login状態の監視をするようにする
        next('/ctrl/login');
      }
    }).catch((err) => {
      console.log(err);
    });

  } else {
    console.log("requiresAuth through.");
    next();
  }



});

export default router
