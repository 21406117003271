<template>
  <div class="recruit-new-about">
    <div class="black-bar">
      <h3>匠味 平野について</h3>
    </div>
    <div class="block-cover">
      <p class="text">
        岐阜県岐阜市の柳ヶ瀬・御浪町にある日本料理店『匠味
        平野』。毎朝、名古屋・岐阜・北陸敦賀から仕入れた四季折々の旬な食材と、洗練された匠の技で一期一会のおもてなしをご提供しています。地元の方々に愛され続けて20年。毎日たくさんのご予約をいただいています。現在、そんな匠味
        平野では一緒にお店を盛り上げてくれる仲間（料理人・調理補助・ホールスタッフ）を募集しています。
      </p>
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-008.jpg`"
        alt="ミシュラン"
        class="michelin"
      />
    </div>
    <div class="block-cover">
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-002.jpg`"
        alt="匠味 平野イメージ1"
        class="hirano-image"
      />
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-003.jpg`"
        alt="匠味 平野イメージ2"
        class="hirano-image pc-only"
      />
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-004.jpg`"
        alt="匠味 平野イメージ3"
        class="hirano-image pc-only"
      />
      <img
        :src="`${rootUrl}/img/kyujin/th_kyujin202305-005.jpg`"
        alt="匠味 平野イメージ4"
        class="hirano-image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "recruit-learn",
  components: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-new-about {
  // padding-top: 100px;
          @media screen and (max-width: 767px) {
    padding-top: 20px;
    }
  .black-bar {
    background-color: var(--black);
    color: white;
    font-size: 22px;
    padding: 15px 0;
  }
  .block-cover {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      flex-direction: column;
       margin-top: 30px;
    }

    .text {
      text-align: left;
      font-size: 16px;
      width: 70%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .michelin {
      width: 18%;
      @media screen and (max-width: 767px) {
        width: 50%;
        margin: 20px auto;
      }
    }
    .hirano-image {
      width: 23%;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
