import { render, staticRenderFns } from "./Img.vue?vue&type=template&id=8d57dfc0&scoped=true"
import script from "./Img.vue?vue&type=script&lang=js"
export * from "./Img.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d57dfc0",
  null
  
)

export default component.exports