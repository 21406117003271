<template>
  <div class="menu-saba">
    <div class="soba-cover" :class="{ 'over-baseline3': scrollY > 4300 }">
      <div class="line">
        <div class="line2">
          <div class="lineinappear">
            <img
              :src="`${rootUrl}/img/hirano_oshinagaki-soba001.jpg`"
              alt="蕎麦１"
            />
          </div>
        </div>
      </div>
      <div class="line3">
        <div class="line4">
          <div class="lineinappear">
            <img
              :src="`${rootUrl}/img/hirano_oshinagaki-soba002.jpg`"
              alt="蕎麦２"
            />
          </div>
        </div>
      </div>
      <div class="line">
        <div class="line2">
          <div class="lineinappear">
            <img
              :src="`${rootUrl}/img/hirano_oshinagaki-soba-sp001.jpg`"
              alt="蕎麦"
              class="sp-only"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="text-cover">
      <img :src="`${rootUrl}/img/hirano_oshinagaki-soba005.jpg`" alt="蕎麦" />
      <img
        :src="`${rootUrl}/img/hirano_oshinagaki-soba-sp002.jpg`"
        alt="蕎麦"
        class="sp-only"
      />

      <div class="text-box">
        <div class="soba-dis">
          <p>こだわった蕎麦粉は</p>
          <p>｢岐阜県荘川町｣産と｢福井県大野町｣産をブレンド。</p>
          <p>至高の風味と喉越しをお楽しみください。</p>
          <p>ざる蕎麦はもちろん、季節によって</p>
          <p>おろし蕎麦、自然薯蕎麦、</p>
          <p>鴨せいろ、南蛮などご用意しております。</p>
          <p>ぜひ一度ご賞味くださいませ。</p>
        </div>
        <div class="soba-title">
          <h3>お料理の〆に<br /><span>匠味自慢の蕎麦を</span></h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "menu-seasons-season",
  components: {
    // UiSliderBox,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        this.scrollTimer = setTimeout(
          function () {
            this.scrollY = window.scrollY;
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            console.log(this.scrollY);
          }.bind(this),
          200
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-saba {
  position: relative;
}
.soba-cover {
  display: flex;
  // margin-top: 1px;
  img {
    width: 100%;
    vertical-align: top;
    @media screen and (max-width: 976px) {
      display: none;
    }
  }
  .sp-only {
    display: none;
    @media screen and (max-width: 976px) {
      display: initial;
      width: 100%;
    }
  }
}
.text-cover {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-right: calc(calc(100vw - 1100px) / 2);
  margin-left: calc(calc(100vw - 1500px) / 4);
  padding-left: 100px;
  margin-top: 50px;
  padding-bottom: 150px;
  @media screen and (min-width: 768px) and (max-width: 1100px){
  justify-content: flex-start;
  margin-right: 0;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    bottom: 100px;
  }

  img {
    width: 50%;
    height: 50%;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .sp-only {
    display: none;
    @media screen and (max-width: 767px) {
      display: initial;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 50px;
    }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    width: 40%;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 50%;
      padding-top: 50px;
    }
    @media screen and (max-width: 767px){
      padding-right: 0px;
      padding-left: 20px;
       padding-top: 50px;
       width: 45%;

    }
  }

  .soba-title,
  .soba-dis {
    writing-mode: vertical-rl;
  }

  .soba-title {
    font-size: 30px;
    word-break: keep-all;
    line-height: 2em;
    position: absolute;
    bottom: 22%;
    right: calc(calc(100vw - 1100px) / 2);
    z-index: 1;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      right: 0%;
      bottom: 40%;
    }
    @media screen and (max-width: 767px) {
      font-size: 20px;
      right: 5%;
      bottom: 53%;
      // padding-bottom: 200px;
    }
    span {
      font-size: 65px;
      margin-top: 50px;
      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }
  }
  .soba-dis {
    width: 70%;
    line-height: 3.2em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media screen and (min-width: 768px) and (max-width: 1100px){
      margin-left: 0;
      justify-content: space-evenly;
      line-height: 2em;
    }
    @media screen and (max-width: 767px) {
      width: 80%;
      margin-left: 30px;
       justify-content: normal;
       line-height: 2.3em;
    }
  }
}

/*枠線が伸びて出現*/
.over-baseline3 {
  .soba-cover{
    img{
      display: initial;
    }
  }
  .line,.line3 {
    position: relative; /* 枠線が書かれる基点*/
  }

  /*上下線*/
  .line::before,
  .line::after {
    position: absolute;
    content: "";
    width: 0;
    height: 1px;
    background: #333; /* 枠線の色*/
  }

  /*左右線*/
  .line2::before,
  .line2::after{
    position: absolute;
    content: "";
    width: 1px;
    height: 0;
    background: #333; /* 枠線の色*/
  }

  /*上線*/
  .line::before {
    top: 1;
    left: 0;
    animation: lineAnime 2s linear 0s backwards; /*表示されて0秒後に上線が0.5秒かけて表示*/
  }

  /*右線*/
  .line2::before {
    top: 0;
    right: 0;
    animation: lineAnime2 2s linear 0.5s backwards; /*表示されて0.5秒後に右線が0.5秒かけて表示*/
  }

  /*下線*/
  .line::after {
    bottom: 0;
    right: 0;
    animation: lineAnime 2s linear 0.5s backwards; /*表示されて1秒後に下線が0.5秒かけて表示*/
  }

  /*左線*/
  .line2::after {
    bottom: 0;
    left: 0;
    animation: lineAnime2 2s linear 1.5s backwards; /*表示されて1.5秒後に左線が0.5秒かけて表示*/
  }
// 2個目の画像
  /*上下線*/
  .line3::before,
  .line3::after {
    position: absolute;
    content: "";
    width: 0;
    height: 1px;
    background: #333; /* 枠線の色*/
  }

  /*左右線*/
  .line4::before,
  .line4::after{
    position: absolute;
    content: "";
    width: 1px;
    height: 0;
    background: #333; /* 枠線の色*/
  }

  /*上線*/
  .line3::before {
    top: 1;
    left: 0;
    animation: lineAnime 2s linear 0s backwards; /*表示されて0秒後に上線が0.5秒かけて表示*/
  }

  /*右線*/
  .line4::before {
    top: 0;
    right: 0;
    animation: lineAnime2 2s linear 0.5s backwards; /*表示されて0.5秒後に右線が0.5秒かけて表示*/
  }

  /*下線*/
  .line3::after {
    bottom: 0;
    right: 0;
    animation: lineAnime 2s linear 0.5s backwards; /*表示されて1秒後に下線が0.5秒かけて表示*/
  }

  /*左線*/
  .line4::after {
    bottom: 0;
    left: 0;
    animation: lineAnime2 2s linear 1.5s backwards; /*表示されて1.5秒後に左線が0.5秒かけて表示*/
  }
  @keyframes lineAnime {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes lineAnime2 {
    0% {
      height: 0%;
    }
    100% {
      height: 100%;
    }
  }

  /*枠線内側の要素*/
  .line .lineinappear {
    animation: lineInnerAnime 2s linear 1.5s forwards; /*1.5秒後に中央のエリアが0.5秒かけて表示*/
    opacity: 0; /*初期値を透過0にする*/
  }
  .line3 .lineinappear {
    animation: lineInnerAnime 2s linear 1.5s forwards; /*1.5秒後に中央のエリアが0.5秒かけて表示*/
    opacity: 0; /*初期値を透過0にする*/
  }

  @keyframes lineInnerAnime {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
