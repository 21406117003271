<template>
  <div class="recruit-deshi-happy">
        <div class="happy-cover">
          <div class="img-cover">
            <img
              :src="`${rootUrl}/img/deshi/th_deshi202305-pa001-1.png`"
              alt="弟子になる理由"
              class="base"
            />
            <img
              :src="`${rootUrl}/img/deshi/th_deshi202305-pa001-2.png`"
              alt="弟子になる理由"
              class="deco"
              :class="{ fadeIn: displayData.display01 === true }"
            />
          </div>
          <div class="list-cover">
            <div class="list">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa008-1.png`"
                alt="パターン1"
              />
              <div class="text">
                <h3>平野の横で直接<br class="sp-only">見て・聞いて・体験して学べる
</h3>
                <p>
                  日々の実務の中で得られる経験に勝るものはありません。直接平野から学ぶことで、おもてなしの心・料理人としての志や日本文化を学びながら、一流の料理人としての技術を磨くことができます。 また、昔ながらの見て学べではなく、しかっり基礎から丁寧に教えます。
                </p>
              </div>
            </div>

            <div class="list">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa008-2.png`"
                alt="パターン2
                "
              />
              <div class="text">
                <h3>
                  京都などの本格日本料理の<br class="sp-only">視察あり
                </h3>
                <p>
                  年2回ほど、老舗の名店やそのとき話題の日本料理店へ視察に行きます。百聞は一見に如かず。実際に体験し、五感すべてで感じることが大切にしています。また、いつもお世話になっている生産者様や酒蔵様も定期的に訪問します。食材やお酒が育ってきた背景知ることで、さらに理解が深まると考えています。

                </p>
              </div>
            </div>

            <div class="list">
              <img
                :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa008-3.png`"
                alt="パターン3"
              />
              <div class="text">
                <h3>独立も積極的にサポート</h3>
                <p>
                  「いつか独立して、自分の店を持ちたい」「手に職をつけてこれだと思う道で生きていきたい」「違うジャンルに挑戦したい」など、目標を持ち、常に上を目指して努力ができる熱い想いがある人を平野は全力でサポートします。仕事に打ち込める良い環境と多くの経験ができる場を整え、一流の料理人に育て上げます。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="about">
          <p class="title-bar">独立について</p>
          <p>独立時の出店エリアは問いません。匠味 平野の直ぐ近くでもよし。地元でもよし。大都市で勝負してみてもよし。修行後は、あなたのタイミングで、あなたの希望の場所で出店してください。当店で学んだ味と日本料理の伝統を受け継ぎ、後世に残し、広めていただければ嬉しい限りです。独立後も私の仲間であることにかわりはありません。この業界は、協力し合い、互いの技と味を高め合うことが大切です。仕入れや情報交換、店舗づくり、部下の教育の相談など、あなたが望むのであれば、いつまでも私にできることはサポートしていく所在です。</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "recruit-deshi-happy",
  components: {},
  data() {
    return {
      // １．これをpropsで渡す
      configList: [
        {
          matchMedia: "(min-width: 768px)",
          displayDefinitionList: [
            {
              offset: 2984,
              display: "display01",
            },
          ],
        },
        {
          matchMedia: "(max-width: 768px)",
          displayDefinitionList: [
            {
              offset: 3600,
              display: "display01",
            },
          ],
        },
      ],

      //
      displayDefinitionList: [],
      displayData: {
        display01: false,
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
  watch: {
    // ４．あとは同じ
    scrollY(offset) {
      //
      let displayList = this.displayDefinitionList.filter(function (
        displayDefinitionData
      ) {
        return displayDefinitionData.offset <= offset;
      });

      //
      displayList.forEach((displayData) => {
        this.displayData[displayData.display] = true;
      });
    },
  },
  created() {
    // ２．configListから、画面幅に合致するものを取得
    let configList = this.configList.filter(function (configData) {
      return window.matchMedia(configData.matchMedia).matches;
    });

    // ３．dataのdisplayDefinitionListに、取得できたものを格納
    console.log("===== configList =====");
    console.log(configList);
    if (configList.length > 0) {
      this.displayDefinitionList = configList[0].displayDefinitionList;
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-deshi-happy {
    .happy-cover {
      padding-top: 200px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media screen and (max-width: 767px) {
        padding-top: 70px;
        display: block;
      }
      .img-cover {
        width: 28%;
        position: relative;
        @media screen and (max-width: 767px) {
          width: 80%;
          margin: 0 auto;
        }
      }
      .base {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        @media screen and (max-width: 767px) {
          position: relative;
        }
      }

      .list-cover {
        width: 68%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        img {
          width: 80px;
        }
        .list {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 30px;
          @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
          }
          .text {
            text-align: left;
            width: calc(100% - 100px);
            @media screen and (max-width: 767px) {
              width: 100%;
              // text-align: center;
            }
          }
          h3 {
            font-size: 25px;
            margin: 10px 0;
            @media screen and (max-width: 767px) {
              text-align: center;
            }
            span {
              font-size: 18px;
            }
          }
        }
      }
    }
    .about{
      text-align: left;
      margin-top: 30px;
      .title-bar{
        padding: 20px;
        background-color: var(--black);
        color: white;
        font-size: 26px;
      }
      p{
        font-size: 16px;
        padding: 20px;
        color: var(--black);
        border: 1px solid var(--black);
      }
    }
  }
</style>
