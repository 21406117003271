<template>
  <div class="recruit-new-flow">
    <div class="beige">
      <div class="wrap">
        <div class="black-bar">
        <h3>応募の流れ</h3>
        </div>
        <UiFlowBox
          v-for="flowBoxListData in flowBoxList"
          :key="flowBoxListData.id"
          :barNo="flowBoxListData.barNo"
          :barText="flowBoxListData.barText"
          :sankaku="flowBoxListData.sankaku"
          class="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiFlowBox from "../Ui/FlowBox.vue";
export default {
  name: "recruit-new-flow",
  components: {
    UiFlowBox,
  },
  data() {
    return {
      flowBoxList: [
        {
          id: 1,
          barNo: "01",
          barText:
            "当ページのお問い合わせフォームに、必要事項を記入の上、お問い合わせをお願いします。",
          sankaku: true,
        },
        {
          id: 2,
          barNo: "02",
          barText: "当店からお電話を差し上げ、面接日時の調整を行います。",
          sankaku: true,
        },
        {
          id: 3,
          barNo: "03",
          barText: "面接を行います。面接以外の試験はございません。",
          sankaku: true,
        },
        {
          id: 4,
          barNo: "04",
          barText: "合否の連絡をします。合格の場合は、勤務開始日を決定します。",
          sankaku: true,
        },
        {
          id: 5,
          barNo: "05",
          barText: "決定した勤務開始日から勤務がはじまります。",
          sankaku: false,
        },
      ],
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-new-flow {
  .beige {
    padding: 200px 0;
    background-color: var(--cream);
    @media screen and (max-width: 767px) {
      padding: 70px 0;
    }
    .black-bar {
      background-color: var(--black);
      color: white;
      font-size: 22px;
      padding: 15px 0;
      margin-bottom: 50px;
              @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
    }
  }
}
</style>
