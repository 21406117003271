<template>
  <div class="recruit">
    <!-- <div class="top"></div> -->
    <RecuruitNewTop class="top-space" />
    <div class="wrap">
      <RecruitNewAbout class="mb-200" />
    </div>

    <div class="wrap">
      <RecruitNewList class="mb-200" />
    </div>

    <RecruitNewHappy :barDisplay="true" :configList="configList" />

    <RecruitNewMessage class="mb-200" />

    <div class="wrap">
      <RecruitNewInfo />
    </div>

    <RecruitNewADay :configList="configList2" />

    <RecruitNewFlow />

    <RecruitNewQa />

    <div class="wrap">
      <RecuruitNewContactForm />
    </div>

    <div class="wrap">
      <p class="company-title">会社情報</p>
      <UiTable :tableList="tableList" class=" company" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RecuruitNewTop from "@/components/RecruitNew/Top.vue";
import RecruitNewAbout from "@/components/RecruitNew/About.vue";
import RecruitNewList from "@/components/RecruitNew/List.vue";
import RecruitNewHappy from "@/components/RecruitNew/Happy.vue";
import RecruitNewMessage from "@/components/RecruitNew/Message.vue";
import RecruitNewInfo from "@/components/RecruitNew/Info.vue";
import RecruitNewADay from "@/components/RecruitNew/ADay.vue";
import RecruitNewFlow from "@/components/RecruitNew/Flow.vue";
import RecruitNewQa from "@/components/RecruitNew/Qa.vue";
import RecuruitNewContactForm from "@/components/RecruitNew/ContactForm.vue";
import UiTable from "@/components/Ui/Table.vue";
// @ is an alias to /src

export default {
  name: "recruit",
  components: {
    RecuruitNewTop,
    RecruitNewAbout,
    RecruitNewList,
    RecruitNewHappy,
    RecruitNewMessage,
    RecruitNewInfo,
    RecruitNewADay,
    RecruitNewFlow,
    RecruitNewQa,
    RecuruitNewContactForm,
    UiTable,
  },
  data() {
    return {
      tableList: [
        {
          id: 1,
          th: "会社名",
          td: "株式会社 匠味 平野",
        },
        {
          id: 2,
          th: "代表者名",
          td: "平野 功治",
        },
        {
          id: 3,
          th: "住所",
          td: "岐阜市御浪町10番地(御浪ビル１F)",
        },
        {
          id: 4,
          th: "電話<br class=sp-only>番号",
          td: "058-263-8644",
        },
      ],
      configList: [
        {
          matchMedia: "(min-width: 768px)",
          displayDefinitionList: [
            {
              offset: 2306,
              display: "display01",
            },
          ],
        },
        {
          matchMedia: "(max-width: 768px)",
          displayDefinitionList: [
            {
              offset: 2500,
              display: "display01",
            },
          ],
        },
      ],
      configList2: [
        {
          matchMedia: "(min-width: 768px)",
          displayDefinitionList: [
            {
              offset: 9500,
              display: "display01",
            },
          ],
        },
        {
          matchMedia: "(max-width: 768px)",
          displayDefinitionList: [
            {
              offset: 13800,
              display: "display01",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
};
</script>

<style lang="scss" scoped>
.top-space {
  padding-bottom: 100px;
  @media screen and (max-width: 767px) {
    padding-bottom: 50px;
  }
}
.recruit {
  background-color: rgb(241, 235, 226);
  color: black;
  text-align: center;

  .top {
    height: 104px;
    width: 100%;
    background-color: rgb(241, 235, 226);
    @media screen and (max-width: 767px) {
      background-color: initial;
      height: 50px;
    }
  }
}
.company-title {
  font-size: 20px;
  margin-bottom: 20px;
}
.company {
  width: 70%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  padding: 0 0 100px;
  margin: 0 auto;
  ::v-deepth {
    @media screen and (max-width: 767px) {
      width: 20%;
    }
  }
  ::v-deeptd{
    background-color: white;
  }
}
</style>
