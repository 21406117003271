<template>
  <div class="home">
    <HomeTop />

    <div>
      <HomeInfo />
    </div>

    <!-- リニューアルセクション -->
    <!-- <div class="gray">
      <div class="wrap">
        <HomeRenewal />
      </div>
      <img
        :src="`${rootUrl}/img/renewal/202309-pa001.png`"
        alt="匠味平野 リニューアル"
        class="back-img"
        :class="{ fadeIn2: displayData.display01 === true }"
      />
    </div> -->

    <!-- <div class="wrap">
      <HomeOsechi class="space-1" />
    </div> -->

    <!-- <div class="parallax_content1" :style="`background-image: url(${rootUrl}/img/takumi-hirano_image004.jpg);`"> -->
    <div style="background-color: black">
      <div class="wrap">
        <HomeGallery class="space-3" />
      </div>
    </div>

    <div
      class="back"
      :style="`background-image: url(${rootUrl}/img/${backgroundImageSource});`"
    >
      <div class="wrap1">
        <HomeCommitment />
      </div>
    </div>

    <div class="parallax_content2">
      <div class="wrap">
        <HomeCourse class="space-2 fixed-background" />
      </div>

      <HomeMessage />

      <HomeEnd class="fixed-background" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeTop from "@/components/Home/Top.vue";
import HomeInfo from "@/components/Home/Info.vue";
import HomeGallery from "@/components/Home/Gallery.vue";
import HomeCommitment from "@/components/Home/Commitment.vue";
import HomeCourse from "@/components/Home/Course.vue";
import HomeMessage from "@/components/Home/Message.vue";
import HomeEnd from "@/components/Home/End.vue";
// import HomeRenewal from "@/components/Home/Renewal.vue";
// import HomeOsechi from "@/components/Home/Osechi.vue";
export default {
  name: "home",
  props: {
    path: String,
  },
  components: {
    HomeTop,
    HomeInfo,
    HomeGallery,
    HomeCommitment,
    HomeCourse,
    HomeMessage,
    HomeEnd,
    // HomeRenewal,
    // HomeOsechi,
  },
  data() {
    return {
      backgroundImageSource: "takumi-hirano_004.jpg",
      configList: [
        {
          matchMedia: "(min-width: 768px)",
          displayDefinitionList: [
            {
              offset: 1000,
              display: "display01",
            },
          ],
        },
        {
          matchMedia: "(max-width: 768px)",
          displayDefinitionList: [
            {
              offset: 3600,
              display: "display01",
            },
          ],
        },
      ],
            displayDefinitionList: [],
      displayData: {
        display01: false,
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
  watch: {
    // ４．あとは同じ
    scrollY(offset) {
      //
      let displayList = this.displayDefinitionList.filter(function (
        displayDefinitionData
      ) {
        return displayDefinitionData.offset <= offset;
      });

      //
      displayList.forEach((displayData) => {
        this.displayData[displayData.display] = true;
      });
    },
  },
  created() {
    // ２．configListから、画面幅に合致するものを取得
    let configList = this.configList.filter(function (configData) {
      return window.matchMedia(configData.matchMedia).matches;
    });

    // ３．dataのdisplayDefinitionListに、取得できたものを格納
    console.log("===== configList =====");
    console.log(configList);
    if (configList.length > 0) {
      this.displayDefinitionList = configList[0].displayDefinitionList;
    } else {
      // no action.
    }
  },
};
</script>

<style lang="scss" scoped>
// 傘背景 :style="`background-image: url(${rootUrl}/img/takumi-hirano_image004.jpg);`"
//長良川背景 :style="`background-image: url(${rootUrl}/img/takumi-hirano_image005.jpg);`"

.space-1 {
  margin-top: 110px;
  margin-bottom: 110px;
}
.space-3 {
  padding-top: 110px;
  padding-bottom: 200px;
}
.space-2 {
  padding-top: 150px;

  @media screen and (max-width: 767px) {
    padding-top: 50px;
  }
}

.space-3 {
  @media screen and (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 0px;
  }
}

// .back {
//   // margin-top: 150px;
//   background-position: center;
//   position: relative;
//   width: 100%;

//   .scenery {
//     position: absolute;
//   }

//   .case-5 {
//     width: 9%;
//     bottom: 10%;
//     left: 6%;
//   }

//   .case-6 {
//     width: 8%;
//     top: 20%;
//     right: 13%;
//   }
// }
.parallax_content1,
.parallax_content2
{
  width: 100%;
  background-size: cover !important;
  background-size: auto 100vh;
  background-position: center;
  background-attachment: fixed;
  background-color: rgb(0, 0, 0);
  background-blend-mode: darken;
  overflow-x: hidden;

  // @media screen and (max-width: 767px){
  //   background-attachment: scroll;
  // }
}
.gray {
  background-color: rgb(209, 209, 209);
  position: relative;
}
.back-img {
  position: absolute;
  bottom: 10%;
  right: 0;
  width: 35%;
  opacity: 0;
  @media screen and (max-width: 767px){
    display: none;
  }
}
.fadeIn2 {
  animation-duration: 3s;
  opacity: 0;
}
</style>
