<template>
  <div class="dish">
    <div class="top">
      <h2>{{ title }}</h2>
      <img
        :src="`${rootUrl}/img/takumi-hirano_pa004.png`"
        alt="雲"
        class="cloud"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Uidish",
  components: {},
  props: {
    title: String,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.dish {
  .top {
    text-align: center;
  }
}
</style>
