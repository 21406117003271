<template>
  <div class="price">
    <div class="wrap2">
      <div v-show="type === 0">
        <h2 style="margin-top: 50px">コース</h2>
        <p style="text-align: center;font-size: 12px;color:#C0C0C0;margin-bottom: 30px;line-height: 1.5;">
          新鮮な食材の仕入れ価格の上昇に伴い、一部メニューの値上げを実施しました。
          <br>
          何卒ご理解のほどお願い申し上げます。
        </p>
        <img
          class="maru"
          :src="`${rootUrl}/img/takumi-hirano_pa001.png`"
          alt="くも"
        />
      </div>
      <div class="course">
        <div v-if="type === 0">
          <p class="price">16,500円</p>
          <p class="price">19,800円</p>
        </div>
        <div v-else-if="type === 1">
            <p class="price">4,320円</p>
            <p class="price">5,400円</p>
            <p class="note">お祝い弁当、ちらし寿司もあります。詳しくはお問い合わせください。</p>
        </div>

        <p class="note" v-html="description"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Uiprice",
  components: {},
  props: {
    description: String,
    type: Number,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.price {
  text-align: center;
  .wrap2{
    width: 100%;
    margin :0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
  }
  .maru {
    width: 30px;
    line-height: 10px;
  }
  .course {

    .price {
      line-height: 60px;
    }

    .note {
      font-size: 16px;
      line-height: 35px;
      margin-top: 30px;

         @media screen and (max-width: 767px) {
           font-size: 15px;
           letter-spacing: 1px;
           line-height: 25px;



    }
    }
  }
}
</style>
