<template>
  <div class="recruit-learn">
    <div class="wrap">
      <div class="top">
        <img :src="`${rootUrl}/img/takumi-hirano_pa012.png`" alt="求人" />
      </div>
      <div class="offer">
        <h1>心を学び、技を磨く</h1>
        <p class="sub-title">匠味平野で新たな一歩を</p>

        <img
          :src="`${rootUrl}/img/takumi-hirano_027.jpg`"
          alt="椀物"
          style="margin-bottom: 63px"
        />
        <p>
          匠味平野では、スタッフを募集しております。<br />
          経験やスキルがなくても問題ありません。
        </p>
        <p>
          将来独立して自分のお店を持ちたい、<br
            class="sp-kaigyo"
          />お客様を感動させたい、<br />
          包丁一本で生きていきたい<br />
          という意欲的な方をお待ちしています。
        </p>
      </div>
    </div>

    <div class="black-bar">
      <div class="small-wrap">
        <h2>ご応募・<br class="sp-kaigyo" />お問い合わせ</h2>
        <div class="application">
          <div class="note">
            <p>
              お電話でのご応募・お問い合わせは<br
                class="pc-kaigyo"
              />なるべく営業時間外にお願いいたします。
            </p>
          </div>
          <div class="tel">
            <p><a href="tel:0582638644"> 058-263-8644</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recruit-learn",
  components: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.top {
  img {
    width: 150px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.offer {
  h1 {
    font-size: 48px;

    @media screen and (max-width: 767px) {
      font-size: 30px;
    }
  }
  .sub-title {
    padding-top: 40px;
    padding-bottom: 60px;
    font-size: 27px;
    @media screen and (max-width: 767px) {
      font-size: 20px;
      padding: 20px 0;
    }
  }
  p {
    line-height: 2.5em;
    padding-bottom: 60px;
    @media screen and (max-width: 767px) {
      padding-bottom: 30px;
    }
  }
}
.black-bar {
  background-color: rgb(62, 58, 57);
  color: white;
  padding: 30px;
  letter-spacing: 0.7em;
  display: flex;
  .small-wrap {
    width: 100vw; //画面幅に対して、基本100%
    max-width: 835px; //でも、835pxを超える画面幅でwidth: 100%では困るのでこの設定
    margin: 0 auto;

    .application {
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 767px) {
        display: block;
      }
      p {
        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 1.5em;
        }
      }

      .note,
      .tel {
        width: 50%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .note {
        letter-spacing: 0.2em;
        line-height: 2em;
        font-size: 18px;
        text-align: left;
        @media screen and (max-width: 767px) {
          margin-bottom: 20px;
          letter-spacing: 1px;
        }
      }
      .tel {
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: solid 1px white;
        border-right: solid 1px white;
        height: 52px;

        P {
          font-size: 38px;
          @media screen and (max-width: 767px) {
            font-size: 20px;
          }
          a {
            text-decoration: none;
            color: white;
            pointer-events: none;
            cursor: default;
            @media screen and (max-width: 767px) {
              pointer-events: initial;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
