<template>
  <div class="menu-seasons-spring">
    <div class="summer">
      <div class="summer-left">
        <img
          :src="`${rootUrl}/img/takumi-hirano_pa006.png`"
          alt="ヘッダー画像2"
        />
        <p>野山が若草色に染まり、</p>
        <p>そこここに早春の</p>
        <p>息吹を感じるこの季節だけの贅沢。</p>
        <p>特に筍、山菜は</p>
        <p>岐阜の繊細な季節のうつろいを</p>
        <p>感じさせてくれます。</p>
      </div>
      <div class="summer-right">
        <UiSliderBox
          :images="images"
          :swiperOption="swiperOption"
          :height="100"
          :width="100"
          :button="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "menu-seasons-season",
  components: {
    UiSliderBox,
  },
  data() {
    return {
      images: [
        { id: 1, imgpath: "hirano_oshinagaki-2022haru001.jpg", name: "椀物" },
        { id: 2, imgpath: "hirano_oshinagaki-2022haru002.jpg", name: "松茸" },
        { id: 3, imgpath: "hirano_oshinagaki-2022haru003.jpg", name: "いくら" },
        {
          id: 4,
          imgpath: "hirano_oshinagaki-2022haru004.jpg",
          name: "金目鯛",
        },
        { id: 5, imgpath: "hirano_oshinagaki-2022haru005.jpg", name: "紅葉" },
        {
          id: 6,
          imgpath: "hirano_oshinagaki-2022haru006.jpg",
          name: "栗きんとん",
        },
      ],
      swiperOption: {
        slidesPerView: 1,
        // spaceBetween: 30,
        speed: 5500,
        effect: "fade",
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay:200,
          disableOnInteraction: false,
        },
      },
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.summer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  // padding-bottom: 50px;
  // width: 100vh;
  // justify-content: flex-start;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-end;
  }

  .summer-left {
    width: calc(1100px * 0.35);
    padding-left: 50px;
    margin-right: calc(calc(100vw - 1100px) / 2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;

    @media screen and (max-width: 1100px) {
      width: 100%;
      margin: 0 0 15px 0;
      height: 500px;
      padding: 0 30px;
      align-items: flex-end;
      // padding-right: ;
    }

    img {
      width: 200px;
      // width: 40%;
      // @media screen and (max-width: 1100px) {
      // }
    }

    p {
      font-size: 16px;
      letter-spacing: 0.1px;
    }
  }
  .summer-right {
    width: calc(calc(calc(100vw - 1100px) / 2) + calc(1100px * 0.65));
    //↑右だけ1100px超えて出したいとき使うやつね！
    // height: 100%;
    align-self: flex-end;
    margin: 0;

    @media screen and (max-width: 1100px) {
      width: 100% !important;
      margin-top: 30px;
    }

    ::v-deepimg {
      
      object-fit: cover;

      @media screen and (min-width: 769px) and (max-width: 1100px) {
        width: 100%;
      }
      @media screen and (max-width: 768px) {
        object-fit: cover;
        object-position: 50% 50%;
        height: 300px;
      }
    }
  }
}
</style>
