<template>
  <div class="osechi">
    <div class="wrap">
      <div class="cover">
        <OsechiInfo />
      </div>
    </div>
  </div>
</template>
<script>
import OsechiInfo from "@/components/Osechi/Info.vue";
export default {
  name: "Osechi",
  components: {
    OsechiInfo,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.osechi {
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .cover {
      background-color: rgba($color: black, $alpha: 0.9);
      margin-top: 100px;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
}
</style>
