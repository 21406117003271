<template>
  <div class="InfoAccess">
    <div class="top">
      <h1>店舗情報・アクセス</h1>
      <img :src="`${rootUrl}/img/takumi-hirano_pa004.png`" alt="雲" />
    </div>
    <div class="cover">
    <div class="info-box">
      <div class="left">
        <img :src="`${rootUrl}/img/takumi-hirano_logo01.png`" alt="ロゴ" />
        <table>
          <tr>
            <th>住所</th>
            <td>
              〒500-8181&nbsp;&nbsp;<br
                class="sp-kaigyo"
              />岐阜市御浪町10番地(御浪ビル１F)
            </td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td><a href="tel:0582638644"> 058-263-8644</a></td>
          </tr>
          <tr>
            <th>営業時間</th>
            <td>17時30分~</td>
          </tr>
          <tr>
            <th>定休日</th>
            <td>
              日曜日、第一・第三月曜日<br
                class="sp-kaigyo"
              />（※月曜日が祝日の場合もお休み）
            </td>
          </tr>
          <tr>
            <th>提携駐車場</th>
            <td>有</td>
          </tr>
        </table>
      </div>
      <div class="map">
      <img
        class="right"
        :src="`${rootUrl}/img/takumi-hirano_map001.png`"
        alt="地図"
      />
      </div>
    </div>
    <div class="note"><p>サフランの赤い看板の隣です</p></div>
    </div>
    

    <div class="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3251.4289037719745!2d136.75880011554582!3d35.41940385189352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003a9443c2e267b%3A0xdbb5ce8ff781efa6!2z44CSNTAwLTgxODEg5bKQ6Zic55yM5bKQ6Zic5biC5b6h5rWq55S677yR77yQ!5e0!3m2!1sja!2sjp!4v1624429713360!5m2!1sja!2sjp"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoAccess",
  components: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.top {
  text-align: center;
  img {
    width: 12%;
    @media screen and (max-width: 767px) {
      width: 25%;
    }
  }
}
.cover{
background-color: rgba($color: black, $alpha: 0.9);
margin-top: 100px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

.info-box {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;

  @media screen and (max-width: 767px) {
    display: block;
    width: 100%;
  }

  .left {
    width: 50%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    img {
      width: 38%;
      margin-bottom: 10px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;

      @media screen and (max-width: 767px) {
        width: 80%;
        margin: 0 auto;
      }
      :last-child {
        border-bottom: solid 1px white;
      }
      th,
      td {
        border-top: solid 1px white;
        padding: 20px 10px;
        
        @media screen and (max-width: 767px) {
          display: block;
          text-align: center;
        }
      }

      th {
        text-align: left;
        width: 23%;
        @media screen and (max-width: 767px) {
          width: 100%;
          text-align: center;
          padding: 10px 0;
        }

      }

      td {
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      
      a {
        text-decoration: none;
        border: none!important;        
        color: white;
        pointer-events: none;
        cursor: default;

        @media screen and (max-width: 767px) {
          pointer-events: initial;
          cursor: pointer;
        }
      }
     
    }
  }
.map{
  width: 47%;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 30px;
      padding: 20px;
    }
  .right {
    display: flex;
    align-self: flex-end;

  }
      }

}
.note {
  width: 100%;
  font-size: 13px;
  padding-top: 10px;
  margin-bottom: 50px;
  p{
    text-align: right;
    margin-right: 50px;
    @media screen and (max-width: 767px){
      margin-right: 0;
    }

  }
}
  }
.map {
  margin-top: 100px;
  margin-bottom: 50px;
}

</style>
