<template>
  <div class="ctrl-img">

    <div class="wrap">
    <CtrlImg />
    </div>



  </div>
</template>
<script>
import CtrlImg from "@/components/Ctrl/Img.vue";
export default {
  name: 'ctrl-img',
  components: {
    CtrlImg,
  },
data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
