<template>
  <div class="container">
    <div class="shop-message">
      <img class="point-img" :src="`${rootUrl}/img/takumi-hirano_pa002.png`" alt="月">
      <p>ぜひごゆっくりとお愉しみくださいませ。</p>
      <p>コースは二時間半程度です。</p>
      <p>献立をおつくりいたします。</p>
      <p>できるだけお客様に寄り添って</p>
      <p>食が細い方は事前にお聞かせください。</p>
      <p>苦手な食材がある方、</p>
      <p>当店は、コース料理のみの完全予約制です。</p>
    </div>
    <div class="shop-img">
      <img id="cloud-img" class="cloud-img" :src="`${rootUrl}/img/20240710-2.webp`" alt="雲">
      <img class="vestibule-img" :src="`${rootUrl}/img/20240710-3.webp`" alt="匠味 平野">
      <img class="counter-img" :src="`${rootUrl}/img/20240710-4.webp`" alt="匠味 平野カウンター">
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeMessage",
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
  mounted() {
    if (document.getElementById('cloud-img')) {
      this.initIntersectionObserver();
    }
  },
  methods: {
    initIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.cloud-img');
      targets.forEach(target => observer.observe(target));
    },
  }
}
</script>

<style lang="scss" scoped>
  .container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      padding: 0 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }
    .shop-message {
      position: relative;
      width: 330px;
      margin: 0 100px 0 auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap-reverse;
      @media screen and (max-width: 767px) {
        width: 90%;
        margin: 0 auto;
      }
      .point-img {
        display: block;
        width: 200px;
        position: absolute;
        top: -100px;
        right: -120px;
        z-index: 0;
        @media screen and (max-width: 767px) {
          width: 100px;
          top: -60px;
          right: -15px;
        }
      }
      p {
        position: static;
        z-index: 1;
        font-size: 18px;
        font-weight: bold;
        color: white;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
      }
    }
    .shop-img {
      position: relative;
      height: 370px;
      margin: 80px 0 150px 0;
      @media screen and (max-width: 840px) {
        margin-top: 40px;
        height: auto;
      }
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        margin-bottom: 100px;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 60px;
      }
      .cloud-img {
        width: 900px;
        position: absolute;
        top: -200px;
        right: -400px;
        z-index: 0;
        /* アニメーション用スタイル */
        opacity: 0;
        // transform: scale(0.9);
        transition: opacity 2s ease-out, transform 2s ease-out;
        @media screen and (min-width: 840px) and (max-width: 1100px) {
          width: 800px;
          top: -180px;
          right: -250px;
        }
        @media screen and (min-width: 768px) and (max-width: 839px) {
          width: 500px;
          top: -100px;
          right: -130px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          top: -220px;
          right: -100px;
        }
      }
      .vestibule-img {
        height: 250px;
        position: absolute;
        top: -300px;
        z-index: 1;
        @media screen and (max-width: 840px) {
          position: static;
          margin-bottom: 10px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          height: auto;
        }
      }
      .counter-img {
        width: 60%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        @media screen and (max-width: 840px) {
          position: static;
          width: auto;
        }
      }
    }
  }
</style>