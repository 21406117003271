<template>
  <div class="ctrl-login">

    <div class="wrap">
    <CtrlLogin />
    </div>



  </div>
</template>
<script>
import CtrlLogin from "@/components/Ctrl/Login.vue";
export default {
  name: 'ctrl-login',
  components: {
    CtrlLogin,
  },
data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
