<template>
  <div class="recruit-deshi-top">
    <div class="top">
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-001sp.jpg`"
        alt="弟子募集トップSP"
        class="base sp-only"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-001.jpg`"
        alt="弟子募集トップPC"
        class="base pc-only"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-1.png`"
        alt="弟子募集トップPC"
        class="base2 moon pc-only fadeIn2"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-1sp.png`"
        alt="弟子募集トップPC"
        class="base2 moon sp-only fadeIn2"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-2.png`"
        alt="弟子募集トップPC"
        class="fadeLeft pc-only"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-2sp.png`"
        alt="弟子募集トップPC"
        class="sp-only fadeLeft"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-3.png`"
        alt="弟子募集トップPC"
        class="pc-only fadeRight"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-3sp.png`"
        alt="弟子募集トップPC"
        class="sp-only fadeRight "
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-4.png`"
        alt="弟子募集トップPC"
        class="base2 logo pc-only fadeIn2"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-4sp.png`"
        alt="弟子募集トップPC"
        class="base2 logo sp-only fadeIn2"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-5.png`"
        alt="弟子募集トップPC"
        class="base2 sub pc-only fadeIn2"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-5sp.png`"
        alt="弟子募集トップPC"
        class="base2 sub sp-only fadeIn2"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-6.png`"
        alt="弟子募集トップPC"
        class="base2 text pc-only fadeIn2"
      />
      <img
        :src="`${rootUrl}/img/deshi/th_deshi202305-tai001-6sp.png`"
        alt="弟子募集トップPC"
        class="base2 text sp-only fadeIn2"
      />
    </div>
    <div class="top-bar">
      <div class="item-cover">
        <div class="sp-cover">
          <div class="item">
            <img
              :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
              alt="金丸"
            />
            <p>半額家賃補助あり</p>
          </div>
          <div class="item">
            <img
              :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
              alt="金丸"
            />
            <p class="pc-only">日曜は毎週お休み</p>
            <p class="sp-only">毎週日曜休み</p>
          </div>
        </div>
        <div class="sp-cover">
          <div class="item">
            <img
              :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
              alt="金丸"
            />
            <p class="pc-only">年１回昇給あり</p>
            <p class="sp-only">年１回昇給有</p>
          </div>
          <div class="item">
            <img
              :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
              alt="金丸"
            />
            <p class="pc-only">1年以上勤務で年２回賞与</p>
            <p class="sp-only">年２回賞与有</p>
          </div>
        </div>
        <div class="sp-cover">
          <div class="item">
            <img
              :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
              alt="金丸"
            />
            <p class="pc-only">昼・夜と美味しい賄い付</p>
            <p class="sp-only">昼・夜の賄い付</p>
          </div>
          <div class="item sp-only">
            <img
              :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
              alt="金丸"
            />
            <p class="pc-only">車通勤可能</p>
            <p class="sp-only">車通勤可能&emsp;</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "recruit-learn",
  components: {},
  data() {
    return {
      display01: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
  watch: {
    scrollY(newValue) {
      if (newValue >= 0) {
        // alert(123);
        this.display01 = true;
      } else {
        //
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-deshi-top {
  overflow-x: hidden;
  transform: translateZ(0);
  .top {
    .base {
      position: relative;
      vertical-align: bottom;
      @media screen and (max-width: 767px){
        overflow-x: hidden;
      }
    }
    .base2 {
      position: absolute;
      width: 50%;
      left: 50%;
      // padding-top: 6vw;
      // padding-left:50vh;
      transform: translate(-50%, 6vw);
      -webkit-transform: translate(-50%, 6vw);
      -ms-transform: translate(-50%, 6vw);
      z-index: 100;
      @media screen and (max-width: 767px) {
        width: 100%;
        top: -30px;
        overflow-x: hidden;
      }
    }
    .fadeIn2 {
      animation-delay: 0.5s;
      animation-duration: 4s;
    }
    .fadeLeft {
      position: absolute;
      width: 50%;
      left: 50%;
      animation-name: fadeLeftAnime;
      animation-delay: 2s;
      animation-duration: 5s;
      animation-fill-mode: forwards;
      opacity: 0;
      z-index: 100;
      @media screen and (max-width: 767px) {
        width: 100%;
        top: -30px;
        overflow-x: hidden;
      }

      // overflow: hidden;
    }

    @keyframes fadeLeftAnime {
      from {
        opacity: 0;
        transform: translate(-70%, 6vw);
        @media screen and (max-width: 767px) {
          transform: translate(-70%, -100%);
        }
      }
      to {
        opacity: 1;
        transform: translate(-50%, 6vw);
        @media screen and (max-width: 767px) {
          transform: translate(-50%, -100%);
        }
      }
    }

    .fadeRight {
      position: absolute;
      width: 50%;
      left: 50%;
      animation-name: fadeRightAnime;
      animation-duration: 5s;
      animation-delay: 2s;
      animation-fill-mode: forwards;
      opacity: 0;
      z-index: 100;
      @media screen and (max-width: 767px) {
        width: 100%;
        top: -30px;
        overflow-x: hidden;
      }
    }

    @keyframes fadeRightAnime {
      from {
        opacity: 0;
        transform: translate(-30%, 6vw);
      }

      to {
        opacity: 1;
        transform: translate(-50%, 6vw);
      }
    }
  }
  .top-bar {
    background-color: black;
    padding: 22px 0;
    display: flex;
    justify-content: center;
    .item-cover {
      display: flex;
      justify-content: space-between;
      width: 80%;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 95%;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    .sp-cover {
      display: flex;
      justify-content: space-around;
      width: 33%;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        justify-content: space-between;
        flex-wrap: wrap;
        width: 50%;
      }
      @media screen and (max-width: 767px){
        justify-content: space-between;
        width: 100%;
      }
    }
    .item {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767px){
        padding: 5px 0;
      }
      p {
        color: white;
        font-size: 16px;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          font-size: 18px;
        }
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
      img {
        margin-right: 5px;
        width: 30px;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          width: 15px;
        }
        @media screen and (max-width: 767px) {
          width: 15px;
        }
      }
    }
  }
}
</style>
