<template>
  <div class="recruit">
    <div class="top"></div>

      <RecuruiLearn />

    <div class="wrap">
      <RecuruiContact class="space1"/>
    </div>
  </div>
</template>

<script>
import RecuruiContact from "@/components/Recruit/Contact.vue";
import RecuruiLearn from "@/components/Recruit/Learn.vue";
// @ is an alias to /src

export default {
  name: "recruit",
  components: {
    RecuruiLearn,
    RecuruiContact,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.recruit {
  background-color: rgb(241, 235, 226);
  color: black;
  text-align: center;


  .top {
    height: 104px;
    width: 100%;
    background-color: rgb(241, 235, 226);
         @media screen and (max-width: 767px) {
           background-color: initial;
           height: 50px;
     }
  }
}
.space1{
  padding-top:103px; padding-bottom:50px;
  @media screen and (max-width: 767px){
    padding-top: 50px;
  }
  }
</style>
