<template>
  <div class="ctrl-login">
           <el-row type="flex" justify="center">
      <h1>管理画面</h1>
    </el-row>
<div class="input-cover">
   
        <el-input type="email" autocomplete="on" placeholder="登録メールアドレス" v-model="mail_address">
          <template slot="prepend">ログインID</template>
        </el-input>
</div>
<div class="input-cover">
        <el-input type="password" autocomplete="on" placeholder="123456789abcdefg" v-model="password">
          <template slot="prepend">パスワード</template>
        </el-input>
</div>
     <el-row type="flex" justify="center" style="margin-top: 40px">
      <el-col :span="6" style="text-align: center">
        <el-button @click="login" type="success">ログイン</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "ctrl-login",
  components: {

  },
  data() {
    return {
      mail_address:"",
      password:"",
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {
    login(){
      let params = new URLSearchParams();
      params.append("mail_address", this.mail_address);
      params.append("password", this.password);
      this.$axios
        .post(`${this.rootUrl}/api/login.php`, params)
        .then((response) => {
          console.log(response);
          // alert(response.data.status);
          if (response.data.status === 200) {
            // console.log('ok')
            this.$router.push("/ctrl")
            return;
          } else if (
            response.data.status === 401 || response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
},
}
</script>

<style lang="scss" scoped>
.ctrl-login{
  padding-top: 50px;
  padding-bottom: 150px;
  background-color: #f4f4ef;
  // margin-bottom: 50px;

h1{
  margin-bottom: 50px;
  color: black;
}
.input-cover{
width: 70%;
margin: 10px auto;
@media screen and (max-width: 768px) {
  width: 95%;
}
}
}

</style>
