<template>
  <div class="recruit-deshi-about">
    <div class="wrap mb-200">
      <div class="gold-bar">
        <h1>匠味 平野の弟子募集について</h1>
      </div>
      <div class="block-cover">
        <p>
          匠味 平野では、昔ながらの「見て学べ」という教え方ではなく、志から丁寧に教え、より良い環境で逸材を育てたいと強く思い、熱い魂を持った弟子を1名限定で募集することになりました。今日まで何十年もかけて平野が培ってきた調理技術や知識、お客様への心遣いまで料理人のすべてを叩き込みます。料理人が成長する一番の近道は、実戦を積むこと。知識を得たり、他店の味を勉強したりすることも大切ですが、日々の実務の中で得られる経験に勝るものはありません。料理が好きで一流を目指したい！まだ見つかってないけど、絶対何かで成功するんだ！など、真っ直ぐな思いとやる気がある方をお待ちしています。
        </p>
      </div>
    </div>
    <div class="black-bar">
      <div class="wrap">
        <div class="block">
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa003.png`"
            alt="ゴールドパターン左"
            class="left"
          />
          <p>
            料理人としての魂を引き継ぎ、<br />そして育む<br />これが平野の使命
          </p>
          <img
            :src="`${rootUrl}/img/kyujin/th_kyujin202305-pa004.png`"
            alt="ゴールドパターン右"
            class="right"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recruit-learn",
  components: {},
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-deshi-about {
  .gold-bar {
    background-color: var(--gold);
    color: white;
    font-size: 22px;
    padding: 15px 0;
    h1{
      margin-bottom: 0;
      @media screen and (max-width: 767px) {
        font-size: 22px;
      }
    }
  }
  .block-cover {
    margin-top: 50px;
            @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
    p {
      text-align: left;
    }
  }
  .black-bar {
    background-color: var(--black);
    color: white;
    font-size: 22px;
    padding: 50px 0;
    .block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 30px;

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        flex-direction: column;
        font-size: 18px;
      }
      img {
        width: 70px;

      }
      .right {
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          margin-top: auto;
        }
        @media screen and (max-width: 767px) {
          margin-left: auto;
        }
      }
      .left {
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          margin-bottom: auto;
        }
        @media screen and (max-width: 767px) {
          margin-right: auto;
        }
      }
      p {
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          font-size: 22px;
        }
        @media screen and (max-width: 767px) {
          width: calc(100% - 60px);
        }
      }
    }
  }
}
</style>
