<template>
  <div class="ctrl-home">
    <h1>
      メニュー
   </h1>
   <el-row type="flex" justify="end"  style="padding-buttom: 20px;">
      <el-col :span="8">
               <el-button class="logout" @click="logout" type="info" plain>ログアウト</el-button>
      </el-col>
   </el-row>
    <el-row class="bord">
      <el-col class="bord-col">
        <el-button class="menu" @click="info" type="success" plain>お知らせ投稿</el-button>
      </el-col>
      <el-col>
        <el-button class="menu" @click="imgDate" type="danger" plain>画像・動画データ</el-button>
      </el-col>
      <el-col :span="8">

      </el-col>
      
    </el-row>


  </div>
</template>

<script>

export default {
  name: "ctrl-home",
  components: {

  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {
info(){
  this.$router.push("/ctrl/info")
},
logout(){
      this.$axios
        .get(`${this.rootUrl}/api/logout.php`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$router.push("/ctrl/login")
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    imgDate(){
      this.$router.push("/ctrl/img")
    }
},

}
</script>

<style lang="scss" scoped>
.ctrl-home{
    background-color: #f4f4ef;
    h1{
      color: black;
      padding-top: 30px;
    }
}
.menu{
  width: 300px;
  height: 80px;

}
.el-col{
  text-align: center;
  margin: 20px 0;
}

.bord{
    padding-top: 50px;
  padding-bottom: 300px;
     @media screen and (max-width: 767px){
    padding-bottom: 50px; 
    margin-bottom: 30px;
   }
}
.logout{
  width: 100px;
  height: 40px;
  text-align: center;
  padding: 0;

}
</style>
