<template>
  <div class="recruit-deshi-happy">
    <div class="happy-cover">
      <div class="img-cover">
        <img
          :src="`${rootUrl}/img/deshi/th_deshi202305-pa002-1.png`"
          alt="弟子になる理由"
          class="base"
        />
        <img
          :src="`${rootUrl}/img/deshi/th_deshi202305-pa002-2.png`"
          alt="弟子になる理由"
          class="deco"
          style="z-index:100;"
          :class="{ fadeIn: displayData.display01 === true }"
        />
      </div>
      <div class="title">
        <!-- <p class="small">
          なぜ和食の修行は10年かかると<br class="sp-only" />言われているのか
        </p> -->
        <!-- <p class="big">
          食材から器まで、<br class="sp-only" />10年かけてやっと<br
            class="pc-only"
          />日本の四季を<br class="sp-only" />知ることができるから
        </p> -->
        <p class="big">10年かけないと見えない<br />日本料理の真髄</p>
        <p class="small">
          食材が豊富な年もあれば、<br class="sp-only" />不作な年もある。<br />
          <!-- 雨ばかり降る夏の年もあれば、<br class="sp-only"/>暖冬な年もある。<br> -->
          旬の食材を選り抜き、<br
            class="sp-only"
          />時節にあう器という着物をきせる。<br />
          <!-- そして一つの「景色」を描き出し、お客様をもてなす。<br> -->
          10年かけてやっと日本料理の四季と共鳴できます。
        </p>
      </div>
    </div>
    <div class="way-cover">
      <div class="way">
        <div class="year">
          <img
            :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
            alt="金丸"
          />
          <p>1年目&nbsp;&emsp;八寸場・盛り付け</p>
        </div>
        <p class="text">
          この期間に現場の作業や季節ごとの流れを把握し、先輩たちの仕事内容を理解します。<br
            class="pc-only"
          />和食の特徴である「美しさ」を表現するための大事な仕事です。
        </p>
      </div>
      <div class="way">
        <div class="year">
          <img
            :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
            alt="金丸"
          />
          <p>2年目&nbsp;&emsp;焼き場</p>
        </div>
        <p class="text">
          魚はもちろん、肉や野菜も取り扱います。<br
            class="pc-only"
          />綺麗な焼き目は料理の美味しさを引き立たせる大事な仕事です。
        </p>
      </div>
      <div class="way">
        <div class="year">
          <img
            :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
            alt="金丸"
          />
          <p>4年目&nbsp;&emsp;揚げ物</p>
        </div>
        <p class="text">
          天ぷらなどを揚げる「揚げ場」を担当します。<br
            class="pc-only"
          />素材ごとの加熱温度とスピードを意識しながら味を引き出す大事な仕事です。
        </p>
      </div>
      <div class="way">
        <div class="year">
          <img
            :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
            alt="金丸"
          />
          <p>6年目&nbsp;&emsp;蒸し場・煮方</p>
        </div>
        <p class="text">
          蒸し場担当者はすべての味付けをチェックしますので、椀物・蒸し物・煮物も担当します。<br
            class="pc-only"
          />蒸し場は蒸し物を担当するほかに「味付け」を担う大事な仕事です。
        </p>
      </div>
      <div class="way">
        <div class="year">
          <img
            :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
            alt="金丸"
          />
          <p>8年目&nbsp;&emsp;刺し場</p>
        </div>
        <p class="text">
          この刺し場を担当する料理人が板前・板場と呼ばれます。<br
            class="pc-only"
          />料理以外の心配りも重要な仕事となります。
        </p>
      </div>
      <div class="way">
        <div class="year">
          <img
            :src="`${rootUrl}/img/deshi/th_kyujin202305-pa007.png`"
            alt="金丸"
          />
          <p>10年目&emsp;独立</p>
        </div>
        <p class="text">
          独立・開業して自分の店を持てる一流料理人になっています。<br
            class="pc-only"
          />平野のサポートのもと、あなたのタイミングで独立へと進みます。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "recruit-deshi-happy",
  components: {},
  props: {},
  data() {
    return {
      displayDefinitionList: [],
      configList: [
        {
          matchMedia: "(min-width: 768px)",
          displayDefinitionList: [
            {
              offset: 6900,
              display: "display01",
            },
          ],
        },
        {
          matchMedia: "(max-width: 768px)",
          displayDefinitionList: [
            {
              offset: 11281,
              display: "display01",
            },
          ],
        },
      ],
      displayData: {
        display01: false,
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },

  watch: {
    // ４．あとは同じ
    scrollY(offset) {
      //
      let displayList = this.displayDefinitionList.filter(function(
        displayDefinitionData
      ) {
        return displayDefinitionData.offset <= offset;
      });

      //
      displayList.forEach((displayData) => {
        this.displayData[displayData.display] = true;
      });
    },
  },
  created() {
    // ２．configListから、画面幅に合致するものを取得
    let configList = this.configList.filter(function(configData) {
      return window.matchMedia(configData.matchMedia).matches;
    });

    // ３．dataのdisplayDefinitionListに、取得できたものを格納
    console.log("===== configList =====");
    console.log(configList);
    if (configList.length > 0) {
      this.displayDefinitionList = configList[0].displayDefinitionList;
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.recruit-deshi-happy {
  .happy-cover {
    padding-top: 200px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      padding-top: 70px;
      display: block;
    }
    .img-cover {
      width: 28%;
      position: relative;
      @media screen and (max-width: 767px) {
        width: 80%;
        margin: 0 auto;
      }
    }
    .base {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      @media screen and (max-width: 767px) {
        position: relative;
      }
    }
    .title {
      width: 65%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }

      .small {
        font-size: 18px;
        margin-top: 20px;
        line-height: 2em;
        @media screen and (max-width: 1024px) {
          font-size: 20px;
        }
      }
      .big {
        margin-top: 30px;
        line-height: 1.3em;
        font-size: 36px;
        @media screen and (max-width: 1024px) {
          font-size: 24px;
        }
      }
    }
  }
  .way-cover {
    text-align: left;
    margin-top: 150px;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: 80%;
      margin: 100px auto 0;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 70px auto 0;
    }
    .way {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        flex-direction: column;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      .year {
        color: var(--gold);
        width: 32%;
        display: flex;
        align-items: center;
        font-size: 22px;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          width: 100%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        img {
          margin-right: 10px;
          width: 24px;
        }
      }
      .text {
        width: 68%;
        font-size: 18px;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          width: 100%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}
</style>
